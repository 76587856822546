import { Text, TextField } from 'components';
import { TEXT_STRING } from '../../../constants';
import { TTextFieldChangeEvent } from 'types';
import { ReactElement } from 'react';

type Props = {
  label: string;
  name?: string;
  value?: string;
  onChange?: (e: TTextFieldChangeEvent) => void;
  onBlur?: (e: TTextFieldChangeEvent) => void;
  type?: string;
  isDisabled?: boolean;
  isShowValue?: boolean;
  showValue?: string;
  suffix?: ReactElement;
  error?: string;
};

function TextFieldRow({
  label,
  name = '',
  value = '',
  onChange = () => {},
  onBlur = () => {},
  isDisabled,
  type,
  isShowValue,
  showValue,
  suffix,
  error,
}: Props) {
  return (
    <>
      <div className="flex justify-between">
        <Text
          fontSize={16}
          fontWeight="font-medium"
          textColor="text-neutral-700"
          className="flex-1"
        >
          {label}
        </Text>

        {isShowValue ? (
          <div className="flex-1">
            <Text
              textColor="text-neutral-700"
              fontSize={16}
              className="leading-8"
              textAlign="text-right"
            >
              {showValue}
              {TEXT_STRING.COMMON.POINT_UNIT}
            </Text>
          </div>
        ) : (
          <div className="flex-1">
            <TextField
              afficPadding="p-0"
              suffix={suffix}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              inputClass="leading-8 text-neutral-700 font-normal text-right"
              inputPadding="p-0"
              textSize="text-base"
              disabled={isDisabled}
              type={type}
              noBorder
            />

            {error ? (
              <p className="text-right text-error mt-2">{error}</p>
            ) : null}
          </div>
        )}
      </div>

      <div className="w-full h-[1px] my-5 bg-neutral-50" />
    </>
  );
}

export default TextFieldRow;

import { Text } from 'components';

type Props = {
  label: string;
  value: string;
  className?: string;
};

function BankInforRow({ label, value, className }: Props) {
  return (
    <div className={`${className} flex gap-8 items-center text-base`}>
      <Text
        className="border py-2 px-7 w-[125px] bg-neutral-50 rounded-md"
        textColor="text-neutral-700"
        fontWeight="font-medium"
      >
        {label}
      </Text>
      <Text textColor="text-neutral-550">{value}</Text>
    </div>
  );
}

export default BankInforRow;

import axios from 'axios';
import { TCreatePost, TPostResponse } from 'types';

const getRecommendPostList = async (
  currentPage: number,
  pageSize: number
): Promise<TPostResponse> => {
  const res = await axios
    .get('post', {
      params: {
        currentPage: currentPage,
        pageSize: pageSize,
      },
    })
    .then(function (response) {
      return response.data;
    });

  return res;
};

const getFollowedPostList = async (
  currentPage: number,
  pageSize: number
): Promise<TPostResponse> => {
  const res = await axios
    .get('post/followed', {
      params: {
        currentPage: currentPage,
        pageSize: pageSize,
      },
    })
    .then(function (response) {
      return response.data;
    });

  return res;
};

const createPost = async (body: TCreatePost): Promise<TPostResponse> => {
  const res = await axios.post('post', body).then(function (response) {
    return response.data;
  });

  return res;
};

const getPremiumPurchasedList = async (
  currentPage: number,
  pageSize: number
): Promise<TPostResponse> => {
  const res = await axios
    .get('post/premium-purchased', {
      params: {
        currentPage,
        pageSize,
      },
    })
    .then(function (response) {
      return response.data;
    });

  return res;
};

export {
  getRecommendPostList,
  getFollowedPostList,
  getPremiumPurchasedList,
  createPost,
};

import Icon from 'components/Icon';
import Text from 'components/Text';
import { SLASH_DATE_TIME_FORMAT, TEXT_STRING } from '../../../constants';
import { EPointHistoryType, TPointHistoryResponse } from 'types';
import { formatAmountNumber, formatDate } from 'utils';
import { useMemo } from 'react';

type Props = {
  data: TPointHistoryResponse;
  className?: string;
};

function UsageHistoryItem({ data, className }: Props) {
  const isHistoryTypeSubtracted = useMemo(
    () => data.historyType === EPointHistoryType.SUBTRACTED,
    [data.historyType]
  );

  return (
    <div
      className={`bg-white
      ${className} flex items-center gap-4 p-5 rounded-lg border border-neutral-100`}
    >
      <div className="w-[26px] h-[26px]">
        <Icon icon={'teco'} />
      </div>

      <div className="flex-1 flex flex-col gap-[2px] text-base">
        <div className="flex justify-between items-center">
          <Text textColor="text-neutral-550">
            {formatDate(data.updatedDateTime, SLASH_DATE_TIME_FORMAT)}
          </Text>
        </div>

        <div className="flex justify-between items-center text-base">
          <Text textColor="text-neutral-950">{data.reason}</Text>

          <Text
            textColor={isHistoryTypeSubtracted ? 'text-error' : 'text-success'}
            fontWeight="font-medium"
          >
            {(isHistoryTypeSubtracted ? '-' : '+') +
              formatAmountNumber(
                data.pointAmount,
                TEXT_STRING.COMMON.POINT_UNIT
              )}
          </Text>
        </div>
      </div>
    </div>
  );
}

export default UsageHistoryItem;

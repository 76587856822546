const TEXT_STRING = {
  COMMON: {
    NO_DATA: 'データなし',
    MY_PAGE: 'マイページ',
    INFO: '基本情報',
    AGE: '歳',
    CANCEL: 'キャンセル',
    LOGIN: 'ログイン',
    REGISTER: '会員登録は',
    YEN: '円',
    POINT_UNIT: 'T',
    PEOPLE: '人',
    REQUIRE: '必須',
    MULTIPLE_CHOICE: '※複数可',
    MALE: '男性',
    FEMALE: '女性',
    SET: 'セット',
    PERSON: '名',
    COMPLETION: '完了',
    HOUR: '時',
    NO: 'いいえ',
    YES: 'はい',
    YEN_HOUR: '円/時',
    RANK: 'ランク',
    TECO_GIRL: 'テコガール',
    LIST_TECO_GIRL: 'ゲーム一覧',
    GAME: 'ゲーム',
    UNDO: '取り消し',
    CONFIRM_DELETE: 'カードを削除しますか。',
    CONFIRM_MESSAGE: '宜しいでしょうか。',
    SOMETHING_WENT_WRONG: '何か問題が発生しました。',
    KEEP: '保存',
    PROFILE: 'プロフィール',
    GO_TO_PROFILE: 'プロフィールへ',
    EMAIL_NOT_VALID: '無効メールです。',
    CONTINUE: '続ける',
    SETTING: '設定',
    DISCOUNT_COUPON: '割引クーポン',
    DISCOUNT_FOR_ONE: '個テコ(1対1)3万円以上のゲーム代注文時',
    DISCOUNT_FOR_MULTIPLE: '団体注文時のゲーム代割引',
    SOMEONE_WANT_CONTACT: '個別のメッセージができます',
    GAME_FEE: 'ゲーム代を',
    DISCOUNT_NOTE: '以上注文されたら',
    DISCOUNT: '割引',
    RECENT_TWEETS: '最近のつぶやき',
    RECEIVED_MESSAGE: 'さんからメッセージが届きました！',
    MEETING: '合流中',
    NEXT: '次へ',
    MENU: 'MENU',
    SELECT: '選択する',
    NOT_FOUND_GIRL: 'テコガールIDが見つかりません。',
    PRICE_SINGLE_ORDER: '10,000〜20,000',
    AMOUNT: '金額',
    TYPING: '誰かが入力しています',
    MGM_STAFF: '運営スタッフに問い合わせ',
    QUIT: '退出',
    SUCCESS: '成功',
    BACK: '后退',
    MESSAGE_REMOVED_CHANNEL: 'グループチャットから削除されました！',
    QUANTITY: '個数',
    INDIVUAL: '個',
    INQUIRY: '問い合わせ',
    CLOSE: '閉じる',
    RESET: 'リセット',
    ONE_MAN: '万円',
    PUBLIC: '公開',
    PENDING: '承認待ち...',
    APPROVED: '承認済',
    ONE_NIGHT: '1泊',
    START_DATE: '開始日',
    END_DATE: '終了日',
    PRICE_RANGE: '$min円 ～ $max円',
    UNUSED: '未使用',
    COUNT: '回',
    AND: 'と',
    UNREGISTERED: '未登録',
    NAVIGATE_TO_REGISTER_SCREEN: '会員登録はこちら',
    QUOTE: '「$value」',
    RETURN: '戻る',
    PAYMENT_HERE: '決済ページはこちら',
    WARNING: '警告',
    CHOOSE: '選ぶ',
    SPECIFY_STARS: '指定の評価',
    CREDIT_CARD_REGISTRATION: 'クレジットカード登録',
    TOTAL_STAMPS: '投げ銭スタンプ総額',
    TOTAL_POINTS: 'teco資産購入金額',
    COPY: 'コピー',
    COPY_SUCCESS: 'リンクをコピーしました。',
    OTHER: 'それ以外',
    OTHER_WITHOUT_VISA: 'VISA以外のカード',
    MAINTENANCE: '※メンテナンス中',
    SHOW_ALL: 'すべて表示',
  },
  NAVIGATION: {
    SEARCH: '探す',
    HOME: 'つぶやき',
    CALL: '今すぐ呼ぶ',
    MESSAGE: 'メッセージ',
  },
  SEARCH_PAGE: {
    SET_CONDITIONS: '検索条件を設定する',
    SEARCH: '検索',
    REFINE: '絞り込み検索',
    STYLES: 'スタイル',
    LINEAGE: '系統',
    ENJOY_METHOD: '楽しみ方',
    GAME_PLAY: '遊べること',
    SCHEDULES: '本日出勤',
    LIST_EMPLOYEES: '在籍一覧',
    LIST_DISCOUNT: '割引一覧',
    RATING: '評価',
    DISCOUNT_MINIMUM: '以上のゲーム代注文時に',
    ATTENDANCE_AT_WORK: '出勤',
    SEARCH_NOTE: 'テコガールの名やIDや自己紹介をフリーワードで検索できます',
    SEARCH_BY_KEYWORD: 'フリーワード検索',
    ENTER_KEYWORD: 'フリーワードを入力してください',
    LIST_DISCOUNT_INDIVIDUAL: '個テコ(1対1)の割引一覧(ゲーム3万円以上注文時)',
    INDIVIDUAL_DISCOUNT_LIST: '個テコ割引一覧',
  },
  CAST_PAGE: {
    SCHEDULES: '出勤時間',
    FOLLOW: 'フォロー',
    FOLLOWING: 'フォロー中',
    INTRO: '自己紹介',
    CHAT: 'メッセージする',
    CALL: '指名して呼ぶ',
    AGE_NUMBER: '年齢',
    HEIGHT: '身長',
    CM: 'cm',
    THREE_SIZES: '3サイズ',
    BUST_SHORT: 'B.',
    WAIST_SHORT: 'W.',
    HEIGHT_SHORT: 'H.',
    NUMBER_CUP: 'カップ数 ',
    CUP: 'カップ',
    PERIOD_MESSAGE: '生理中です',
    RANK: 'ランク',
    POINT: '評価',
    ABROADER: 'ABROADER',
    ACTIVITY_AREA: 'エリア',
    AFFILIATION_AREA: '所属エリア',
    WORK_AREA: '出勤エリア',
    OK: 'OK',
    NO: 'No',
  },
  MY_PAGE: {
    ACCOUNT_INFO: 'アカウント情報',
    LIST_FOLLOWING: 'フォローしている女性一覧',
    PAYMENT_METHOD: 'お支払い方法',
    ORDER_HISTORY: '注文履歴',
    LOGOUT: 'ログアウト',
    PREVIEW_IMAGE: 'プレビュー画像。',
    EMAIL_NOTI_SETTING: 'メール通知設定',
    EMAIL_RECEIVE: '受信するメールアドレス',
    GROUP_CHAT_MESSAGE: 'グループチャットのメッセージ',
    MESSAGE_FROM_STAFF: '運営スタッフのメッセージ',
    TECO_GIRL_MESSAGE: 'テコガールのメッセージ',
    CONFLUENCE: '合流',
    GAME_SELECTION: 'ゲームの選択',
    OPEN_TUTORIAL: 'tecoをホーム画面へ追加し、push通知を受信できるようにする',
    NOTI_SETTING: '通知設定',
    JOIN_NOTI: '合流通知',
    FEMALE_MET: '合流した女性一覧',
    FEMALE_MET_TEXT: '過去に呼んだことのあるテコガール',
    ABOUT_APP: 'アプリに関して',
    LOVE_HOTELS: 'ラブホ一覧',
    HOW_TO_USE: 'ご利用方法',
    FAQ: 'よくある質問',
    ACCOUNT: 'アカウント',
    COMMUNICATION_GUIDE: 'コミュニケーションガイドライン',
    TERMS_OF_SERVICE: '利用規約',
    PRIVACY_POLICY: 'プライバシーポリシー',
    LEGAL_COMPLIANCE_POLICY: '法令遵守ポリシー',
    PROFILE_IMAGE_REVIEW: 'プロフィール画像の審査結果',
    BUY_POINTS: 'teco資産を購入する',
    BUY_POINTS_HISTORY: 'teco資産購入履歴',
    PURCHASED_PAID_TWEET: '購入した有料のつぶやき',
  },
  MALE_PAGE: {
    PROFILE_DETAIL: 'プロフィール詳細',
    PROFILE_INFO: 'プロフィール情報',
    EDIT_PROFILE: 'プロフィール編集',
    USING_TECO: 'tecoの利用',
    TOTAL_AMOUNT: '会計総額',
    NUM_OF_ORDERS: '注文回数',
    TOTAL_STAMP: 'スタンプ総額',
    COLLECTIONS: 'Profile image',
    PREVIEW: 'プレビュー',
    ANNUAL_INCOME: '年収',
    AVATAR: 'アバター画像',
    PROFILE_IMAGE: 'サブ画像',
    UTILIZATION_AMOUNT: '累計利用金額',
    TOTAL_USES: '累計利用回数',
    INTRO: '自己紹介',
  },
  PROFILE_RANK: {
    GUEST_RANK: 'ゲストランク',
    USAGE_30_DAY: '直近30日間の利用',
    USAGE_AMOUNT: '利用金額：$amount',
    USAGE_COUNT: ' 利用回数：$count回',
    GUEST_RANK_CONDITIONS: 'ゲストランクの条件',
    RANK_STAR_CONDITIONS:
      '星0個：利用回数0回\n星1個：利用金額5万円以上もしくは利用回数1回\n星2個：利用金額20万円以上もしくは利用回数2回\n星3個：利用金額50万円以上もしくは利用回数4回\n星4個：利用金額100万円以上もしくは利用回数8回\n星5個：利用金額200万円以上もしくは利用回数12回',
    RANK_NOTE:
      'ゲストランクを決定する期間は直近30日間。\n直近30日間で上記の条件を満たさなかった場合、ゲストランクが一つ下がります。',
    OVER_ONE_MAN: '$amount万円以上',
    LESS_ONE_MAN: '$amount万円未満',
  },
  FAQ: {
    QUESTION_1: '交通費はかかりますか？',
    ANSWER_1:
      '交通費はセット料金に含まれているためテコガールズにお支払いいただかなくて大丈夫です！',
    QUESTION_2: '男性何人で使ってもいいですか？',
    ANSWER_2:
      '男性1名で、男性複数人、女性より多め全然OKです！\nご利用者様が複数名の場合はテコガールは2名以上からの派遣となります！\nまたお客様1名の場合のみとテコガール1名との〝個teco〟も可能です✨',
    QUESTION_3: '場所は本当にどこでもいいんですか？',
    ANSWER_3:
      '自宅、ラブホ、ビジホ、個室、飲食店どこでもお届けします🚗。ただし公衆の面前はNG🙅‍♂️です',
    QUESTION_4: 'どこまでできるんですか？',
    ANSWER_4: 'さいっっっっこうにエロい飲み会とだけ…🤫💕言っておきます😆🍌',
    NOTE: '🟨当店ではしょうもないぼったりくりは絶対許しておりませんのでご安心ください\n\n🟨生理中のテコガールは名前の横に🩸のマークがつくので参考にしてください\n\n🟨当店ではお客様の秘密を厳守しております。著名人の方でも安心してご利用いただけます',
  },
  LOGIN: {
    WARNING_LOGIN: 'ログインしてください!',
    MESSAGE_LOGIN: 'これらの女の子と話すにはログインしてください',
    PHONE_NUMBER: '電話番号',
    REQUIRE: '必須',
    HERE: 'こちら',
    MESSAGE_REGISTER: '登録するために、以下の情報を入力してください。',
    NICK_NAME: 'ニックネーム',
    PLACEHOLDER_PHONE: '03 1234 5678',
    PLACEHOLDER_NICK_NAME: 'ニックネームを入力してください。',
    EMAIL: 'メール',
    PLACEHOLDER_EMAIL: 'abc@email.com',
    GO_LOGIN: 'ログインは',
    REGISTRATION: '登録',
    PLACE_HOLDER_NUMBER: '10',
  },
  NOT_FOUND: {
    ERROR: '404',
    TEXT: 'Page not found',
    MESSAGE: 'Sorry, we couldn’t find the page you’re looking for.',
    GO_BACK: 'Go back home',
  },
  FOLLOWING_PAGE: {
    LIST: 'フォロー一覧',
  },
  PRICE_LIST: {
    TITLE: '【料金表】',
    LEVERAGE: '【テコ活】',
    LEVERAGE_DESCRIPTION: 'お互い自由に日程と滞在時間を決められます',
    FREE_TIME: '時間は自由',
    LEVERAGE_NOTE:
      '・テコガールとの個人チャット画面より、\n注文と日程や滞在時間の交渉が可能です\n・11:00〜20:00の間のみ利用可能です \n・20:00をすぎると強制終了または【個テコ】や【団体派遣】\nにて 再度予約し直しをしていただきます',
    GROUP_DISPATCH: '【団体派遣】',
    DISPATCH_MORE_GIRL: 'テコガール2名以上の派遣',
    DISPATCH_PEOPLE: '(2名以上の派遣)',
    ONE_SET: '1セット',
    LEAVE_IT: 'おまかせ',
    INDIVIDUAL_LEVER: '【個テコ】',
    PLAY_SINGLE: 'テコガールと1:1で遊ぶ',
    DISPATCH_RANK_FEE: '派遣されたランクの料金',
    PRICE_NOTE:
      '・指名される場合は下記記載の指名料をいただきます\n・お客様が何名様でご利用いただいても1セットの料金は変わりません',
    ONE_GIRL: 'テコガール1名',
    NOMINATE_FEE: '指名料',
    DISPATCHED_EVALUATION_FEE: '派遣された\n評価の料金',
    PRICE_TUTORIAL:
      '・上記料金はすべてテコガール1名ごとの料金です\n・評価のみを指定したおまかせ注文も可能です\n・指名料は1度切りで毎セットかかるわけではございません\n・カード決済可能\n・領収証発行可能',
    TRAVEL_PACK: '【旅行パック】',
    TRAVEL_INFO: 'テコガールと明朗会計で旅行にいける',
    TRAVEL_NOTE:
      '・指名料、ゲーム代すべて含まれています \n・交通費や現地でかかるテコガールへの費用はすべてお客様負担となります\n・集合時間、場所、解散時間はチャットや現地にて双方でお決め下さい\n・完全前払い制となります',
    FEE_NOTE: '・指名料は1度切りで毎セットかかるわけではございません',
    SPECIFY_ONLY_RATING: 'テコガールの評価のみを指定する',
  },
  CALL_WOMAN: {
    TECO_TABLE: 'テコガールのオーダー表',
    PLAY: '興味のある遊び',
    NOMINATE_GIRL: 'テコガールを指名する',
    NOMINATE_HINT: '※選ばなければフリーになります',
    START_TIME: '開始日時',
    ESTIMATED_AMOUNT: '目安金額',
    GAME_FEE: 'ゲーム代',
    ENTER_DETAIL: '詳細情報の入力へ',
    PAYMENT_WARNING:
      '消費税10%がかかりますが\n現金の場合は消費税相当分を割引させていただきます。',
    LABEL_OPTIONS: 'セット数（1セット/60分）・テコガール数',
    OPTION_SET: 'セット数（1セット/60分）・',
    OPTION_PEOPLE: 'テコガール数',
    OPTION_AREA: '場所',
    DATE_WARNING: '過去時間を選択できません',
    END_DATE_ERROR: '終了時刻を開始時刻より小さくすることはできません',
    INPUT_ID: '名前またはIDを入力してテコガールを指名する ',
    INPUT_PLACEHOLDER: 'テコガールの名前またはIDを入力',
    PLAY_WITH_KID: 'この子と遊ぶ',
    CALL_BY_NAME: '指名して呼ぶ',
    LEVERAGE: 'テコ活する',
    LEVERAGE_DESCRIPTION:
      '・料金は総額5万円ポッキリ（消費税+10% ※現金の場合は割引）\n・11〜20時限定で、双方で日程と滞在時間を決めて会えます\n・20時を超えると強制終了または【個テコ】や【団体派遣】にて再度予約が必要です',
    GO_ON_TRIP: '旅行に行く',
    CONFIRM_LEVERAGE: 'テコ活の日時を承認しますか？',
    APPROVE_LEVERAGE: 'テコ活の承認',
    CANCEL_LEVERAGE: 'テコ活キャンセル',
    CONFIRM_APPROVE_LEVERAGE: 'テコ活を本当に承認しますか？',
    CONFIRM_CANCEL: '本当にキャンセルしますか？',
    NOMINATED_GIRL: '指名テコガール',
    CONFIRM_YOUR_ORDER: '注文内容の確認へ',
  },
  ORDER_DETAIL: {
    RECRUITMENT_DETAIL: '注文の詳細',
    PLAY: '遊び方の要望',
    MEETING_ADDRESS: '合流住所',
    ADDRESS: '住所...',
    NUMBER_CUSTOMER: 'お客様の人数',
    NUMBER_MALE: '男性の人数',
    NUMBER_FEMALE: '女性の人数',
    NOTE_REQUEST: 'その他備考や要望',
    CALL_GIRL: 'テコガールを呼ぶ',
    CALL_HINT: '(確定まで数分程度のお時間を要します)',
    CALL_WARNING:
      '※遠方など入力した内容により、運営から確認のご連絡が入る場合がございます。',
    PREFIX_ADDRESS: '住所前半',
    PREFIX_ADDRESS_PLACEHOLDER: '例：東京都渋谷区渋谷',
    SUFFIX_ADDRESS: '住所後半',
    SUFFIX_ADDRESS_PLACEHOLDER: '例：1-2-3 渋谷ビル 101',
    CHOOSE_AREA: 'どちらのエリアに\n呼びますか?',
    AREA: 'エリア',
    AREA_DETAILS: 'エリア詳細',
    OTHERS: 'その他',
    ENTER_LOCATION: '場所を入力してください',
  },
  SUMMARY_ORDER: {
    CONFIRM_ORDER: '注文確認',
    ORDER: '注文',
    ORDER_SUCCESS_MESSAGE: 'ご注文ありがとうございます！',
    ORDER_SUCCESS_HINT: '派遣まで少々お待ちください。',
    ORDER_THANK: 'ご注文ありがとうございます！',
  },
  HOME_PAGE: {
    RECOMMEND: 'おすすめ',
  },
  MODAL: {
    WARNING_18: 'あなたは18歳以上ですか？',
    WARNING_MESSAGE:
      '18歳未満の方はご遠慮ください。\n必ず利用規約を確認いただきご利用ください。',
    WARNING_REFRAIN: '遠慮ください。',
    CUSTOMER_HERE: 'お客様はこちら',
    OFFICE_WORKER: '内勤・エンジニア',
    GIRL_JOB: 'テコガールの求人',
  },
  ORDER_METHOD: {
    AUTO_CALL: 'おまかせで呼ぶ',
    AUTO_BUTTON: '人数を決める',
    ELECTIVE_CALL: '指名して呼ぶ',
    CHOOSE_CAST: 'テコガールを選ぶ',
  },
  AUTO_CALL: {
    INVITE_QUESTION: '何人呼びますか？',
    ROYAL_VIP_INFO: 'キャストの中でも1％しかいない希少な最上級キャスト',
    ROYAL_VIP: 'ロイヤルVIP',
    VIP_INFO: '特選キャストの中でも10％しかいない特別なキャスト',
    VIP: 'VIP',
    PREMIUM: 'プレミアム',
    PREMIUM_INFO: '合格率10％の面接を通過した厳選キャスト',
  },
  MESSAGE: {
    MESSAGE_LIST: 'メッセージ一覧',
    ENTER_NAME: '名前を入力',
    INPUT_PLACEHOLDER: 'メッセージを入力...',
    QUESTION_PLACEHOLDER: '利用場所はどこがありますか？',
    QUESTIONS_TO_MANAGEMENT: '運営への質問はこちらから',
    START_MESSAGE: '個別のメッセージができます',
    IMAGE: '「画像」',
    FAKE_PREVIEW:
      '👨‍🎓 tecoのご利用ガイド👨‍🎓1. 団体派遣：テコガールは2名から派遣可能です。お客様は1名から複数名まで、料金を割り勘にてご利用いただけます。',
    CANNOT_REPLY: `読むことしかできません。`,
    AUTOMATED_CHAT: '自動メッセージ受信用チャット',
    GIVE_A_GIFT: '$nameを贈りました',
  },
  CHAT: {
    ADD_GAME_MESSAGE: '追加するゲームを選んでください',
    ORDERED_GAME: '注文しているゲーム一覧',
    LIST_GAME: 'ゲーム一覧',
    ADD_GAME: 'ゲームを追加する',
    CANCEL_GAME_MESSAGE: '注文したゲームを取り消します。',
    ADD_FEMALE: 'テコガール追加',
    DISSOLUTION: '会計',
    ADD_TECO_GIRL: 'テコガールの追加',
    RELEASE_TECO_GIRL: '会計',
    ADDITIONAL_ESTIMATED_AMOUNT: '追加目安金額',
    BUTTON_ADD_GIRL: 'テコガールを追加する',
    GAMES_TO_ORDER: '注文するゲーム',
    SELECT_TECO_GIRL: '注文するテコガールを選択する',
    SELECT_NOTE: '※選べばなければ全適用になります',
    GAME_ORDER: 'ゲーム注文',
    LIST_TECO_GIRL: 'テコガール一覧',
    MESSAGE_PROHIBITED_WARNING:
      '$wordは禁止ワードとなりますので、メッセージを送信できません。',
    GIVE_STAMPS_NOTE: 'テコガールに投げ銭スタンプを贈ることができます',
  },
  PAYMENT: {
    ADVANCE_PAYMENT: '前払い',
    PAYMENT_METHOD: 'お支払い方法',
    PAYMENT_SUCCESS: 'クレジットカード決済が完了しました',
    PAYMENT_CASH_SUCCESS: '現金決済を選択されました。',
    POINT_PAYMENT_SUCCESSFULLY: 'teco資産決済が完了しました。',
    CARD_NUMBER: 'カード番号',
    EXPIRATION_DATE: '有効期限',
    CARD_NAME: 'カード上の名前',
    SECURITY_CODE: 'セキュリティコード',
    CASH: '現金',
    CARD: 'クレジットカード',
    POINT_ASSET: 'teco資産',
    ADD_METHOD: '支払い方法を追加',
    NOTE: `※上記で選択したカードで決済エラーが発生した場合は、自動的に別の登録カードで決済されます。 カード情報を削除する場合は、削除したいカードを左にスワイプしてください。\n※プリペイドカードは登録できません。`,
    TO_PAYMENT: '支払いへ',
    CHOOSE_METHOD: 'お支払い方法を選択する',
    WAIT_MESSAGE: '決済方法についてメッセージしますのでお待ちください',
    CHOOSE_PAYMENT_METHOD_PLEASE: '支払い方法を選択してください',
    GO_TO_PAYMENT_PAGE: '決済ページはこちら',
    VISA: 'VISAカード',
  },
  RELEASE_GIRL: {
    RELEASE_AMOUNT: '会計金額',
    FREE_GIRL: '会計する',
    CHOOSE_GIRL: '会計するテコガールを選ぶ',
    NOTE_CHOOSE_GIRL: '※選ばなければ全会計になります',
    NUM_OF_SET: 'セット数（1セット/60分)',
    TOTAL_PRICE: 'セット料金',
    TOTAL: '合計',
    TOTAL_ABOVE: '上記の合計',
    DISCOUNT: '割引額',
    GAME_DISCOUNT: 'ゲーム代割引額',
    TOTAL_CASH: '合計 (現金払い)',
    TOTAL_CARD: '合計 (カード払い)',
    TOTAL_CASH_NO_DISCOUNT: '合計 (現金払い)',
    TOTAL_CARD_NO_DISCOUNT: '合計 (カード払い)',
    NOMINATION_FEE: '指名料金',
    HANDLING_FEE: '（消費税10%）',
    PRE_PAID: '前払い額 (カード払い)',
    SUB_TOTAL: '小計',
    PROJECT_DISCOUNT: '会計総額から割引額',
    PAYMENT_CONFIRM: '支払い確認',
    GAME_PAYMENT_NOTICE:
      'FCエリアではテコガール一人当たりのゲーム代の上限金額が$price万円になるので、今回のゲーム代は$price万円になります。',
  },
  REVIEW_GIRL: {
    QUESTIONNAIRE: 'テコガールへの\nアンケート',
    SEND: '送信する',
    TEXT_COMMENT: '自由記述コメント',
    RATING: '5段階評価',
    REVIEW_SUCCESS: '成功のレビュー',
  },
  TUTORIAL: {
    ADD_TO_HOME: 'tecoをホーム画面に追加する',
    FOR_IPHONE: 'iPhoneの場合',
    FOR_ANDROID: 'Androidの場合',
    IPHONE_TUTORIAL_STEP_1:
      'ブラウザ(safari)でWEBページを開いた状態で下部メニュー真ん中のアイコンをタップ',
    IPHONE_TUTORIAL_STEP_2:
      '出てきたメニュー内の「ホーム画面に追加」をタップ。',
    IPHONE_TUTORIAL_STEP_3: '画面右上の「追加」をタップ。',
    ANDROID_TUTORIAL_STEP_1:
      'Google Chromeを起動させアイコンを作りたいサイトを表示させます。右上にある「メニューアイコン」をタップします。',
    ANDROID_TUTORIAL_STEP_2:
      '「メニューアイコン」をタップすると「メニュー」の一覧が開きますので「ホーム画面に追加」をタップします。',
    ANDROID_TUTORIAL_STEP_3:
      '「ホーム画面に追加」の画面が表示されます。タイトルを入力したら「追加」をタップします。',
    TUTORIAL_STEP_4: 'ホーム画面にアイコンが追加され完了です',
  },
  GAME_PAGE: {
    WHAT_IS_TECO: 'tecoとは',
    GROUP_LEVERAGE_GAME: '団体テコ専用ゲーム',
    NORMAL_GAME: '通常のゲーム',
    NOTE_LEVERAGE_GAME:
      '【団体テコ専用ゲームで明朗会計に】\n①〝王様ゲーム〟が入った場合は以降のゲームや命令に追加料金は一切かかりません。\n②〝王様のコイン〟〝王様のアプリ〟〝王様ゲーム〟は全てのゲームと類する命令を行えます。\n③お客様とテコガールでほかのゲームや金額を自由にお決めいただいてもOKです。\n④たくさんゲームをする予定であればはじめに“王様のアプリ”や“王様ゲーム”をいれるほうがお得になる場合がございます。また“王様ゲーム”は１：１の個テコではご利用いただけません。\n⑤“王様のアプリ”から“王様ゲーム”に切り替える場合は差額の2万円をお支払いいただければスライド可能となっています',
    START_APP: 'アプリを起動',
    INDIVIDUAL_LEVER: '個テコ専用ゲーム',
    USEFUL_TIMER: '便利なタイマー',
    ABOUT_KING_APP: '-  王様のアプリについて-',
    KING_APP_STEP_1:
      '①王様のアプリ には軽い命令からハード♡な命令まで多種多様にはいっています',
    KING_APP_STEP_2: '②１：１の個テコでもかなり楽しめるものになっていまいます',
    KING_APP_STEP_3:
      '③王様のアプリ を入れた場合はそれ以上の追加料金はかかりません',
    GAME_SINGLE_NOTE: 'このゲームは個テコ専用ゲームです',
  },
  POST: {
    POST: 'ポストする',
    CONTENT_PLACEHOLDER: 'いまどうしている？',
    NEW_POSTS: '新規投稿を表示します',
    SUCCESS: '審査後に投稿されます',
    PAID_POST_NOTE: '投げ銭スタンプを送ると閲覧できます',
    BROWSE: '閲覧する',
    ALL_POST: '全てのつぶやき',
    PAID_POST: '有料のつぶやき',
  },
  GIFT: {
    NOTE: `※スタンプ送付後はいかなる理由があっても、 キャンセル・返金は一切できません。`,
    GIVE_GIFT: '$giftを贈る',
    PAYMENT_SUCCESS: '決済成功',
    PAYMENT_FAIL: '決済失敗',
    CONTINUE_PAY: '残額を決済する',
    CREDIT_REGISTRATION: 'クレジットカード決済',
    WARNING_MESSAGE: '現在のゲストランクでは送付できません',
    PAYMENT_PROCESSING_MESSAGE: '恐れ入りますが残額の決済をお願いいたします。',
    SUPPORT_PAYMENT: '※銀行振込デポジット(現金払い)機能は近々対応予定です',
  },
  POINTS: {
    TRANSFER_SCHEDULE_INFO: '振込予定情報',
    TITLE_OF_BUY_POINT: 'teco資産を購入する',
    POINT_HISTORY_TITLE: 'teco資産購入履歴',
    BANK_TRANSFER: '銀行振込',
    CREDIT_CARD: 'クレジットカード',
    OWNED_TECO_ASSETS: '所持teco資産',
    USAGE_HISTORY: '履歴',
    EXPIRED_POINTS: 'teco資産の有効期限は購入から179日です',
    PLEASE_TRANSFER_TO_BANK_ACCOUNT: '銀行口座にお振込をお願いいたします',
    BANK_ACCOUNT: '振込先銀行口座',
    FINANCIAL_INSTITUTION_NAME: '金融機関名',
    BRANCH_NAME: '支店名',
    ACCOUNT_TYPE: '口座種類',
    ACCOUNT_NUMBER: '口座番号',
    ACCOUNT_NAME: '口座名義',
    POINT_TERMS:
      'teco資産チャージは振込から通常24時間以内に反映されます。\n 銀行の営業時間外などで反映が遅れることもございます。\n また、ご購入いただいたteco資産は、いかなる場合でも返還いたしかねます。※1T=1円',
    ENTER_TRANSFER_NAME_AND_MONEY: '振込名義と金額を入力してください',
    TRANSFER_NAME: '振込名義(全角カナ)',
    TRANSFER_OF_MONEY: '振込金額',
    TECO_ASSETS_TO_BE_REFLECTED: '反映予定teco資産',
    PLEASE_PAY_BANK_TRANSER: '振込手数料はご自身にてご負担ください。',
    RATE: '1T=1円となります。',
    NOTES: '注意事項',
    NO_REFUND_WITH_ANY_REASON:
      '理由のいかんを問わずお振込いただいた金額の払い戻しやteco資産の換金はいたしません。',
    APPLY: '申請する',
    TRANSFER_APPLICATION: '振込申請',
    TECO_ASSET_CHARGE: 'teco資産チャージ',
    RELEASE: 'で解放',
    GUEST_RANK: 'ゲストランク',
    PURCHASE_CONFIRMATION: '購入確認',
    CONTENT_OF_PURCHASE_POINTS:
      '$priceで$amountを購入します。※購入後のキャンセル・返金は一切できません',
    BUY_NOW: '購入する',
    PURCHASED_TECO_ASSETS: 'teco資産を購入しました。',
    UNCHARGED: '未チャージ',
    CHARGED: 'チャージ済み',
    PLEASE_ENTER_KATAKANA: '全角カナで入力してください',
  },
  ERROR: {
    LIMIT_FILE: `画像またはビデオを$numberつ以上アップロードできません！`,
    LIMIT_SIZE: '写真は$sizeを超えてはなりません',
    LIMIT_NUMBER: '価格は$number円を超えることはできません',
  },
  INTRODUCING: {
    introducingAFriend: 'お友達紹介',
    step1: 'ページ下の「招待のURL」をコピーし、友達に共有する',
    step2:
      '「招待のURL」からお友達がtecoに登録すると、お友達へ10,000円分のteco資産をプレゼント',
    step3: 'お友達がtecoを初利用であなたに10,000円分のteco資産をプレゼント',
    invitationFrom:
      '・$nameさんからのご招待\n・tecoに登録すると、あなたに10,000円分のteco資産をプレゼント',
    tecoAssetsNote: '※teco資産はtecoのご注文などでご利用いただけます',
    invitationUrl: '招待のURL',
  },
};

export default TEXT_STRING;

import { TUnreadCount } from 'types';
import { create } from 'zustand';

type State = {
  unreadCount: TUnreadCount;
  adminList: string[];
};

type TUpdate = { channelId: string; value: number };

type Actions = {
  setUnreadCount: (state: TUnreadCount) => void;
  updateUnreadCount: (state: TUpdate) => void;
  resetUnreadCount: (channelId: string) => void;
  setAdminList: (list: string[]) => void;
};

const useMessageStore = create<State & Actions>((set) => ({
  unreadCount: {},
  adminList: [],
  setUnreadCount: (state: TUnreadCount) =>
    set(() => ({
      unreadCount: state,
    })),
  updateUnreadCount: ({ channelId, value }: TUpdate) =>
    set((prev) => {
      return {
        unreadCount: {
          ...prev.unreadCount,
          [channelId]: (prev.unreadCount[channelId] || 0) + value,
        },
      };
    }),
  resetUnreadCount: (channelId) =>
    set((prev) => {
      return {
        unreadCount: {
          ...prev.unreadCount,
          [channelId]: 0,
        },
      };
    }),
  setAdminList: (list: string[]) =>
    set(() => ({
      adminList: list,
    })),
}));

export default useMessageStore;

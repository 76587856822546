import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import Button from 'components/Button';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { pinChannel, unpinChannel } from 'api';
import { useMutation } from '@tanstack/react-query';
import { TChannel } from 'types';
import { useToast } from 'hooks';
import { detectMobile } from 'utils';

type Props = PropsWithChildren<{
  disabledAction?: boolean;
  channel: TChannel;
  onPinChannel?: (channelId: string, pinOrder: string) => void;
}>;

const MAX_POSITION_X = 80;

const ChannelActionItem = ({
  children,
  disabledAction = false,
  channel,
  onPinChannel,
}: Props) => {
  // Hooks
  const draggableRef = useRef<HTMLDivElement>(null);
  const { toastError } = useToast();
  const touchStartX = useRef<number>(0);
  const touchStartY = useRef<number>(0);

  // States
  const [positionX, setPositionX] = useState<number>(0);

  // Query, mutations
  const { mutateAsync: mutatePin, isPending: pinning } = useMutation({
    mutationFn: (channelId: string) => {
      return pinChannel(channelId);
    },
  });

  const { mutateAsync: mutateUnpin, isPending: unpinning } = useMutation({
    mutationFn: (channelId: string) => {
      return unpinChannel(channelId);
    },
  });

  // Memo, callback
  const isMobile = useMemo(() => detectMobile(), []);

  const handleTouchStart = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      if (disabledAction) return;
      touchStartX.current = e?.touches[0]?.pageX;
      touchStartY.current = e?.touches[0]?.pageY;
      if (draggableRef.current) {
        draggableRef.current.classList.remove('transition-all');
      }
    },
    [disabledAction]
  );

  const handleTouchMove = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      if (disabledAction) return;
      const diffX = e?.touches[0]?.pageX - touchStartX?.current;
      const diffY = e?.touches[0]?.pageY - touchStartY?.current;

      // Drag
      if (Math.abs(diffX) > Math.abs(diffY)) {
        setPositionX((prev) =>
          diffX >= 0
            ? Math.min(diffX, MAX_POSITION_X)
            : Math.max(prev + diffX, 0)
        );
      }
    },
    [disabledAction, touchStartX, touchStartY]
  );

  const handleTouchEnd = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      if (disabledAction) return;
      let position = 0;
      const lastTouch = e.changedTouches[0];
      if (lastTouch.pageX - touchStartX.current >= 40) {
        position = MAX_POSITION_X;
      }
      if (draggableRef.current) {
        draggableRef.current.classList.add('transition-all');
      }
      setPositionX(position);
    },
    [disabledAction]
  );

  const handleDragStop = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      let position = 0;
      if (data.lastX >= 40) {
        position = MAX_POSITION_X;
      }
      setPositionX(position);
    },
    []
  );

  const handlePinChannel = useCallback(
    async (pinOrder?: string) => {
      try {
        const res = !pinOrder
          ? await mutatePin(channel.id)
          : await mutateUnpin(channel.id);
        onPinChannel && onPinChannel(channel.id, res.pinOrder || '');
      } catch (error) {
        toastError(error as Error);
      }
    },
    [mutatePin, channel.id, mutateUnpin, onPinChannel, toastError]
  );

  return (
    <>
      <div className="absolute left-0 h-full py-1 w-24">
        <Button
          onClick={() => handlePinChannel(channel.pinOrder)}
          iconLeft={!channel.pinOrder ? 'pin' : 'unpin'}
          bgColor={
            !channel.pinOrder
              ? 'bg-primary hover:bg-primary-400'
              : 'bg-error hover:bg-error-400'
          }
          iconColor="white"
          className="h-full w-full"
          loading={pinning || unpinning}
          disabled={pinning || unpinning}
        ></Button>
      </div>
      <Draggable
        disabled={disabledAction || isMobile}
        axis="x"
        position={{ x: positionX, y: 0 }}
        scale={1}
        onStop={handleDragStop}
        bounds={{ left: 0, right: MAX_POSITION_X }}
        nodeRef={draggableRef}
      >
        <div
          ref={draggableRef}
          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}
          onTouchEndCapture={handleTouchEnd}
        >
          {children}
        </div>
      </Draggable>
    </>
  );
};

export default React.memo(ChannelActionItem);

import React from 'react';
import { Button, DraggableModal, IconButton, Text } from 'components';
import { TEXT_STRING } from '../../../constants';
import {
  EActionChat,
  EProjectType,
  EUserProvider,
  TChannelDetail,
  TProjectFemale,
} from 'types';
import { useChatStore } from 'store';
import AddGirl from './AddGirl';
import AddGame from './AddGame';
import ReleaseGirl from './ReleaseGirl';

const CHAT_STR = TEXT_STRING.CHAT;
const COMMON_STR = TEXT_STRING.COMMON;

const titleModal = {
  [EActionChat.SELECT_GAME]: '',
  [EActionChat.LIST_GAME]: '',
  [EActionChat.ORDER_METHOD]: '',
  [EActionChat.ADD_GIRL]: CHAT_STR.ADD_TECO_GIRL,
  [EActionChat.RELEASE_GIRL]: CHAT_STR.RELEASE_TECO_GIRL,
};

type Props = {
  channelDetail: TChannelDetail;
  females?: TProjectFemale[];
  isLoading?: boolean;
  refetch: () => void;
};

function ActionConversation({
  channelDetail,
  females,
  isLoading = false,
  refetch,
}: Props) {
  // Hook
  const { actionChatStep, setActionChatStep } = useChatStore();

  const actions = React.useMemo<
    {
      title: string;
      action: () => void;
      bg: string;
      color?: `text-${string}`;
      border: string;
      disabled: boolean;
      showButton: boolean;
    }[]
  >(
    () => [
      {
        title: COMMON_STR.GAME,
        action: () => {
          setActionChatStep(EActionChat.SELECT_GAME);
        },
        bg: 'bg-primary-50',
        color: 'text-primary',
        border: 'ring-1 ring-primary',
        disabled: !channelDetail.participant.some(
          (member) => member.isFemaleMeeting
        ),
        showButton: true,
      },
      {
        title: CHAT_STR.ADD_FEMALE,
        action: () => {
          setActionChatStep(EActionChat.ADD_GIRL);
        },
        bg: 'bg-primary-50',
        color: 'text-primary',
        border: 'ring-1 ring-primary',
        disabled: !channelDetail.participant.some(
          (detail) =>
            EUserProvider.FEMALE_APPLICATION === detail.provider &&
            !detail.isFemaleReleased
        ),
        showButton: true,
      },
      {
        title: CHAT_STR.DISSOLUTION,
        action: () => {
          setActionChatStep(EActionChat.RELEASE_GIRL);
          refetch();
        },
        bg: 'bg-error',
        border: '',
        disabled:
          !females ||
          females?.every(({ isMeeting }: TProjectFemale) => !isMeeting),
        showButton: true,
      },
    ],
    [channelDetail.participant, females, refetch, setActionChatStep]
  );

  return (
    <>
      {channelDetail.projectType !== EProjectType.TOUR && (
        <div className="w-full bottom-0 flex gap-8px text-[14px] p-4 pt-0">
          {actions.map((item, index) => {
            return (
              item.showButton && (
                <Button
                  key={index}
                  onClick={item.action}
                  bgColor={item.bg}
                  textColor={item.color}
                  border={item.border}
                  padding="py-7 flex-1"
                  disabled={item.disabled}
                >
                  {item.title}
                </Button>
              )
            );
          })}
        </div>
      )}
      <DraggableModal
        onClose={() => setActionChatStep()}
        open={!!actionChatStep}
        zIndex="z-10"
        title={
          actionChatStep && (
            <aside className="flex justify-between p-16px items-center">
              <div></div>
              <Text bold fontSize={16} center>
                {titleModal[actionChatStep]}
              </Text>
              <div className="z-[1]">
                <IconButton
                  icon="close"
                  transparent
                  onClick={() => {
                    setActionChatStep();
                  }}
                />
              </div>
            </aside>
          )
        }
      >
        {actionChatStep === EActionChat.ADD_GIRL && (
          <AddGirl
            projectId={channelDetail.projectId}
            areaId={channelDetail.areaId}
          />
        )}
        {actionChatStep === EActionChat.SELECT_GAME && (
          <AddGame
            projectId={channelDetail.projectId}
            listGirlInMeeting={
              females?.filter((female) => female.isMeeting) || []
            }
            refetchFemales={refetch}
            isSingleMeeting={channelDetail.isSingleMeeting}
          />
        )}
        {actionChatStep === EActionChat.RELEASE_GIRL && (
          <ReleaseGirl
            females={females}
            isFetching={isLoading}
            refetchFemales={refetch}
            refetchData={refetch}
            projectData={{
              franchiseId: channelDetail.franchiseId,
              projectId: channelDetail.projectId,
              isSingleMeeting: channelDetail.isSingleMeeting,
              areaId: channelDetail.areaId,
              projectType: channelDetail.projectType,
              isFranchiseProject: channelDetail.isFranchiseProject,
            }}
          />
        )}
      </DraggableModal>
    </>
  );
}

export default ActionConversation;

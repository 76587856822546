import React from 'react';
import { TGift } from 'types/public';
import Text from 'components/Text';
import { formatNumber } from 'utils';
import { ReactComponent as LockIcon } from 'assets/images/lock.svg';

type Props = {
  gift: TGift;
  onClick?: () => void;
  isLocked?: boolean;
};

const GiftItem = ({ gift, onClick, isLocked = false }: Props) => {
  return (
    <>
      <div className="cursor-pointer" onClick={onClick}>
        <div className="w-full relative flex justify-center items-center">
          <img
            src={gift?.image}
            alt=""
            className={`${isLocked && 'opacity-30'}`}
          />
          {isLocked && (
            <div className="w-full h-full absolute flex justify-center items-center">
              <LockIcon />
            </div>
          )}
        </div>
        <Text bold center>
          {gift?.name}
        </Text>
        <Text
          bold
          center
          textColor="text-primary"
          fontSize={16}
          nowrap
        >{`${formatNumber(gift?.price)} ${gift?.currency}`}</Text>
      </div>
    </>
  );
};

export default GiftItem;

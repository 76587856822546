import Button from 'components/Button';
import React from 'react';

type Tab = {
  title: string;
  id: string;
};

type Props = {
  tabs: Tab[];
  currentTab: string;
  onChangeTab: (tab: string) => void;
  className?: string;
  textSize?: string;
};

const Tabs = ({ tabs, currentTab, onChangeTab, className = '', textSize }: Props) => {
  return (
    <div
      className={`${className} flex gap-1 p-1 bg-white rounded-lg ring-1 ring-primary`}
    >
      {tabs.map((tab, index) => {
        const isCurrentTab = currentTab === tab.id;
        return (
          <Button
            key={index}
            onClick={() => !isCurrentTab && onChangeTab(tab.id)}
            className={`flex-1 py-3 px-4 rounded-md focus:outline-none`}
            bgColor={`${isCurrentTab ? 'bg-primary' : ''}`}
            textColor={`${isCurrentTab ? 'text-white' : 'text-neutral'}`}
            textSize={textSize}
            bold
          >
            {tab.title}
          </Button>
        );
      })}
    </div>
  );
};

export default Tabs;

import axios from 'axios';
import { TSignedUrl } from 'types';

const getSignUrl = async (body: {
  filename: string;
  contentType: string;
  size: number;
  isPremiumFile?: boolean;
}): Promise<TSignedUrl> => {
  return await axios.post('upload/signed-url', body).then((response) => {
    return response.data;
  });
};

const uploadFile = async (preSignedUrl: string, file: File) => {
  const res = await fetch(preSignedUrl, { method: 'PUT', body: file });
  if (!res.ok) {
    console.error(res);
    throw new Error(res.statusText);
  }
  return res;
};

export { getSignUrl, uploadFile };

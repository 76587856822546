import { Icon, Text } from 'components';
import { useNavigate } from 'react-router-dom';
import { routes, TEXT_STRING } from '../../../constants';
import { usePointStore } from 'store';
import { formatAmountNumber } from 'utils';

const str = TEXT_STRING.POINTS;
function TecoAsset() {
  const { points } = usePointStore();
  const navigate = useNavigate();

  return (
    <div className="border border-primary bg-primary-50 rounded-xl py-5 px-7">
      <div className="flex items-center justify-between">
        <div className="flex gap-3 items-center">
          <Text
            textColor="text-neutral-950"
            fontSize={18}
            fontWeight="font-medium"
          >
            {str.OWNED_TECO_ASSETS}
          </Text>
          <div className="w-7 h-7">
            <Icon icon={'teco'} />
          </div>
        </div>

        <div
          className="flex gap-[2px] cursor-pointer items-center"
          onClick={() => navigate(routes.POINT_USAGE_HISTORY)}
        >
          <Text textColor="text-primary" fontSize={16} fontWeight="font-medium">
            {str.USAGE_HISTORY}
          </Text>
          <Icon icon="chevronRight" color="var(--color-primary)" />
        </div>
      </div>

      <Text
        fontSize={28}
        textColor="text-neutral-950"
        bold
        className="py-4 pb-9"
      >
        {formatAmountNumber(points?.point || 0, TEXT_STRING.COMMON.POINT_UNIT)}
      </Text>

      <Text fontSize={14} textColor="text-primary">
        {str.EXPIRED_POINTS}
      </Text>
    </div>
  );
}

export default TecoAsset;

import { Button, Modal, Text } from 'components';
import { routes, TEXT_STRING } from '../../../constants';
import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { EChannelType, EOrderTravelStep, EUserProvider, TMember } from 'types';
import { useOrder, useToast } from 'hooks';
import { useMutation } from '@tanstack/react-query';
import { createLeverage } from 'api';
import { ConversationContext } from 'contexts';
import { useChatStore, useUserStore } from 'store';
import { useNavigate } from 'react-router-dom';
import { useOrderStore } from 'store';

type Props = {
  channelId?: string;
  channelType?: EChannelType;
  users: TMember[];
};
const ChatOrder = ({ channelId, channelType, users }: Props) => {
  const { userLeverageId, refetchLeverage } = useContext(ConversationContext);

  // Store
  const { isAuthenticated } = useUserStore();
  const {
    leveragePendingCreation,
    setLeveragePendingCreation,
    removeLeveragePending,
  } = useChatStore();

  // Hooks
  const { setSelectedGirlId, setIsPendingOrder, setOrderTravelStep } =
    useOrderStore();
  const { gotoCall } = useOrder();
  const { toastError } = useToast();
  const navigate = useNavigate();

  // State
  const [isShowModal, setShowModal] = useState<boolean>(false);

  // Mutate
  const { mutate: mutateCreate, isPending: isCreating } = useMutation({
    mutationFn: () => {
      return createLeverage(femaleId);
    },
    onSuccess() {
      setShowModal(false);
      refetchLeverage && refetchLeverage();
      removeLeveragePending();
    },
    onError(err) {
      toastError(err);
    },
  });

  const isPrivateChannel = useMemo(
    () => channelType === EChannelType.PRIVATE,
    [channelType]
  );

  const femaleId = useMemo(
    () =>
      users.find(
        (female) => female.provider === EUserProvider.FEMALE_APPLICATION
      )?.id,
    [users]
  );

  const orderMethods = useMemo(
    () => [
      {
        title: TEXT_STRING.CALL_WOMAN.CALL_BY_NAME,
        action: () => gotoCall(femaleId),
        disabled: false,
        loading: false,
      },
      {
        title: TEXT_STRING.CALL_WOMAN.LEVERAGE,
        description: TEXT_STRING.CALL_WOMAN.LEVERAGE_DESCRIPTION,
        action: () => {
          if (!isAuthenticated) {
            navigate(routes.SIGNUP);
            setLeveragePendingCreation(channelId);
          } else {
            mutateCreate();
          }
        },
        disabled: isCreating || !!userLeverageId,
        loading: isCreating,
      },
      {
        title: TEXT_STRING.CALL_WOMAN.GO_ON_TRIP,
        action: () => {
          setOrderTravelStep(EOrderTravelStep.ORDER_PRICE);
          setIsPendingOrder(false);
          setSelectedGirlId(femaleId);
          navigate(routes.ORDER_TRAVEL);
        },
        disabled: false,
      },
    ],
    [
      femaleId,
      isAuthenticated,
      navigate,
      isCreating,
      userLeverageId,
      channelId,
      mutateCreate,
      gotoCall,
      setLeveragePendingCreation,
      setIsPendingOrder,
      setSelectedGirlId,
      setOrderTravelStep,
    ]
  );

  useEffect(() => {
    if (
      isAuthenticated &&
      leveragePendingCreation &&
      leveragePendingCreation === channelId
    ) {
      mutateCreate();
    }
    return () => {};
  }, [isAuthenticated, leveragePendingCreation, channelId, mutateCreate]);

  return isPrivateChannel && users.every(({ inactive }) => !inactive) ? (
    <>
      <Button
        onClick={() => {
          setShowModal(true);
        }}
        className="text-xs whitespace-nowrap p-8px"
      >
        {TEXT_STRING.CALL_WOMAN.PLAY_WITH_KID}
      </Button>
      <Modal
        open={isShowModal}
        className="py-8 px-6 text-center bg-white w-3/4 flex flex-col gap-6"
        onClose={() => {
          setShowModal(false);
        }}
      >
        {orderMethods.map(
          ({ title, description, action, disabled, loading }, index) => (
            <div
              key={index}
              className="p-5 rounded-[8px] shadow-lg shadow-neutral-200 border border-neutral-50"
            >
              <Button
                className="py-5"
                onClick={action}
                block
                disabled={disabled}
                loading={loading}
              >
                {title}
              </Button>
              {description && (
                <Text className="whitespace-pre-line mt-4" bold>
                  {description}
                </Text>
              )}
            </div>
          )
        )}
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default memo(ChatOrder);

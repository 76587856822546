import IconButton from 'components/IconButton';
import TextField from 'components/TextField';
import React, { useState } from 'react';
import {
  EChannelType,
  TChannelDetail,
  TSendMessage,
  TTextFieldChangeEvent,
} from 'types';
import UploadFile from '../UploadFile';
import {
  IMAGE_ACCEPTED,
  MAX_FILE_SIZE_PUBNUB,
  ONE_MB,
  queryKeys,
  storageKeys,
  TEXT_STRING,
} from '../../../constants';
import FileView from 'components/FileView';
import { GiftBag } from 'components/Gift';
import { useAuth, useToast, useUser } from 'hooks';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getProhibitedWords } from 'api';
import PopupWarning from 'components/PopupWarning';
import Tooltip from 'components/Tooltip';

type Props = {
  sendMessage: (payload: TSendMessage) => Promise<void>;
  onTyping: (status: boolean) => void;
  showTyping: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  onDating?: () => void;
  femaleId?: number;
  channelData?: TChannelDetail;
};

let timeout: NodeJS.Timeout;

const TYPING_TIME = 3000;
const PAGE_SIZE = 500;

const MessageInput = ({
  sendMessage,
  onTyping,
  showTyping,
  onBlur,
  onFocus,
  onDating,
  femaleId,
  channelData,
}: Props) => {
  // Hooks
  const ref = React.useRef(true);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { checkNeedToLogin } = useAuth();
  const { toastError } = useToast();
  const { refetchPoints } = useUser();

  // States
  const [message, setMessage] = React.useState<string>('');
  const [files, setFiles] = React.useState<File[]>([]);
  const [isTyping, setIsTyping] = React.useState<boolean>(false);
  const [isSending, setIsSending] = React.useState<boolean>(false);
  const [buttonClicked, setButtonClicked] = React.useState<boolean>(false);
  const [showGift, setShowGift] = React.useState<boolean>(false);
  const [prohibitedWord, setProhibitedWord] = React.useState<string>('');
  const [showTooltip, setShowTooltip] = useState<boolean>(
    !localStorage.getItem(storageKeys.GIFT_SUGGESTIONS_DISPLAYED)
  );

  // Query, mutation
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [queryKeys.PROHIBITED_WORDS],
      queryFn: async ({ pageParam }) =>
        getProhibitedWords(pageParam, PAGE_SIZE, ''),
      initialPageParam: 1,
      getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) => {
        return lastPage?.totalPage > lastPageParam
          ? lastPageParam + 1
          : undefined;
      },
    });

  // Memo, callbacks
  const listProhibitedWord = React.useMemo(
    () =>
      data?.pages.flatMap(({ data }) =>
        data.map((item) => item.content.toLowerCase())
      ) ?? [],
    [data]
  );

  const onChangeFiles = React.useCallback(
    (files: File[]) => {
      if (files[0]?.size > MAX_FILE_SIZE_PUBNUB) {
        return toastError(
          new Error(
            TEXT_STRING.ERROR.LIMIT_SIZE.replace(
              '$size',
              `${MAX_FILE_SIZE_PUBNUB / ONE_MB}mb`
            )
          )
        );
      }
      setFiles(files);
    },
    [toastError]
  );

  const onChangeMessage = React.useCallback(
    (event: TTextFieldChangeEvent) => {
      setMessage(event.target.value);
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        setIsTyping(false);
      }, TYPING_TIME);
      setIsTyping(true);
    },
    [setMessage]
  );

  const onSendMessage = React.useCallback(() => {
    const messageLowerCase = message?.toLowerCase();
    const word = listProhibitedWord.find((word) =>
      messageLowerCase.includes(word)
    );
    if (word) return setProhibitedWord(word);
    if (inputRef.current) inputRef.current.focus();
    if (message.trim() || files.length) {
      setIsSending(true);
      sendMessage({ message: message?.trim(), file: files?.[0] })
        .then(() => {
          onTyping(false);
          setMessage('');
          setFiles([]);
        })
        .finally(() => {
          setIsSending(false);
        });
    }
  }, [message, listProhibitedWord, files, sendMessage, onTyping]);

  const handleBlur = React.useCallback(() => {
    if (buttonClicked) return setButtonClicked(false);
    onBlur && onBlur();
  }, [buttonClicked, onBlur]);

  const handleCloseTooltip = React.useCallback(() => {
    setShowTooltip(false);
    localStorage.setItem(storageKeys.GIFT_SUGGESTIONS_DISPLAYED, 'true');
  }, []);

  // Effect
  React.useEffect(() => {
    if (!ref.current) {
      onTyping(isTyping);
    } else ref.current = false;
    return () => {};
  }, [isTyping, onTyping]);

  React.useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  return (
    <>
      {!!files?.length && (
        <div className="flex gap-4 overflow-x-auto p-2 h-24">
          {files.map((file, index) => (
            <FileView
              className="h-full"
              key={index}
              file={file}
              onRemove={() => {
                setFiles((prev) => prev.filter((_, idx) => idx !== index));
              }}
            />
          ))}
        </div>
      )}
      {showTyping && (
        <div className="flex space-x-2 items-end px-2">
          <p className="">{TEXT_STRING.COMMON.TYPING}</p>
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.05s] mb-1" />
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.025s] mb-1" />
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce mb-1" />
        </div>
      )}

      <div
        className={`flex gap-8 items-center pl-6 pr-5 py-6 rounded-lg relative ${
          showGift && 'shadow-lg'
        }`}
      >
        {onDating && (
          <IconButton
            icon="calendarHeart"
            onClick={onDating}
            className="scale-[1.75] mb-4px"
            transparent
            disabled={isSending}
          />
        )}
        <UploadFile
          onChangeFiles={onChangeFiles}
          accept={IMAGE_ACCEPTED.join(',')}
          disabled={isSending}
          buttonClass="scale-[1.75]"
        />
        {channelData?.type === EChannelType.PRIVATE && femaleId && (
          <>
            <Tooltip
              show={showTooltip}
              withOverplay
              content={TEXT_STRING.CHAT.GIVE_STAMPS_NOTE}
              className="bottom-full mb-8 mr-6"
              triangleClass="ml-14"
              onClose={handleCloseTooltip}
            />
            <IconButton
              icon="gift"
              onClick={() => {
                if (!checkNeedToLogin()) {
                  setShowGift((prev) => !prev);
                  refetchPoints();
                  handleCloseTooltip();
                }
              }}
              className={`scale-[1.75] mb-4px ${
                showTooltip && 'relative z-50 bg-white p-3'
              }`}
              transparent
              disabled={isSending}
              iconColor={
                showGift ? 'var(--color-primary)' : 'var(--color-neutral)'
              }
            />
          </>
        )}
        <TextField
          type="textarea"
          name="message"
          onChange={onChangeMessage}
          value={message}
          rows={Math.min(message.split('\n').length || 1, 5)}
          className="w-full"
          inputClass="resize-none py-4"
          placeholder={
            channelData?.type === EChannelType.MALE_MANAGEMENT
              ? TEXT_STRING.MESSAGE.QUESTION_PLACEHOLDER
              : TEXT_STRING.MESSAGE.INPUT_PLACEHOLDER
          }
          // disabled={isSending}
          inputRef={inputRef}
          onBlur={handleBlur}
          onFocus={() => {
            onFocus && onFocus();
            setShowGift(false);
          }}
        />
        <div onMouseDown={() => setButtonClicked(true)}>
          <IconButton
            icon="sendMessage"
            onClick={() => onSendMessage()}
            className="text-blue-600 scale-[1.75]"
            transparent
            disabled={isSending}
          />
        </div>
      </div>
      <GiftBag
        sendMessage={sendMessage}
        femaleId={femaleId}
        showGift={showGift}
        channelData={channelData}
      />
      <PopupWarning
        open={!!prohibitedWord}
        content={TEXT_STRING.CHAT.MESSAGE_PROHIBITED_WARNING.replace(
          '$word',
          prohibitedWord
        )}
        title={TEXT_STRING.COMMON.WARNING}
        onClose={() => setProhibitedWord('')}
        onClick={() => setProhibitedWord('')}
      />
    </>
  );
};

export default MessageInput;

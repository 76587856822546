import Rating from 'components/Rating';
import { queryKeys, TEXT_STRING } from '../../../../../constants';
import { useMemo } from 'react';
import { useUserStore } from 'store';
import { TGroupListPointsResponse, TListPointsResponse } from 'types';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { formatAmountNumber } from 'utils';
import Button from 'components/Button';
import { useQuery } from '@tanstack/react-query';
import { getListPoints } from 'api';
import Loading from 'components/Loading';

type Props = {
  onClick: (point: TListPointsResponse) => void;
};

const str = TEXT_STRING.POINTS;
const commonStr = TEXT_STRING.COMMON;

function ListPoints({ onClick }: Props) {
  const { user } = useUserStore();

  const { data, isLoading } = useQuery({
    queryKey: [queryKeys.LIST_POINTS],
    queryFn: () => getListPoints(),
  });

  const userRanking = useMemo(
    () => user?.total.ranking || 0,
    [user?.total.ranking]
  );

  const groupPointsByStar = useMemo(
    () =>
      data?.reduce((acc: TGroupListPointsResponse[], item) => {
        const currentPoints = acc.find(
          (point) => point.starRequired === item.starRequired
        );
        if (!currentPoints) {
          acc.push({ starRequired: item.starRequired, list: [item] });
        } else {
          currentPoints.list.push(item);
        }
        return acc;
      }, []),
    [data]
  );

  return (
    <>
      {isLoading ? (
        <div className="py-9">
          <Loading />
        </div>
      ) : !groupPointsByStar?.length ? (
        <Text bold center className="py-16">
          {commonStr.NO_DATA}
        </Text>
      ) : (
        <div className="overflow-y-auto mt-4">
          {groupPointsByStar?.map((group, index) => (
            <div key={index}>
              {group.starRequired > userRanking ? (
                <div className="flex gap-1 justify-center items-center py-[6px] bg-primary-100 border-y border-primary-600">
                  <Text textColor="text-primary-600" fontWeight="font-medium">
                    {str.GUEST_RANK}
                  </Text>
                  <Rating value={group.starRequired} />
                  <Text textColor="text-primary-600" fontWeight="font-medium">
                    {str.RELEASE}
                  </Text>
                </div>
              ) : null}

              {group.list.map((point) => (
                <div
                  key={point.id}
                  className="flex justify-between items-center py-4 border-b"
                >
                  <div className="flex gap-3 items-center">
                    <div className="w-[26px] h-[26px]">
                      <Icon icon={'teco'} />
                    </div>
                    <Text
                      fontSize={16}
                      fontWeight="font-medium"
                      textColor="text-neutral-800"
                    >
                      {formatAmountNumber(point.amount, commonStr.POINT_UNIT)}
                    </Text>
                  </div>

                  <Button
                    className="w-[130px] py-2 font-medium text-base"
                    onClick={() => onClick(point)}
                    disabled={point.starRequired > userRanking}
                  >
                    {formatAmountNumber(point.price)}
                  </Button>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default ListPoints;

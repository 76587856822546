import Label from 'components/Label';
import React, { ReactElement } from 'react';
import { TTextFieldChangeEvent } from 'types';

type Props = {
  className?: string;
  inputClass?: string;
  name: string;
  value: string | number;
  type?: string;
  autoComplete?: string;
  label?: string;
  placeholder?: string;
  bold?: boolean;
  prefix?: ReactElement;
  suffix?: ReactElement;
  disabled?: boolean;
  maxLength?: number;
  textSize?: string;
  onChange: (event: TTextFieldChangeEvent) => void;
  onBlur?: (event: TTextFieldChangeEvent) => void;
  onFocus?: (event: TTextFieldChangeEvent) => void;
  onEnter?: () => void;
  error?: boolean;
  require?: boolean;
  rows?: number;
  max?: number;
  min?: number;
  readOnly?: boolean;
  noBorder?: boolean;
  inputRef?: any;
  inputPadding?: string;
  afficPadding?: string;
};

function TextField({
  className = '',
  inputClass = '',
  name,
  value,
  type = 'text',
  autoComplete,
  label,
  placeholder,
  prefix,
  suffix,
  bold,
  disabled = false,
  maxLength,
  textSize = '',
  onChange,
  onBlur,
  onFocus,
  onEnter,
  error,
  require,
  rows,
  max,
  min,
  readOnly = false,
  noBorder,
  inputRef,
  inputPadding = 'p-2.5',
  afficPadding = 'pr-2',
}: Props) {
  const isTextArea = type === 'textarea';
  const Input = isTextArea ? 'textarea' : 'input';

  const handleKeyUp = (
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (e.key === 'Enter' && onEnter) {
      onEnter();
    }
  };

  return (
    <div className="w-full">
      {label && (
        <Label require={require} error={error} bold={bold}>
          {label}
        </Label>
      )}
      <div
        className={`${className}
				${disabled ? 'opacity-50' : ''}
				 flex items-center rounded-lg
         ${
           noBorder
             ? 'ring-0 focus:ring-0'
             : 'ring-1 focus-within:ring-2 focus-within:ring-primary'
         }
				 ${error ? `ring-error` : `ring-neutral-300`} 
         ${!isTextArea && (prefix || suffix) ? afficPadding : ''}`}
      >
        {!isTextArea && prefix}
        <Input
          type={type}
          name={name}
          id={name}
          autoComplete={autoComplete}
          className={` ${textSize} ${inputClass} ${inputPadding} block w-full border-0 bg-transparent placeholder:text-neutral-300 ring-0 focus:ring-0`}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          disabled={disabled}
          maxLength={maxLength}
          onKeyUp={handleKeyUp}
          rows={rows}
          max={max}
          min={min}
          readOnly={readOnly}
          ref={inputRef}
        />
        {!isTextArea && suffix}
      </div>
    </div>
  );
}

export default TextField;

import axios from 'axios';
import { TListResult } from 'types';

const getProhibitedWords = async (
  currentPage: number,
  pageSize: number,
  search?: string
): Promise<TListResult<{ id: number; content: string; type: string }>> => {
  const res = await axios
    .get('prohibited', {
      params: {
        currentPage,
        pageSize,
        search,
      },
    })
    .then(function (response) {
      return response.data;
    });

  return res;
};

export { getProhibitedWords };

import Icon from 'components/Icon';
import Text from 'components/Text';

type Props = {
  isActive?: boolean;
  onClick?: () => void;
  icon?: string;
  label?: string;
  disabled?: boolean;
  subLabel?: string;
};

const Method = ({
  isActive = false,
  onClick,
  icon,
  label,
  disabled = false,
  subLabel = '',
}: Props) => {
  return (
    <>
      <div
        className={`basis-1/2 border rounded-md cursor-pointer ${
          isActive ? 'border-primary bg-primary-50' : ''
        } ${disabled && 'pointer-events-none opacity-60'}`}
        onClick={onClick}
      >
        <div
          className={`w-5 aspect-square rounded-full ${
            isActive
              ? 'bg-primary ring-primary border-4 border-solid border-white'
              : 'ring-neutral-200'
          } ring-1 cursor-pointer ml-auto mr-2 mt-2`}
        ></div>
        <div className={`flex gap-3 px-5 ${subLabel ? 'pb-2' : 'pb-7'}`}>
          {icon && (
            <div className="w-7 h-7">
              <Icon icon={icon} />
            </div>
          )}
          <Text>{`${label}\n${subLabel}`}</Text>
        </div>
      </div>
    </>
  );
};

export default Method;

import Text from 'components/Text';
import { DATE_TIME_FORMAT, routes } from '../../constants';
import { memo, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { EUserProvider, TPostDetail } from 'types';
import { formatDate } from 'utils';
import { Avatar, ModalGift, PostGallery } from 'components';
import { useNavigate } from 'react-router-dom';
import { usePostStore } from 'store';
import { useUser } from 'hooks';

type Props = PropsWithChildren<{
  data: TPostDetail;
  disableClick?: boolean;
  onClickAvatar?: () => void;
  onLoad?: () => void;
  className?: string;
  onConfirmGift?: (data: TPostDetail) => void;
}>;

function PostItem({
  data: postDetail,
  disableClick = false,
  onClickAvatar,
  onLoad,
  className = '',
  onConfirmGift,
}: Props) {
  // Hooks
  const navigate = useNavigate();
  const { listPostPaid } = usePostStore();
  const { lockedGift, refetchPoints } = useUser();

  // States
  const [openGift, setOpenGift] = useState<boolean>(false);

  // Memo, callbacks
  const data = useMemo(
    () => listPostPaid.find((post) => post.id === postDetail.id) || postDetail,
    [listPostPaid, postDetail]
  );

  const navigateToDetail = useCallback(() => {
    if (disableClick) return;
    onClickAvatar && onClickAvatar();
    navigate(routes.DETAIL_FEMALE.replace(':id', data.user.id));
  }, [data.user.id, disableClick, navigate, onClickAvatar]);

  return (
    <>
      <div className={className}>
        <div className={`flex gap-3`}>
          <div className={`flex-[1]`}>
            <Avatar
              onClick={navigateToDetail}
              circle
              url={data.user.avatar}
              canViewFull={
                data.user.applicationProvider === EUserProvider.MALE_APPLICATION
              }
            />
          </div>

          <div className={`flex-[7]`}>
            <Text className="w-fit">{data.user.nickname}</Text>
            <Text textColor="text-neutral" className="mt-1">
              {formatDate(data.approveDatetime, DATE_TIME_FORMAT)}
            </Text>
            <Text textColor="text-black" className="mt-2">
              {data.content}
            </Text>
            {!!data.media?.length && (
              <PostGallery
                id={data.id}
                data={data.media}
                onLoad={onLoad}
                isLockedPost={data?.isPremium && !data?.isPaidPremium}
                isPremiumPost={data?.isPremium}
                onPaidPost={() => {
                  setOpenGift(true);
                  refetchPoints();
                }}
              />
            )}
          </div>
        </div>
      </div>
      <ModalGift
        open={openGift}
        onClose={() => setOpenGift(false)}
        onConfirm={() => {
          setOpenGift(false);
          onConfirmGift && onConfirmGift(data);
        }}
        gift={data?.gift}
        isLocked={lockedGift(data?.gift)}
      />
    </>
  );
}

export default memo(PostItem);

import { VIDEO_ACCEPTED } from '../constants';
import { useCallback, useMemo, useRef, useState } from 'react';

const Video = ({
  url,
  height,
  poster,
  className = '',
}: {
  url: string;
  height?: number;
  poster?: string;
  className?: string;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = useCallback(() => {
    setIsPlaying(true);
    if (videoRef) {
      videoRef?.current?.play();
    }
  }, []);

  return (
    <div className={`relative ${className}`} style={{ height, width: '100%' }}>
      <video
        controls={isPlaying}
        playsInline
        ref={videoRef}
        controlsList="nodownload"
        poster={poster}
        style={{ width: '100%', maxHeight: '100%', objectFit: 'cover' }}
      >
        <source src={url} />
      </video>

      {!isPlaying && (
        <div className="absolute bottom-0 left-0 w-full h-full">
          <>
            <div
              className="w-full h-full absolute flex justify-center items-center cursor-pointer"
              onClick={handlePlay}
            >
              <div className="w-1/4 aspect-square rounded-full border-4 border-solid border-white"></div>
              <div className="w-0 h-0 ml-1 absolute border-solid border-y-[12px] border-y-transparent border-l-[24px] border-l-white"></div>
            </div>
          </>
        </div>
      )}
    </div>
  );
};

function useSize({
  defaultHeight,
  url,
  resolution,
  poster,
  className = '',
  redirectUrl,
}: {
  url?: string;
  resolution?: string;
  defaultHeight?: number;
  poster?: string;
  className?: string;
  redirectUrl?: string;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [bannerHeight, setBannerHeight] = useState<number | undefined>(
    defaultHeight
  );

  const isVideo = useMemo(() => {
    if (!url) return false;
    const [extension] = url.split('.').reverse();
    return VIDEO_ACCEPTED.map((type) => type.split('/')[1]).includes(extension);
  }, [url]);

  const onClickBanner = useCallback(() => {
    if (redirectUrl) {
      window.open(redirectUrl, '_blank,_self');
    }
  }, [redirectUrl]);

  const getSize = useCallback(
    (width: number) => {
      return new Promise<number>((resolve) => {
        const onloadFn = (height: number) => {
          setBannerHeight(height);
          setIsLoading(false);
          resolve(height);
        };
        if (url && !bannerHeight) {
          setIsLoading(true);
          const [bannerWidth, bannerHeight] = resolution?.split('x') || [];
          if (bannerWidth && bannerHeight) {
            onloadFn((width * +bannerHeight) / +bannerWidth);
          } else if (isVideo) {
            const video = document.createElement('video');
            video.src = url;
            video.onloadedmetadata = () => {
              onloadFn((width * video.videoHeight) / video.videoWidth);
            };
            video.onerror = () => {
              setIsLoading(false);
              resolve(0);
            };
          } else {
            const image = new Image();
            image.src = url;
            image.onload = () => {
              onloadFn((width * image.naturalHeight) / image.naturalWidth);
            };
            image.onerror = () => {
              setIsLoading(false);
              resolve(0);
            };
          }
        }
      });
    },
    [isVideo, url, resolution, bannerHeight]
  );

  const BannerView = useMemo(() => {
    if (!url) return null;
    return isVideo ? (
      <Video
        url={url}
        height={bannerHeight}
        poster={poster}
        className={className}
      />
    ) : (
      <img
        src={url}
        alt=""
        className={`w-full ${redirectUrl ? 'cursor-pointer' : ''}`}
        style={{ height: bannerHeight }}
        onClick={redirectUrl ? onClickBanner : undefined}
      />
    );
  }, [
    bannerHeight,
    isVideo,
    poster,
    url,
    className,
    redirectUrl,
    onClickBanner,
  ]);

  return { isLoading, getSize, BannerView };
}

export default useSize;

import { FIELD_NAME, initialTravelValue, initialValueCallWoman } from 'helpers';
import { EOrderStep, EOrderTravelStep, TFemale, TStarRank } from 'types';
import { create } from 'zustand';

type State = {
  orderStep?: EOrderStep;
  selectedGirlId?: number;
  orderFormState: Record<string, string>;
  listSelectedGirl: TFemale[];
  orderTravelStep: EOrderTravelStep;
  travelFormState: Record<string, string>;
  isPendingOrder?: boolean;
  selectedStarRank: TStarRank[];
};

type Actions = {
  setOrderStep: (value?: EOrderStep) => void;
  setSelectedGirlId: (value?: number) => void;
  setOrderFormState: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  setListSelectedGirl: React.Dispatch<React.SetStateAction<TFemale[]>>;
  redirectOrderStep: () => void;
  setTravelFormState: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  setOrderTravelStep: React.Dispatch<React.SetStateAction<EOrderTravelStep>>;
  setIsPendingOrder: (value: boolean) => void;
  setSelectedStarRank: React.Dispatch<React.SetStateAction<TStarRank[]>>;
};

const useOrderStore = create<State & Actions>((set) => ({
  listSelectedGirl: [],
  orderFormState: initialValueCallWoman,
  orderStep: EOrderStep.ORDER_METHOD,
  isPendingOrder: false,
  selectedStarRank: [],
  travelFormState: initialTravelValue,
  orderTravelStep: EOrderTravelStep.ORDER_PRICE,
  selectedGirlId: undefined,
  setOrderStep: (value?: EOrderStep) => set(() => ({ orderStep: value })),
  setSelectedGirlId: (value?: number) => set(() => ({ selectedGirlId: value })),
  setOrderFormState: (value: React.SetStateAction<Record<string, string>>) =>
    set((state) => ({
      orderFormState:
        typeof value === 'function' ? value(state.orderFormState) : value,
    })),

  setListSelectedGirl: (value: React.SetStateAction<TFemale[]>) =>
    set((state) => ({
      listSelectedGirl:
        typeof value === 'function' ? value(state.listSelectedGirl) : value,
    })),

  redirectOrderStep: () =>
    set((state) => ({
      orderStep:
        state.orderStep === EOrderStep.ORDER_DETAIL &&
        !!state.orderFormState[FIELD_NAME.MEETING_ADDRESS].trim()
          ? state.orderStep + 1
          : state.orderStep,
    })),
  setIsPendingOrder: (value: boolean) => set(() => ({ isPendingOrder: value })),
  setTravelFormState: (value: React.SetStateAction<Record<string, string>>) =>
    set((state) => ({
      travelFormState:
        typeof value === 'function' ? value(state.travelFormState) : value,
    })),
  setOrderTravelStep: (value: React.SetStateAction<EOrderTravelStep>) =>
    set((state) => ({
      orderTravelStep:
        typeof value === 'function' ? value(state.orderTravelStep) : value,
    })),
  setSelectedStarRank: (value: React.SetStateAction<TStarRank[]>) =>
    set((state) => ({
      selectedStarRank:
        typeof value === 'function' ? value(state.selectedStarRank) : value,
    })),
}));

export default useOrderStore;

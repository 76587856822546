import { Avatar, IconButton, Text, UploadImage } from 'components';
import { MAX_FILE_SIZE, ONE_MB, TEXT_STRING } from '../../../constants';
import { useCallback, useState } from 'react';
import { getImageURL } from 'utils';

type Props = {
  onUpdateAvatar: (file: File) => void;
  avatar?: string | File;
  status?: string;
};

function UpdateAvatar({ onUpdateAvatar, avatar, status }: Props) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const handleChangeFile = useCallback(
    (file: File) => {
      setErrorMessage('');
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage(
          TEXT_STRING.ERROR.LIMIT_SIZE.replace(
            '$size',
            `${MAX_FILE_SIZE / ONE_MB}mb`
          )
        );
      } else {
        onUpdateAvatar(file);
      }
    },
    [onUpdateAvatar]
  );

  return (
    <>
      <div className="flex justify-center py-8 relative">
        <div className="relative">
          {status && (
            <div className="absolute bg-black/50 w-full h-full flex justify-center items-center rounded-full">
              <Text textColor="text-white">{status}</Text>
            </div>
          )}
          <Avatar url={getImageURL(avatar)} circle size="w-48" />
        </div>
        <UploadImage
          onChangeFile={handleChangeFile}
          className="absolute bottom-3"
          renders={
            <IconButton
              onClick={() => {}}
              icon={'plus'}
              className="justify-center"
              size="w-10 h-10"
            />
          }
        />
      </div>
      <Text bold center textColor="text-error">
        {errorMessage}
      </Text>
    </>
  );
}

export default UpdateAvatar;

import { Button, IconButton, Text } from 'components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LIMIT_VALUE, TEXT_STRING } from '../../../../constants';
import {
  EPaymentType,
  ETransferStep,
  TBankDetail,
  TTextFieldChangeEvent,
  TTransferInfo,
} from 'types';
import { useMutation } from '@tanstack/react-query';
import { requestPoints, updateRequestPoints } from 'api';
import BankInforRow from '../BankInforRow';
import TextFieldRow from '../../TextFieldRow';
import { useToast } from 'hooks';
import Notice from '../../../Notice';
import parseJSON from 'utils/validateStorageVariable';
import { getFormattedAmount, validateFullWidthKatakana } from 'utils';

interface Props {
  bankData: TBankDetail | undefined;
  currentStep: ETransferStep;
  setCurrentStep: (value: ETransferStep) => void;
}

enum EBankField {
  BANK_NAME = 'bankName',
  POINT_PRICE = 'pointPrice',
}

const str = TEXT_STRING.POINTS;

const bankAccount = parseJSON(process.env.REACT_APP_BANK_ACCOUNT);
const BankInfor = [
  {
    Label: str.FINANCIAL_INSTITUTION_NAME,
    value: bankAccount?.FINANCIAL_INSTITUTION_NAME || '',
  },
  {
    Label: str.BRANCH_NAME,
    value: bankAccount?.BRANCH_NAME || '',
  },
  {
    Label: str.ACCOUNT_TYPE,
    value: bankAccount?.ACCOUNT_TYPE || '',
  },
  {
    Label: str.ACCOUNT_NUMBER,
    value: bankAccount?.ACCOUNT_NUMBER || '',
  },
  {
    Label: str.ACCOUNT_NAME,
    value: bankAccount?.ACCOUNT_NAME || '',
  },
];

function BankTransfer({ bankData, currentStep, setCurrentStep }: Props) {
  const { toastError, toastSuccess } = useToast();

  const [formState, setFormState] = useState<TTransferInfo>({
    [EBankField.BANK_NAME]: '',
    [EBankField.POINT_PRICE]: '',
  });
  const [bankNameErrorMsg, setBankNameErrorMsg] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const isFormValid = useMemo(() => {
    return Object.values(formState).every((value) => value);
  }, [formState]);

  const [isApplyStep, isTransfer] = useMemo(
    () => [
      currentStep === ETransferStep.APPLY,
      currentStep === ETransferStep.TRANSFER,
    ],
    [currentStep]
  );

  const handleChangeState = useCallback((key: EBankField, value: string) => {
    setFormState((prev) => ({ ...prev, [key]: value }));
  }, []);

  const { mutate: mutateRequestPoint, isPending } = useMutation({
    mutationFn: () =>
      requestPoints({
        bankName: formState.bankName,
        pointPrice: +formState.pointPrice,
        type: EPaymentType.BANK,
      }),
    onSuccess: () => {
      setCurrentStep(ETransferStep.TRANSFER);
      toastSuccess(TEXT_STRING.COMMON.SUCCESS);
    },
    onError: (error) => toastError(error),
  });

  const { mutate: mutateUpdateRequestPoint, isPending: updating } = useMutation(
    {
      mutationFn: () =>
        updateRequestPoints({
          bankName: formState.bankName,
          amount: +formState.pointPrice,
          pendingId: bankData?.id || 0,
        }),
      onSuccess: () => {
        setIsEdit(false);
        setCurrentStep(ETransferStep.TRANSFER);
        toastSuccess(TEXT_STRING.COMMON.SUCCESS);
      },
      onError: (error) => toastError(error),
    }
  );

  const bankControlFields = useMemo(
    () => [
      {
        label: str.TRANSFER_NAME,
        name: EBankField.BANK_NAME,
        value: formState.bankName,
        error: bankNameErrorMsg,
        onChange: (e: TTextFieldChangeEvent) =>
          handleChangeState(EBankField.BANK_NAME, e.target.value),
        onBlur: () => {
          !validateFullWidthKatakana(formState.bankName)
            ? setBankNameErrorMsg(str.PLEASE_ENTER_KATAKANA)
            : setBankNameErrorMsg('');
        },
      },
      {
        label: str.TRANSFER_OF_MONEY,
        name: EBankField.POINT_PRICE,
        value: formState.pointPrice,
        onChange: (e: TTextFieldChangeEvent) => {
          if (+e.target.value) {
            handleChangeState(EBankField.POINT_PRICE, e.target.value);
          } else if (!e.target.value.length) {
            handleChangeState(EBankField.POINT_PRICE, '');
          }
        },
        suffix: (
          <Text
            className="leading-8"
            fontSize={16}
            fontWeight="font-normal"
            textColor="text-neutral-700"
          >
            {TEXT_STRING.COMMON.YEN}
          </Text>
        ),
      },
      {
        label: str.TECO_ASSETS_TO_BE_REFLECTED,
        isShowValue: true,
        showValue: formState.pointPrice,
      },
    ],
    [
      bankNameErrorMsg,
      formState.bankName,
      formState.pointPrice,
      handleChangeState,
    ]
  );

  const handleApplyPoint = useCallback(() => {
    if (+formState.pointPrice > LIMIT_VALUE.MAX_NUMBER) {
      return toastError(
        new Error(
          TEXT_STRING.ERROR.LIMIT_NUMBER.replace(
            '$number',
            `${getFormattedAmount(LIMIT_VALUE.MAX_NUMBER)}`
          )
        )
      );
    }
    isEdit ? mutateUpdateRequestPoint() : mutateRequestPoint();
  }, [
    formState.pointPrice,
    isEdit,
    mutateRequestPoint,
    mutateUpdateRequestPoint,
    toastError,
  ]);

  useEffect(() => {
    if (bankData) {
      setFormState({
        bankName: bankData?.bankName,
        pointPrice: bankData?.pointAmount.toString(),
      });
    }
  }, [bankData, bankData?.bankName, bankData?.pointAmount]);

  return (
    <div className="flex flex-col gap-7">
      {!isApplyStep ? (
        <div>
          <Text
            className="leading-8 pb-8"
            center
            fontSize={16}
            textColor="text-neutral-700"
            fontWeight="font-medium"
          >
            {str.PLEASE_TRANSFER_TO_BANK_ACCOUNT}
          </Text>

          <div className="flex flex-col gap-7">
            <Text
              className="leading-8"
              fontSize={16}
              fontWeight="font-medium"
              textColor="text-neutral-700"
            >
              {str.BANK_ACCOUNT}
            </Text>

            <div className="border rounded-xl p-5 bg-neutral-25 flex flex-col gap-3">
              {BankInfor.map((item, index) => (
                <BankInforRow
                  key={index}
                  label={item.Label}
                  value={item.value || ''}
                />
              ))}
            </div>

            <Text
              className="leading-7"
              textColor="text-neutral-700"
              fontSize={14}
            >
              {str.POINT_TERMS}
            </Text>
          </div>
        </div>
      ) : null}

      <div className="flex flex-col gap-7">
        <div className="flex justify-between items-center">
          <Text
            className="leading-8"
            textColor="text-neutral-700"
            fontSize={16}
            fontWeight="font-medium"
            center={isApplyStep}
          >
            {!isApplyStep
              ? str.TRANSFER_SCHEDULE_INFO
              : str.ENTER_TRANSFER_NAME_AND_MONEY}
          </Text>
          {!isEdit && isTransfer && (
            <IconButton
              onClick={() => {
                setIsEdit(true);
                setCurrentStep(ETransferStep.APPLY);
              }}
              icon={'edit'}
              transparent
            />
          )}
        </div>

        <div className="border rounded-xl p-7 bg-neutral-25">
          {bankControlFields.map((field, index) => (
            <TextFieldRow
              key={index}
              label={field.label}
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              showValue={field.showValue}
              isShowValue={field.isShowValue}
              isDisabled={!isApplyStep || isPending || updating}
              suffix={field.suffix}
              error={field.error}
            ></TextFieldRow>
          ))}

          <div>
            <Notice content={str.PLEASE_PAY_BANK_TRANSER} />
            <Notice content={str.RATE} />
          </div>
        </div>

        <div>
          <Text
            className="leading-7"
            fontSize={14}
            textColor="text-neutral-700"
          >
            {str.NOTES}
          </Text>
          <Notice content={str.NO_REFUND_WITH_ANY_REASON} />
          <Notice content={str.EXPIRED_POINTS} />
        </div>

        {isApplyStep ? (
          <Button
            onClick={handleApplyPoint}
            className="w-full py-4.5"
            textSize="text-sm"
            disabled={
              !isFormValid ||
              isPending ||
              updating ||
              !!bankNameErrorMsg ||
              (+formState?.pointPrice === bankData?.pointAmount &&
                formState.bankName === bankData?.bankName)
            }
            loading={isPending || updating}
          >
            {str.APPLY}
          </Button>
        ) : null}
      </div>
    </div>
  );
}

export default BankTransfer;

import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ConversationAction,
  Chat,
  Header,
  Loading,
  PageWrapper,
  Text,
  LeverageActions,
} from 'components';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  ERROR_CODE,
  TEXT_STRING,
  queryKeys,
  routes,
  storageKeys,
} from '../../constants';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createChannel,
  getChannelDetail,
  getClientChannelDetail,
  getProjectFemales,
  getPendingProjectLeverage,
  getTotalProjectLeverageApproved,
} from 'api';
import { AxiosError } from 'axios';
import { useUserStore } from 'store';
import { EChannelType, EUserProvider, TCreateChannel } from 'types';
import { ConversationContext } from 'contexts';

const readonlyConversation = [EChannelType.MALE_SYSTEM_MANAGEMENT];

function Conversation() {
  // Hook
  const { user, clientId, isAuthenticated } = useUserStore();
  const { id: channel } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const createId = searchParams.get('create-id');
  const navigate = useNavigate();

  // State
  const [showAction, setShowAction] = useState<boolean>(true);

  // Query
  const {
    data,
    error,
    isLoading: loadingChannelDetail,
    refetch,
    isError,
  } = useQuery({
    queryKey: [queryKeys.CHANNEL_DETAIL, channel],
    queryFn: () => getChannelDetail(channel || ''),
    enabled: !!isAuthenticated,
    retry: 0,
  });
  const { data: clientData, isLoading } = useQuery({
    queryKey: [queryKeys.CLIENT_CHANNEL_DETAIL, channel],
    queryFn: () => getClientChannelDetail(channel || ''),
    enabled: !isAuthenticated,
    retry: 0,
  });
  const {
    data: females,
    isFetching,
    refetch: refetchFemales,
  } = useQuery({
    queryKey: [queryKeys.PROJECT_FEMALES, data?.projectId],
    queryFn: () => getProjectFemales(data?.projectId || 0),
    enabled: !!data?.projectId,
  });

  const channelData = useMemo(
    () => (isAuthenticated ? data : clientData),
    [isAuthenticated, data, clientData]
  );

  const {
    data: leverages,
    refetch: refetchLeverage,
    isFetching: isFetchingLeverage,
  } = useQuery({
    queryKey: [queryKeys.PROJECT_LEVERAGE, channel],
    queryFn: () =>
      getPendingProjectLeverage(
        channelData?.participant.find(
          (item) => item.provider === EUserProvider.FEMALE_APPLICATION
        )?.id || 0
      ),
    enabled: !!channel && channelData?.type === EChannelType.PRIVATE,
  });
  const { data: total } = useQuery({
    queryKey: [queryKeys.TOTAL_LEVERAGE_APPROVED, channel],
    queryFn: () =>
      getTotalProjectLeverageApproved(
        channelData?.participant.find(
          (item) => item.provider === EUserProvider.FEMALE_APPLICATION
        )?.id || 0
      ),
    enabled:
      !!channel &&
      channelData?.type === EChannelType.PRIVATE &&
      !isAuthenticated,
  });

  // Mutation
  const { mutateAsync } = useMutation({
    mutationFn: (body: TCreateChannel) => {
      return createChannel(body);
    },
  });

  const [userLeverageId, pendingLeverageId] = useMemo(() => {
    const res = [];
    for (const item of leverages || []) {
      if (
        user?.id
          ? item.creatorId === user.id &&
            item.provider === EUserProvider.MALE_APPLICATION
          : item.clientId === clientId && !item.creatorId
      ) {
        res[0] = item.id;
      } else res[1] = item.id;
    }
    return res;
  }, [leverages, clientId, user]);

  const isCreateNew = useMemo(() => {
    return (
      !channelData &&
      (error as AxiosError)?.status === ERROR_CODE.NOT_FOUND &&
      createId &&
      !isNaN(+createId)
    );
  }, [channelData, createId, error]);

  const channelParticipants = useMemo(
    () =>
      channelData?.participant?.filter(
        (member) => ![user?.uuid, clientId].includes(member.uuid)
      ),
    [channelData?.participant, user?.uuid, clientId]
  );

  const onCreateChannel = useCallback(async () => {
    if (channel && createId) {
      const res = await mutateAsync({ id: channel, femaleId: +createId });
      refetch();
      return res;
    }
  }, [channel, createId, mutateAsync, refetch]);

  const onFocusInput = useCallback(async () => {
    if (channelData?.projectId || pendingLeverageId) {
      setShowAction(false);
    }
  }, [channelData?.projectId, pendingLeverageId, setShowAction]);

  const onBlurInput = useCallback(async () => {
    if (channelData?.projectId || pendingLeverageId) {
      setShowAction(true);
    }
  }, [channelData?.projectId, pendingLeverageId, setShowAction]);

  const handleRefetch = useCallback(() => {
    refetch();
    refetchFemales();
  }, [refetch, refetchFemales]);

  useEffect(() => {
    if (createId && channelData) {
      setSearchParams();
    }
    return () => {};
  }, [createId, channelData, searchParams, setSearchParams]);

  useEffect(() => {
    if (!!total?.total) {
      localStorage.setItem(storageKeys.REQUIRE_SIGNUP, `1`);
    }
  }, [total]);

  return (
    <>
      <ConversationContext.Provider
        value={{
          userLeverageId: userLeverageId,
          pendingLeverageId: pendingLeverageId,
          refetchLeverage: refetchLeverage,
        }}
      >
        <PageWrapper
          key={channel}
          className="flex flex-col justify-between items-center w-full h-full text-sm"
        >
          {loadingChannelDetail || isLoading ? (
            <div className="h-full flex items-center">
              <Loading />
            </div>
          ) : (
            <>
              {(!!channelData || isCreateNew) && !isError ? (
                <>
                  <div className="flex-1 overflow-hidden w-full h-full translate-x-0 translate-y-0">
                    <Chat
                      channel={channel || ''}
                      channelData={channelData}
                      users={channelParticipants}
                      onCreateChannel={
                        isCreateNew ? onCreateChannel : undefined
                      }
                      onBlur={onBlurInput}
                      onFocus={onFocusInput}
                      refetch={handleRefetch}
                      refetchChannelDetail={refetch}
                      action={(() => {
                        if (
                          !showAction ||
                          (!channelData?.projectId && !pendingLeverageId)
                        ) {
                          return;
                        }

                        return channelData?.projectId ? (
                          <ConversationAction
                            channelDetail={channelData}
                            refetch={handleRefetch}
                            females={females}
                            isLoading={isFetching}
                          />
                        ) : (
                          <LeverageActions loading={isFetchingLeverage} />
                        );
                      })()}
                      readonly={
                        channelData?.type &&
                        readonlyConversation.includes(channelData?.type)
                      }
                    />
                  </div>
                </>
              ) : (
                <div className="h-full w-full flex flex-col justify-between items-center">
                  <Header
                    title={<></>}
                    onBack={() => navigate(routes.MESSAGE)}
                  />
                  <Text center bold fontSize={16}>
                    {TEXT_STRING.COMMON.MESSAGE_REMOVED_CHANNEL}
                  </Text>
                  <div></div>
                </div>
              )}
            </>
          )}
        </PageWrapper>
      </ConversationContext.Provider>
    </>
  );
}

export default Conversation;

import {
  Avatar,
  Button,
  FileView,
  IconButton,
  Modal,
  PageWrapper,
  PlacesInput,
  Text,
  TextField,
} from 'components';
import {
  LIMIT_FILE,
  IMAGE_ACCEPTED,
  routes,
  TEXT_STRING,
  VIDEO_ACCEPTED,
} from '../../../constants';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from 'store';
import { TCreatePost, TPlaceResult, TTextFieldChangeEvent } from 'types';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useUploadFile } from 'hooks';
import { useMutation } from '@tanstack/react-query';
import { createPost } from 'api';
import { toast } from 'react-toastify';
import { getFileSize } from 'utils';

const STRINGS = TEXT_STRING.POST;

function CreatePost() {
  // Hooks
  const fileInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { user } = useUserStore();
  const { uploadToS3 } = useUploadFile();

  // Mutations
  const { mutateAsync } = useMutation({
    mutationFn: (body: TCreatePost) => {
      return createPost(body);
    },
  });

  // State
  const [content, setContent] = React.useState<string>('');
  const [files, setFiles] = React.useState<File[]>([]);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [showLocation, setShowLocation] = React.useState<boolean>(false);
  const [showEmojiPicker, setShowEmojiPicker] = React.useState<boolean>(false);
  const [isPosting, setPosting] = React.useState<boolean>(false);
  const [location, setLocation] = React.useState<TPlaceResult | undefined>();

  const onCancel = React.useCallback(async () => {
    navigate(-1);
  }, [navigate]);

  const onPost = React.useCallback(async () => {
    try {
      if (!content.trim() && !files.length) return;
      setPosting(true);
      const sizeFiles = await Promise.all(
        files.map((file) => getFileSize(file))
      );
      const collections = await uploadToS3({ files: files });
      await mutateAsync({
        content: content.trim(),
        location: location?.formatted_address,
        postMedia: collections.map((item, index) => ({
          url: item.uploadedUrl,
          type: files[index]?.type,
          ...sizeFiles[index],
        })),
      });
      toast.success(STRINGS.SUCCESS);
      navigate(routes.HOME);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    } finally {
      setPosting(false);
    }
  }, [
    content,
    files,
    location?.formatted_address,
    mutateAsync,
    uploadToS3,
    navigate,
  ]);

  const onChangeFiles = () => {
    const files = fileInputRef.current?.files;
    if (files && files.length) {
      if (files.length > LIMIT_FILE) {
        setErrorMessage(
          TEXT_STRING.ERROR.LIMIT_FILE.replace('$number', `${LIMIT_FILE}`)
        );
      } else setErrorMessage(``);
      setFiles(Array.from(files).slice(0, 4));
    }
  };

  const onChangeContent = React.useCallback(
    (event: TTextFieldChangeEvent) => {
      setContent(event.target.value);
    },
    [setContent]
  );

  const onEmojiSelect = React.useCallback(
    (emoji: any) => {
      setContent((prev) => prev + emoji.native);
    },
    [setContent]
  );

  const FilesView = React.useMemo(
    () => (
      <>
        {files?.map((file, index) => {
          return (
            <FileView
              key={file.name}
              file={file}
              className="h-full"
              onRemove={() => {
                setFiles((prev) => prev.filter((_, idx) => idx !== index));
                if (fileInputRef.current) {
                  fileInputRef.current.value = '';
                }
              }}
            />
          );
        })}
      </>
    ),
    [files]
  );

  const actions = React.useMemo(
    () => [
      {
        icon: 'image',
        action: () => {
          fileInputRef?.current?.click();
        },
      },
      // {
      //   icon: 'location',
      //   action: () => {
      //     setShowLocation(true);
      //   },
      // },
      // {
      //   icon: 'sticker',
      //   action: (e: React.MouseEvent<HTMLElement>) => {
      //     e.stopPropagation();
      //     setShowEmojiPicker(true);
      //   },
      // },
    ],
    [fileInputRef]
  );

  return (
    <PageWrapper className="overflow-y-auto py-6 px-4">
      <div className={`flex justify-between`}>
        <Button
          className={`px-8`}
          onClick={onCancel}
          bgColor="bg-transparent"
          textColor="text-neutral-600"
          disabled={isPosting}
        >
          {TEXT_STRING.COMMON.CANCEL}
        </Button>
        <Button
          className={`px-12`}
          onClick={onPost}
          disabled={isPosting || (!content.trim() && !files.length)}
          loading={isPosting}
        >
          {STRINGS.POST}
        </Button>
      </div>
      <div className={`flex mt-8 items-start gap-4`}>
        <Avatar url={user?.avatar || ''} size="w-20" />
        {/* <div>
          <Select
            name="type"
            onChange={() => {}}
            options={[{ name: '公開', value: 'public' }]}
            value={'public'}
            className={`text-sky-500 w-auto bg-white pt-1.5 pb-1.5 rounded-md`}
            disabled
          />
          {location && (
            <div className="flex items-center mt-1">
              <div className="scale-50">
                <Icon icon="location" />
              </div>
              <Text textColor="text-neutral-300">
                {location?.formatted_address}
              </Text>
            </div>
          )}
        </div> */}
        <TextField
          name="content"
          onChange={onChangeContent}
          value={content}
          type="textarea"
          rows={5}
          placeholder={STRINGS.CONTENT_PLACEHOLDER}
          className="ring-0 focus-within:ring-0"
          noBorder
        />
      </div>
      {!!files?.length && (
        <div className="flex gap-4 overflow-x-auto py-4 h-44">{FilesView}</div>
      )}
      <div className="flex relative gap-4 mt-4 pt-4 px-2 border-t-[1px]">
        <input
          type="file"
          ref={fileInputRef}
          id="hiddenFileInput"
          className="hidden"
          onChange={onChangeFiles}
          accept={[...VIDEO_ACCEPTED, ...IMAGE_ACCEPTED].join(', ')}
          multiple
          maxLength={4}
        />
        {showEmojiPicker && (
          <div className="absolute top-16">
            <Picker
              data={data}
              onEmojiSelect={onEmojiSelect}
              theme="light"
              onClickOutside={() => {
                setShowEmojiPicker(false);
              }}
            />
          </div>
        )}
        <Modal
          open={showLocation}
          onClose={() => {
            setShowLocation(false);
          }}
          className="shadow-none h-full"
          position="top"
        >
          <PlacesInput
            onPlaceSelected={(place) => {
              setLocation(place);
              setShowLocation(false);
            }}
            defaultValue={location?.formatted_address}
            className="p-4 bg-neutral-600"
            onRemove={() => {
              setLocation(undefined);
            }}
          />
        </Modal>
        {actions?.map(({ icon, action }, index) => (
          <IconButton key={index} icon={icon} onClick={action} transparent />
        ))}
      </div>
      <Text textColor="text-error" className="mt-4">
        {errorMessage}
      </Text>
    </PageWrapper>
  );
}

export default CreatePost;

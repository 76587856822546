import Loading from 'components/Loading';
import Text from 'components/Text';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMessageStore, useUserStore } from 'store';
import MessageItem from '../MessageItem';
import { TEXT_STRING } from '../../../constants';
import moment from 'moment';
import {
  EChannelType,
  EUserProvider,
  TChannelDetail,
  TMember,
  TMessageDetail,
} from 'types';
import { useCallback } from 'react';

type Props = {
  isLoading?: boolean;
  messages: TMessageDetail[];
  fetchMore: () => void;
  hasMore: boolean;
  members: { [x: string]: TMember };
  channelData?: TChannelDetail;
  onClickAvatar: (data: TMember) => void;
};

const MessageList = ({
  isLoading,
  messages,
  fetchMore,
  hasMore,
  members,
  channelData,
  onClickAvatar,
}: Props) => {
  const { user, clientId } = useUserStore();
  const { adminList } = useMessageStore();

  const handleMessageTime = useCallback(
    (msgTimeToken: string, beforeMsgToken?: string) => {
      const msgTime = +msgTimeToken / 10000;
      const beforeMsgTime = +(beforeMsgToken || 0) / 10000;
      let displayTime;
      const isOneHourDiff = moment(msgTime).diff(beforeMsgTime, 'hours') > 1;
      if (
        !moment(msgTime).isSame(beforeMsgTime, 'day') ||
        isNaN(beforeMsgTime)
      ) {
        displayTime = moment(msgTime).format('ll (dd) HH:mm');
      } else if (isOneHourDiff) {
        displayTime = moment(msgTime).format('HH:mm');
      }
      return { displayTime, isOneHourDiff };
    },
    []
  );

  const handleUserInfo = useCallback(
    (isOneHourDiff: boolean, publisher: string, beforePublisher?: string) => {
      const isMyMessage = [user?.uuid, clientId].includes(publisher);
      const isAdminMessage = adminList.some((uuid) => uuid === publisher);
      const isAdminBeforeMessage = adminList.some(
        (uuid) => uuid === beforePublisher
      );

      const showAvatar =
        !isMyMessage &&
        ((publisher !== beforePublisher &&
          !(isAdminMessage && isAdminBeforeMessage)) ||
          isOneHourDiff);

      return {
        isMyMessage,
        showAvatar,
        isAdminMessage,
      };
    },
    [adminList, clientId, user?.uuid]
  );

  return (
    <div
      id="scrollableMessage"
      className="h-full gap-4 overflow-y-auto p-4 flex flex-col-reverse"
    >
      {isLoading ? (
        <div className="m-auto">
          <Loading />
        </div>
      ) : !messages.length ? (
        <Text className="m-auto tracking-widest" center bold>
          {channelData?.type === EChannelType.MALE_MANAGEMENT
            ? TEXT_STRING.MESSAGE.QUESTIONS_TO_MANAGEMENT
            : channelData?.provider === EUserProvider.MALE_APPLICATION
            ? TEXT_STRING.MESSAGE.START_MESSAGE
            : TEXT_STRING.COMMON.SOMEONE_WANT_CONTACT}
        </Text>
      ) : (
        <>
          <InfiniteScroll
            next={fetchMore}
            inverse={true}
            hasMore={hasMore}
            loader={
              <div className="py-[36px] flex justify-center items-center">
                <Loading />
              </div>
            }
            dataLength={messages.length}
            scrollThreshold={0.5}
            className="gap-4 flex flex-col-reverse overflow-hidden"
            scrollableTarget="scrollableMessage"
          >
            {messages.map((item, index, arr) => {
              const { displayTime, isOneHourDiff } = handleMessageTime(
                item.timetoken,
                arr[index + 1]?.timetoken
              );
              const { isMyMessage, showAvatar, isAdminMessage } =
                handleUserInfo(isOneHourDiff, item.uuid, arr[index + 1]?.uuid);
              return (
                <div key={item.timetoken} className="overflow-hidden">
                  {displayTime && (
                    <Text center className="mb-4">
                      {displayTime}
                    </Text>
                  )}
                  <MessageItem
                    message={item}
                    isMyMessage={isMyMessage}
                    isAdminMessage={isAdminMessage}
                    showAvatar={showAvatar}
                    user={members[item.uuid]}
                    onClickAvatar={() => onClickAvatar(members[item.uuid])}
                  />
                </div>
              );
            })}
          </InfiniteScroll>
        </>
      )}
    </div>
  );
};

export default MessageList;

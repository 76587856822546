import Icon from 'components/Icon';

type Props = {
  label: string;
  isActive: boolean;
  isFinished: boolean;
  isShowLine: boolean;
};

function Step({ isActive, isFinished, label, isShowLine }: Props) {
  return (
    <div className="flex items-center">
      <div className="relative">
        <p
          className={`${
            isActive || isFinished ? 'text-primary-500' : 'text-neutral-700'
          } text-[14px] font-medium leading-[21px] absolute bottom-[125%] whitespace-nowrap left-1/2 translate-x-[-50%]`}
        >
          {label}
        </p>
        {isActive ? (
          <div className="w-[28px] h-[28px] border-[10px] border-primary-500 outline outline-[4px] outline-primary-50 rounded-[50%]" />
        ) : isFinished ? (
          <Icon icon="roundedCheck" />
        ) : (
          <div className="w-[24px] h-[24px] border-[8px] border-neutral-300 rounded-[50%]" />
        )}
      </div>

      {isShowLine ? (
        <div
          className={`flex-1 h-[3px] ${
            isFinished ? 'bg-primary-500' : 'bg-neutral-300'
          }`}
        />
      ) : null}
    </div>
  );
}

export default Step;

import Icon from 'components/Icon';
import Text from 'components/Text';
import { TEXT_STRING } from '../../../constants';
import {
  EPaymentType,
  EPointRequestStatus,
  TPointHistoryResponse,
} from 'types';
import { formatAmountNumber, formatDate } from 'utils';

const str = TEXT_STRING.POINTS;

type IStatusProperty = {
  text: string;
  textColor: `text-${string}`;
};

type IStatus = {
  [EPointRequestStatus.PENDING]: IStatusProperty;
  [EPointRequestStatus.SUCCESS]: IStatusProperty;
};

const status: IStatus = {
  [EPointRequestStatus.PENDING]: {
    text: str.UNCHARGED,
    textColor: 'text-warning',
  },
  [EPointRequestStatus.SUCCESS]: {
    text: str.CHARGED,
    textColor: 'text-success',
  },
};

type Props = {
  onClick: () => void;
  data: TPointHistoryResponse;
  className?: string;
};

function PurchaseHistoryItem({ data, onClick, className }: Props) {
  return (
    <div
      className={`${className} bg-neutral-25 flex items-center gap-4 p-5 rounded-lg border border-neutral-100`}
      onClick={onClick}
    >
      <div className="w-[26px] h-[26px]">
        <Icon icon={'teco'} />
      </div>

      <div className="flex-1 flex flex-col gap-[2px] text-base">
        <div className="flex justify-between items-center">
          <Text fontWeight="font-medium" textColor="text-neutral-900">
            {formatAmountNumber(
              data.pointAmount,
              TEXT_STRING.COMMON.POINT_UNIT
            )}
          </Text>

          {data.paymentType === EPaymentType.BANK ? (
            <Text
              className="tracking-[0.3px]"
              fontWeight="font-medium"
              textColor={status[data.status].textColor}
            >
              {status[data.status].text}
            </Text>
          ) : null}
        </div>

        <div className="flex justify-between items-center text-base">
          <Text textColor="text-neutral-550">
            {formatAmountNumber(data.priceAmount)}
          </Text>

          <Text className="tracking-[0.3px]" textColor="text-neutral-550">
            {formatDate(data.createdDateTime)}
          </Text>
        </div>
      </div>
    </div>
  );
}

export default PurchaseHistoryItem;

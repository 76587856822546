import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../constants';
import { usePointStore, useUserStore } from 'store';
import { TGift } from 'types';

function useUser() {
  const { points } = usePointStore();
  const { user } = useUserStore();
  const queryClient = useQueryClient();

  const refetchPoints = () => {
    queryClient.refetchQueries({ queryKey: [queryKeys.POINTS] });
  };

  const lockedGift = (gift?: TGift) => {
    return (
      (gift?.starRequired || 0) > (user?.total.ranking || 0) &&
      (gift?.price || 0) > (points?.point || 0)
    );
  };

  return { lockedGift, refetchPoints };
}

export default useUser;

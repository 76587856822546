import axios from 'axios';
import {
  EPaymentMethod,
  TBodyGiftPredict,
  TBodyGiftRequest,
  TBodyPaymentGift,
  TGift,
  TResGiftPendingPayment,
  TResGiftPredict,
  TResPaymentGift,
} from 'types';

const paymentGiftRequest = async (body: TBodyGiftRequest) => {
  return await axios.post('gifts/payment/request', body).then((response) => {
    return response.data;
  });
};

const paymentGift = async (
  body: TBodyPaymentGift
): Promise<TResPaymentGift> => {
  return await axios.post('gifts/payment', body).then((response) => {
    return response.data;
  });
};

const getGiftPendingPayment = async (
  historyId: number
): Promise<TResGiftPendingPayment> => {
  return await axios
    .get('gifts/pending', { params: { historyId } })
    .then((response) => {
      return response.data;
    });
};

const getGiftPredict = async (
  payload: TBodyGiftPredict,
  type: EPaymentMethod
): Promise<TResGiftPredict> => {
  return await axios
    .get(`gifts/predict`, { params: { giftId: payload.giftId, type } })
    .then((response) => {
      return response.data;
    });
};

const getGiftList = async (): Promise<TGift[]> => {
  return await axios.get('gifts').then((response) => {
    return response.data;
  });
};

export {
  paymentGiftRequest,
  paymentGift,
  getGiftPendingPayment,
  getGiftPredict,
  getGiftList,
};

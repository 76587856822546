import { TEXT_STRING } from '../../constants';
import { ECardMethod } from 'types';
import Button from 'components/Button';
import { useMemo } from 'react';
import { Method } from 'components';

type Props = {
  onChooseMethod: (cardMethod: ECardMethod) => void;
  loading?: boolean;
  cardMethod: ECardMethod;
  onSubmit: () => void;
  isMaintenance?: boolean;
};

const CardMethod = ({
  onChooseMethod,
  loading,
  cardMethod,
  onSubmit,
  isMaintenance = false,
}: Props) => {
  const methodOptions = useMemo(
    () => [
      {
        label: TEXT_STRING.PAYMENT.VISA,
        icon: 'creditCard',
        method: ECardMethod.VISA,
      },
      {
        label: TEXT_STRING.COMMON.OTHER_WITHOUT_VISA,
        icon: 'creditCard',
        method: ECardMethod.OTHERS,
      },
    ],
    []
  );

  return (
    <>
      <div className="grid grid-cols-2 gap-4 my-5">
        {methodOptions.map((item) => (
          <Method
            {...item}
            key={item.method}
            isActive={cardMethod === item.method}
            onClick={() => onChooseMethod(item.method)}
          />
        ))}
      </div>
      <Button
        block
        onClick={onSubmit}
        loading={loading}
        disabled={loading || !cardMethod}
      >
        {TEXT_STRING.COMMON.CONTINUE}
      </Button>
    </>
  );
};

export default CardMethod;

import { Icon, Tabs } from 'components';
import { queryKeys, TEXT_STRING, SESSION_KEYS, routes } from '../../constants';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getFollowedPostList, getRecommendPostList } from 'api';
import { EPost } from 'types';
import PostView from './PostView';
import { usePostStore, useUserStore } from 'store';
import { useNavigate } from 'react-router-dom';
import { CellMeasurerCache, ScrollParams } from 'react-virtualized';
import { PostContext } from 'contexts';
import { useSize } from 'hooks';

// Upload new images to s3 to change the banner
const [BANNER_URL, RESOLUTION] =
  process.env.REACT_APP_TWEET_BANNER_URL?.split('?resolution=') || [];
const POSTER_URL = process.env.REACT_APP_POSTER_TWEET_BANNER_URL;
const BANNER_REDIRECT_URL = process.env.REACT_APP_TWEET_BANNER_REDIRECT_URL;

const tabPost = [
  {
    title: TEXT_STRING.HOME_PAGE.RECOMMEND,
    id: EPost.RECOMMEND,
  },
  {
    title: TEXT_STRING.CAST_PAGE.FOLLOWING,
    id: EPost.FOLLOWED,
  },
];

const cachePostRecommend = new CellMeasurerCache({
  defaultHeight: 500,
  fixedWidth: true,
});

const cachePostFollowed = new CellMeasurerCache({
  defaultHeight: 500,
  fixedWidth: true,
});

function Home() {
  const homeTab = sessionStorage.getItem(SESSION_KEYS.HOME_TAB) as EPost;

  // State
  const [tab, setTab] = useState<EPost>(homeTab || EPost.RECOMMEND);
  const [showTab, setShowTab] = useState<boolean>(false);
  const [galleryWidth, setGalleryWidth] = useState<number | undefined>(
    undefined
  );

  // Hook
  const {
    bannerHeight,
    postRecommendScrollY,
    postFollowedScrollY,
    setPostRecommendScrollY,
    setPostFollowedScrollY,
    setBannerHeight,
  } = usePostStore();
  const navigate = useNavigate();
  const { isAuthenticated } = useUserStore();
  const {
    isLoading: bannerLoading,
    getSize,
    BannerView,
  } = useSize({
    defaultHeight: bannerHeight,
    url: BANNER_URL,
    resolution: RESOLUTION,
    poster: POSTER_URL,
    className: 'p-4',
    redirectUrl: BANNER_REDIRECT_URL,
  });

  // Ref
  const contentRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const recommendScrollY = useRef<number>();
  const followedScrollY = useRef<number>();

  // Callbacks, memo
  const handleScrollRecommendPost = useCallback((param?: ScrollParams) => {
    setShowTab(
      (param?.scrollTop || 0) > (contentRef?.current?.offsetHeight || 0)
    );
    recommendScrollY.current = param?.scrollTop;
  }, []);

  const handleScrollFollowedPost = useCallback((param?: ScrollParams) => {
    setShowTab(
      (param?.scrollTop || 0) > (contentRef?.current?.offsetHeight || 0)
    );
    followedScrollY.current = param?.scrollTop;
  }, []);

  const handleSaveScrollRecommend = useCallback(() => {
    setPostRecommendScrollY(recommendScrollY.current);
  }, [setPostRecommendScrollY]);

  const handleSaveScrollFollowed = useCallback(() => {
    setPostFollowedScrollY(followedScrollY.current);
  }, [setPostFollowedScrollY]);

  const handleChangeTab = useCallback(
    (tab: string) => {
      if (tab === EPost.FOLLOWED) {
        handleSaveScrollFollowed();
      } else {
        handleSaveScrollRecommend();
      }
      setShowTab(false);
      sessionStorage.setItem(SESSION_KEYS.HOME_TAB, tab);
      setTab(tab as EPost);
    },
    [handleSaveScrollFollowed, handleSaveScrollRecommend]
  );

  const onCreatePost = useCallback(() => {
    navigate(routes.CREATE_POST);
  }, [navigate]);

  const Banner = useCallback(() => {
    return (
      <div ref={contentRef}>
        {BannerView}
        <div className="sticky top-0 bg-white flex flex-col gap-16px w-full px-3 pt-5">
          <Tabs currentTab={tab} tabs={tabPost} onChangeTab={handleChangeTab} />
          <hr className="solid" />
        </div>
      </div>
    );
  }, [BannerView, handleChangeTab, tab]);

  useEffect(() => {
    if (ref.current?.offsetWidth && galleryWidth !== ref.current?.offsetWidth) {
      setGalleryWidth(ref.current.offsetWidth);
    }
  }, [galleryWidth, setGalleryWidth]);

  useEffect(() => {
    if (ref.current?.offsetWidth && !bannerHeight) {
      getSize((ref.current.offsetWidth * 8) / 7 + 24 + 9).then((size) => {
        setBannerHeight(size);
      });
    }
  }, [getSize, bannerHeight, setBannerHeight]);

  return (
    <PostContext.Provider value={{ galleryWidth, setGalleryWidth }}>
      <div className="h-full overflow-y-auto flex flex-col w-full">
        <div
          className={`top-0 z-[2] bg-white flex flex-col gap-16px pt-5 w-full px-3 fixed ${
            showTab ? 'visible' : 'invisible'
          }`}
        >
          <Tabs currentTab={tab} tabs={tabPost} onChangeTab={handleChangeTab} />
          <hr className="solid" />
        </div>
        <div className={`flex gap-3 bg-primary h-0 px-4`}>
          <div className={`flex-[1]`}></div>
          <div className={`flex-[7]`} ref={ref}></div>
        </div>
        {tab === EPost.RECOMMEND && (
          <PostView
            loading={bannerLoading}
            setPostScrollY={setPostRecommendScrollY}
            postScrollY={postRecommendScrollY}
            onScrollY={handleScrollRecommendPost}
            onClickAvatar={handleSaveScrollRecommend}
            Banner={Banner}
            queryKey={[queryKeys.POST_RECOMMEND]}
            getPostList={getRecommendPostList}
            enabled={tab === EPost.RECOMMEND}
            cachePost={cachePostRecommend}
          />
        )}
        {tab === EPost.FOLLOWED && (
          <PostView
            loading={bannerLoading}
            setPostScrollY={setPostFollowedScrollY}
            postScrollY={postFollowedScrollY}
            onScrollY={handleScrollFollowedPost}
            onClickAvatar={handleSaveScrollFollowed}
            Banner={Banner}
            queryKey={[queryKeys.POST_FOLLOWED]}
            getPostList={getFollowedPostList}
            enabled={tab === EPost.FOLLOWED && isAuthenticated}
            cachePost={cachePostFollowed}
          />
        )}
        <button
          className="text-white shadow-xl flex items-center justify-center p-4 rounded-full bg-gradient-to-r from-cyan-500 to-blue-500 z-10 fixed right-9 bottom-36 scale-125"
          onClick={onCreatePost}
        >
          <Icon icon="plus" />
        </button>
      </div>
    </PostContext.Provider>
  );
}

export default Home;

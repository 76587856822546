import { Header, IconButton, Text } from 'components';
import { routes, TEXT_STRING } from '../../../constants';
import { useUserStore } from 'store';
import { useToast } from 'hooks';
import { useCallback, useMemo } from 'react';

const INTRODUCING_STR = TEXT_STRING.INTRODUCING;

const content = [
  INTRODUCING_STR.step1,
  INTRODUCING_STR.step2,
  INTRODUCING_STR.step3,
];

function IntroducingFriend() {
  const { user } = useUserStore();
  const { toastSuccess } = useToast();

  const invitationLink = useMemo(
    () => `${window.location.origin}${routes.SIGNUP}?invite=${user?.uuid}`,
    [user]
  );

  const handleCopyInvitationLink = useCallback(() => {
    navigator.clipboard.writeText(invitationLink);
    toastSuccess(TEXT_STRING.COMMON.COPY_SUCCESS);
  }, [invitationLink, toastSuccess]);

  return (
    <div className="h-full overflow-y-auto flex flex-col">
      <Header title={INTRODUCING_STR.introducingAFriend} />
      <div className="p-5 flex-1 flex flex-col justify-between">
        <div className="flex flex-col gap-3 text-sm">
          {content.map((item, index) => {
            return (
              <div
                className={`flex gap-4 items-center p-5 border border-solid border-neutral-300 rounded-2xl`}
                key={index}
              >
                <div
                  className={`inline-flex items-center justify-center h-10 w-10 text-sm aspect-square text-white rounded-full bg-primary`}
                >
                  {index + 1}
                </div>
                <Text>{item}</Text>
              </div>
            );
          })}
          <Text textColor="text-neutral">{INTRODUCING_STR.tecoAssetsNote}</Text>
        </div>
        <div>
          <Text center bold className="text-base">
            {INTRODUCING_STR.invitationUrl}
          </Text>
          <div className="flex gap-4 items-center mt-4 p-5 border border-solid border-neutral-400 bg-neutral-50 rounded-xl justify-between">
            <Text
              className="whitespace-nowrap overflow-hidden text-ellipsis"
              nowrap
            >
              {invitationLink}
            </Text>
            <IconButton
              icon="copy"
              onClick={handleCopyInvitationLink}
              transparent
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntroducingFriend;

import React, { useContext, useMemo } from 'react';
import {
  EChannelType,
  EGiftPaymentStep,
  TChannelDetail,
  TSendMessage,
} from 'types';
import { GiftItem, GiftPaymentStep, ModalGift } from 'components/Gift';
import { TGift } from 'types/public';
import { ChatContext } from 'components/Chat/context';
import { useUser } from 'hooks';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../constants';
import { getGiftList } from 'api';
import { useUserStore } from 'store';

type Props = {
  sendMessage: (payload: TSendMessage) => Promise<void>;
  femaleId?: number;
  showGift?: boolean;
  channelData?: TChannelDetail;
};

const GiftBag = ({
  sendMessage,
  femaleId,
  showGift = false,
  channelData,
}: Props) => {
  // Hooks
  const { stepPaymentGift, setStepPaymentGift } = useContext(ChatContext);
  const { lockedGift, refetchPoints } = useUser();
  const { isAuthenticated } = useUserStore();

  // States
  const [openGift, setOpenGift] = React.useState<boolean>(false);
  const [selectedGift, setSelectedGift] = React.useState<TGift>();

  // Query
  const { data } = useQuery({
    queryKey: [queryKeys.GIFT_LIST],
    queryFn: getGiftList,
    enabled: channelData?.type === EChannelType.PRIVATE && isAuthenticated,
  });

  const listGift = useMemo(
    () => data?.map((gift) => ({ ...gift, isLocked: lockedGift(gift) })),
    [data, lockedGift]
  );

  return (
    <>
      {showGift && (
        <div className="w-full h-2/3 grid grid-cols-4 p-5 gap-5 overflow-y-auto">
          {listGift?.map((gift) => (
            <GiftItem
              key={gift.id}
              gift={gift}
              onClick={() => {
                setSelectedGift(gift);
                setOpenGift(true);
              }}
              isLocked={gift?.isLocked}
            />
          ))}
        </div>
      )}
      <GiftPaymentStep
        onNextStep={() => setStepPaymentGift(+(stepPaymentGift || 0) + 1)}
        onPreviousStep={() => setStepPaymentGift(+(stepPaymentGift || 0) - 1)}
        giftId={selectedGift?.id || 0}
        onClose={() => setStepPaymentGift(undefined)}
        step={stepPaymentGift}
        femaleId={femaleId}
        onSendMessage={(gift) => {
          sendMessage({ gift, message: '' });
          refetchPoints();
        }}
      />

      <ModalGift
        open={openGift}
        onClose={() => setOpenGift(false)}
        onConfirm={() => {
          setOpenGift(false);
          setStepPaymentGift(EGiftPaymentStep.CHOOSE_METHOD);
        }}
        gift={selectedGift}
        isLocked={selectedGift?.isLocked}
      />
    </>
  );
};

export default GiftBag;

import { useInfiniteQuery } from '@tanstack/react-query';
import { getPointHistory } from 'api';
import { formatDate } from 'utils';
import { useMemo } from 'react';
import {
  Header,
  Loading,
  Text,
  UsageHistoryItem,
  VirtualizedList,
} from 'components';
import { PAGE_SIZE, queryKeys, TEXT_STRING } from '../../../constants';
import { CellMeasurerCache } from 'react-virtualized';

const str = TEXT_STRING.POINTS;

function PointUsageHistory() {
  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery({
    queryKey: [queryKeys.POINT_USAGE_HISTORY],
    queryFn: ({ pageParam }) => getPointHistory(pageParam, PAGE_SIZE, true),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, currentPage) => {
      return lastPage?.totalPage > currentPage ? currentPage + 1 : undefined;
    },
  });
  const pointUsageHistoryData = useMemo(
    () => data?.pages.map((item) => item.data).flat() ?? [],
    [data?.pages]
  );

  const rowCount = useMemo(
    () => pointUsageHistoryData.length + +hasNextPage,
    [hasNextPage, pointUsageHistoryData.length]
  );

  return (
    <>
      <Header title={str.USAGE_HISTORY} />
      {isLoading ? (
        <div className="flex justify-center py-9">
          <Loading />
        </div>
      ) : !pointUsageHistoryData.length ? (
        <Text bold center className="py-16">
          {TEXT_STRING.COMMON.NO_DATA}
        </Text>
      ) : (
        <div className="pt-8 pb-20 px-5 bg-neutral-25 h-full">
          <VirtualizedList
            // TODO reset cache when rowCount changes
            cache={
              new CellMeasurerCache({
                defaultHeight: 50,
                fixedWidth: true,
              })
            }
            fetchMore={fetchNextPage}
            hasMore={hasNextPage}
            dataLength={pointUsageHistoryData.length}
            rowCount={rowCount}
            overscanRowCount={10}
            RowItem={({ index, measure, style, registerChild }) => {
              const item = pointUsageHistoryData[index];
              return (
                <div ref={registerChild} style={style}>
                  {item ? (
                    <div
                      onLoad={measure}
                      className={index === 0 ? 'pb-1' : 'py-1'}
                    >
                      {formatDate(item.updatedDateTime) !==
                        formatDate(
                          pointUsageHistoryData[index - 1]?.updatedDateTime
                        ) && (
                        <Text
                          fontSize={16}
                          fontWeight="font-medium"
                          className={`${index === 0 ? '' : 'mt-3'} mb-5`}
                        >
                          {formatDate(item.updatedDateTime)}
                        </Text>
                      )}
                      <UsageHistoryItem data={item} />
                    </div>
                  ) : null}
                </div>
              );
            }}
          />
        </div>
      )}
    </>
  );
}

export default PointUsageHistory;

import { storageKeys } from '../constants';

const clearStorage = () => {
  Object.values(storageKeys).forEach((key) => {
    if (
      key !== storageKeys.IS_CONFIRM &&
      key !== storageKeys.IS_CONFIRM_TUTORIAL &&
      key !== storageKeys.CLIENT_ID &&
      key !== storageKeys.GIFT_SUGGESTIONS_DISPLAYED
    ) {
      localStorage.removeItem(key);
    }
  });
};

export default clearStorage;

import Text from 'components/Text';
import { CSSProperties, PropsWithChildren, useCallback, useRef } from 'react';
import Draggable, { DraggableEventHandler } from 'react-draggable';
import { detectMobile } from 'utils';

type Props = PropsWithChildren<{
  className?: string;
  style?: CSSProperties;
  onClose: () => void;
  open: boolean;
  title?: JSX.Element | string;
  zIndex?: string;
}>;

const DraggableModal = ({
  onClose,
  open = false,
  children,
  className = '',
  style,
  title,
  zIndex = 'z-40',
}: Props): JSX.Element => {
  const draggableRef = useRef(null);
  const isMobile = detectMobile();

  const handleStop: DraggableEventHandler = useCallback(
    (_e, data) => {
      if (data.y >= 100) {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <div
      className={`${
        open ? `opacity-100 scale-1 ${zIndex}` : 'opacity-0 scale-0'
      }  ${
        isMobile ? 'w-full' : 'w-[50vh]'
      } h-full fixed top-0 left-0 right-0 mx-auto transition-opacity duration-300`}
    >
      <div className="w-full h-full bg-black/80" onClick={onClose}></div>
      <Draggable
        bounds={{ top: 0 }}
        axis="y"
        onStop={handleStop}
        handle="main"
        cancel="div"
        nodeRef={draggableRef}
        position={{ x: 0, y: 0 }}
      >
        <main
          ref={draggableRef}
          className={`${className} bottom-0 bg-white w-full fixed left-0 right-0 rounded-t-[16px] cursor-grab`}
          style={style}
        >
          <span className="w-full absolute left-0 -top-12px flex justify-center">
            <span className="w-[60px] h-4px bg-white rounded-[8px]"></span>
          </span>
          {typeof title === 'string' ? (
            <Text center textColor="text-black" className="py-16px">
              {title}
            </Text>
          ) : (
            title
          )}

          <div
            className={`overflow-y-auto max-h-[75vh] w-full h-full flex flex-col gap-16px pb-16px justify-between px-16px cursor-auto`}
          >
            {children}
          </div>
        </main>
      </Draggable>
    </div>
  );
};

export default DraggableModal;

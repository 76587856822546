import { TPoints } from 'types';
import { create } from 'zustand';

type State = {
  points?: TPoints;
};

type Actions = {
  setPoints: (points: TPoints) => void;
};

const usePointStore = create<State & Actions>((set) => ({
  setPoints: (points: TPoints) =>
    set(() => ({
      points,
    })),
}));

export default usePointStore;

import DraggableModal from 'components/DraggableModal';
import { queryKeys, routes, TEXT_STRING } from '../../../constants';
import {
  EGiftPaymentStep,
  EPaymentMethod,
  TBodyGiftRequest,
  TBodyPaymentGift,
  TPaymentPayload,
  TResPaymentGift,
} from 'types';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getGiftPendingPayment,
  getGiftPredict,
  paymentGift,
  paymentGiftRequest,
} from 'api';
import CardPayment from 'components/CardPayment';
import { useCallback, useContext, useState } from 'react';
import Button from 'components/Button';
import { ChatContext } from 'components/Chat/context';
import Loading from 'components/Loading';
import ProcessingPriceList from 'components/ProcessingPriceList';
import MethodPayment from 'components/PaymentMethod/PaymentMethod';
import { useToast } from 'hooks';
import { usePostStore } from 'store';
import { isAxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
  step?: EGiftPaymentStep;
  onClose: () => void;
  giftId: number;
  femaleId?: number;
  postId?: number;
  onNextStep: () => void;
  onPreviousStep?: () => void;
  onSendMessage: (data: TResPaymentGift) => void;
  onChooseStep?: (step?: EGiftPaymentStep) => void;
  onSuccess?: () => void;
};

const titleModal = {
  [EGiftPaymentStep.GIFTS_PREDICT]: TEXT_STRING.COMMON.TOTAL_STAMPS,
  [EGiftPaymentStep.CHOOSE_METHOD]:
    TEXT_STRING.PAYMENT.CHOOSE_PAYMENT_METHOD_PLEASE,
  [EGiftPaymentStep.CHOOSE_CARD]: TEXT_STRING.COMMON.CREDIT_CARD_REGISTRATION,
};

const GiftPaymentStep = ({
  step,
  onClose,
  onNextStep,
  onPreviousStep,
  giftId,
  femaleId,
  postId,
  onSendMessage,
  onChooseStep,
  onSuccess,
}: Props) => {
  // Hooks
  const { toastError } = useToast();
  const { historyPaymentId, setHistoryPaymentId, setStepPaymentGift } =
    useContext(ChatContext);
  const [paymentMethod, setPaymentMethod] = useState<EPaymentMethod>(
    EPaymentMethod.POINT
  );
  const { setListPostPaid } = usePostStore();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Query
  const { data, isLoading } = useQuery({
    queryKey: [queryKeys.GIFT_PREDICT, giftId, step],
    queryFn: () => getGiftPredict({ giftId }, paymentMethod),
    enabled:
      !!giftId &&
      !historyPaymentId &&
      !!step &&
      [EGiftPaymentStep.CHOOSE_CARD, EGiftPaymentStep.CHOOSE_METHOD].includes(
        step
      ),
  });

  const {
    data: pendingGift,
    isLoading: loadingPendingGift,
    isRefetching,
  } = useQuery({
    queryKey: [queryKeys.GIFT_PENDING_PAYMENT, historyPaymentId],
    queryFn: () => getGiftPendingPayment(historyPaymentId || 0),
    enabled: !!historyPaymentId,
  });

  // Mutations
  const { mutateAsync: payGift, isPending: payingGift } = useMutation({
    mutationFn: (body: TBodyPaymentGift) => {
      return paymentGift(body);
    },
    onSuccess(data) {
      const { post, ...res } = data;
      onSendMessage(res);
      if (post) {
        setListPostPaid(post);
      }
      onSuccess && onSuccess();
    },
    onError(error) {
      if (
        isAxiosError(error) &&
        error?.response?.data.channelId &&
        error?.response?.data.retry === false &&
        !pathname?.includes(error.response?.data.channelId)
      )
        navigate(
          routes.CONVERSATION.replace(':id', error.response?.data.channelId)
        );
    },
  });

  const { mutateAsync: payGiftPending, isPending: paying } = useMutation({
    mutationFn: (body: TBodyGiftRequest) => {
      return paymentGiftRequest(body);
    },
    onSuccess(data) {
      onSendMessage(data);
    },
  });

  // Memo, callbacks
  const handleClose = useCallback(() => {
    if (!paying && !payingGift) {
      setHistoryPaymentId(undefined);
      setPaymentMethod(EPaymentMethod.POINT);
      onClose();
    }
  }, [onClose, paying, payingGift, setHistoryPaymentId]);

  const handlePayment = useCallback(
    async (payload?: TPaymentPayload) => {
      const { token, customerId } = payload || {};
      if (!token && !customerId && paymentMethod !== EPaymentMethod.POINT)
        return;
      if (historyPaymentId) {
        await payGiftPending({
          token,
          customerId,
          type: payload?.type || paymentMethod,
          historyId: historyPaymentId,
          pendingIds: [],
        });
      } else if (femaleId && giftId) {
        await payGift({
          token,
          customerId,
          femaleId,
          giftId,
          postId,
          type: payload?.type || paymentMethod,
        });
      }
    },
    [
      paymentMethod,
      historyPaymentId,
      femaleId,
      giftId,
      payGiftPending,
      payGift,
      postId,
    ]
  );

  const handleSubmitPaymentMethod = useCallback(async () => {
    try {
      let step = undefined;
      if (paymentMethod === EPaymentMethod.POINT) {
        await handlePayment();
      } else {
        step = EGiftPaymentStep.CHOOSE_CARD;
      }
      setStepPaymentGift(step);
      onChooseStep && onChooseStep(step);
    } catch (error) {
      toastError(error as Error);
    }
  }, [
    handlePayment,
    onChooseStep,
    paymentMethod,
    setStepPaymentGift,
    toastError,
  ]);

  return (
    <>
      <DraggableModal
        onClose={handleClose}
        open={!!step}
        title={titleModal[step || EGiftPaymentStep.GIFTS_PREDICT]}
        className="pb-4"
      >
        {step === EGiftPaymentStep.GIFTS_PREDICT && (
          <ProcessingPriceList
            processing={pendingGift?.pending}
            totalAmount={pendingGift?.totalAmount}
            onNextStep={onNextStep}
            disabled={pendingGift?.isSuccess}
            isRefetching={isRefetching}
            isLoading={loadingPendingGift}
          />
        )}
        {step === EGiftPaymentStep.CHOOSE_METHOD && (
          <MethodPayment
            onChooseMethod={setPaymentMethod}
            onSubmit={handleSubmitPaymentMethod}
            price={pendingGift?.totalAmount || data?.totalAmount || 0}
            paymentMethod={paymentMethod}
            loading={payingGift}
            allowPaymentMethod={
              historyPaymentId
                ? pendingGift?.allowPaymentMethod
                : data?.allowPaymentMethod
            }
          />
        )}
        {step === EGiftPaymentStep.CHOOSE_CARD && (
          <>
            {isLoading ? (
              <Loading />
            ) : (
              <CardPayment
                price={pendingGift?.remainingAmount || data?.totalAmount}
                paying={payingGift || paying}
                onPay={handlePayment}
                title=""
                subTitle={TEXT_STRING.CALL_WOMAN.PAYMENT_WARNING}
                onSettled={handleClose}
              />
            )}
          </>
        )}
        {historyPaymentId && (
          <Button
            onClick={() => {
              onPreviousStep && onPreviousStep();
            }}
            bgColor="bg-transparent"
            textColor="text-primary"
            disabled={paying || payingGift}
            padding=""
          >
            {TEXT_STRING.COMMON.RETURN}
          </Button>
        )}
      </DraggableModal>
    </>
  );
};

export default GiftPaymentStep;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  getProjectInformation,
  getRecommendFemale,
  createProject,
} from '../../../api';
import {
  TEXT_STRING,
  storageKeys,
  PAGE_SIZE,
  queryKeys,
  routes,
  LIMIT_VALUE,
} from '../../../constants';
import {
  EFieldFormTravel,
  EOrderTravelStep,
  EProjectType,
  TCreateProject,
  TFemale,
} from '../../../types';
import { useOrderStore, useSettingStore, useUserStore } from '../../../store';
import { Header, PriceList, Text } from '../../../components';
import ModalSuccess from '../../../components/ModalSuccess';
import { detectPWA, getFormattedAmount, getNumberOfNights } from 'utils';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import FormOrderTravel from 'components/OrderScreen/FormOrderTravel';
import { useAuth, useToast } from 'hooks';
import { initialTravelValue } from 'helpers';

const COMMON_STR = TEXT_STRING.COMMON;

const titleModal = {
  [EOrderTravelStep.ORDER_PRICE]: COMMON_STR.MENU,
  [EOrderTravelStep.ORDER_GIRL]: TEXT_STRING.SUMMARY_ORDER.ORDER,
  [EOrderTravelStep.SUMMARY_ORDER]: TEXT_STRING.SUMMARY_ORDER.CONFIRM_ORDER,
};

function Travel() {
  // State
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  // Hooks
  const {
    setSelectedGirlId,
    selectedGirlId,
    listSelectedGirl,
    setListSelectedGirl,
    travelFormState: formState,
    setTravelFormState: setFormState,
    orderTravelStep,
    setOrderTravelStep,
    isPendingOrder,
    setIsPendingOrder,
  } = useOrderStore();
  const navigate = useNavigate();
  const { setOpenTutorial } = useSettingStore();
  const { checkNeedToLogin } = useAuth();
  const { toastError } = useToast();
  const { isAuthenticated } = useUserStore();

  // Queries, mutations
  const { data } = useQuery({
    queryKey: [queryKeys.PROJECT_INFO],
    queryFn: getProjectInformation,
  });

  const {
    mutateAsync: createOrderMutation,
    data: project,
    isPending,
  } = useMutation({
    mutationFn: (body: TCreateProject) => {
      return createProject(body);
    },
  });

  const {
    data: listRecommendFemale,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: [queryKeys.RECOMMEND_FEMALE, selectedGirlId, searchValue],
    queryFn: async ({ pageParam }) =>
      getRecommendFemale({
        currentPage: pageParam,
        pageSize: PAGE_SIZE,
        femaleId: selectedGirlId,
        search: searchValue,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, currentPage) => {
      return lastPage?.totalPage > currentPage ? currentPage + 1 : undefined;
    },
    enabled: !!searchValue || !!selectedGirlId,
  });

  // Memo, callbacks
  const handleChooseGirl = useCallback(
    (value: TFemale) => {
      setListSelectedGirl((prev) => {
        if (prev.some((girl) => girl.userId === value.userId)) {
          return prev.filter((item) => item.id !== value.id);
        } else {
          return [value, ...prev];
        }
      });
    },
    [setListSelectedGirl]
  );

  const listView = useMemo(
    () => listRecommendFemale?.pages.map(({ data }) => data).flat() || [],
    [listRecommendFemale?.pages]
  );

  const totalPriceGirl = useMemo(() => {
    const femaleCount = +formState[EFieldFormTravel.FEMALE_COUNT];
    const girlCount = femaleCount - listSelectedGirl.length;
    const nightCount = getNumberOfNights(
      formState[EFieldFormTravel.START_DATE],
      formState[EFieldFormTravel.END_DATE]
    );
    let total = 0;
    listSelectedGirl.forEach((girl) => {
      total += girl.starRank?.tourPrice;
    });
    const getLimitAmount = (limit: number) =>
      getFormattedAmount((total + girlCount * limit) * nightCount);

    if (femaleCount === listSelectedGirl.length) {
      return getFormattedAmount(total * nightCount) + COMMON_STR.YEN;
    }

    return COMMON_STR.PRICE_RANGE.replace(
      '$min',
      getLimitAmount(LIMIT_VALUE.MIN_TOUR_PRICE)
    ).replace('$max', getLimitAmount(LIMIT_VALUE.MAX_TOUR_PRICE));
  }, [formState, listSelectedGirl]);

  const clearState = useCallback(() => {
    setSelectedGirlId();
    setFormState(initialTravelValue);
    setListSelectedGirl([]);
    setOrderTravelStep(EOrderTravelStep.ORDER_PRICE);
    setIsPendingOrder(false);
  }, [
    setFormState,
    setIsPendingOrder,
    setListSelectedGirl,
    setOrderTravelStep,
    setSelectedGirlId,
  ]);

  const handleOrderGirl = useCallback(async () => {
    try {
      const data = {
        peopleCount: +formState[EFieldFormTravel.FEMALE_COUNT],
        maleCount: +formState[EFieldFormTravel.MALE_COUNT],
        tourStartDate: moment(formState[EFieldFormTravel.START_DATE])
          .utc()
          .startOf('day')
          .toISOString(),
        tourEndDate: moment(formState[EFieldFormTravel.END_DATE])
          .utc()
          .startOf('day')
          .toISOString(),
        ProjectCandidate: listSelectedGirl.map((girl) => girl.userId),
        estimatePrice: totalPriceGirl,
        type: EProjectType.TOUR,
      };
      await createOrderMutation(data);
      setOpenModal(true);
      clearState();
    } catch (error) {
      toastError(error as Error);
    }
  }, [
    clearState,
    createOrderMutation,
    formState,
    listSelectedGirl,
    toastError,
    totalPriceGirl,
  ]);

  // Effect
  useEffect(() => {
    if (listView[0]?.userId === selectedGirlId && selectedGirlId) {
      setListSelectedGirl([listView[0]]);
    }
  }, [listView, searchValue, selectedGirlId, setListSelectedGirl]);

  useEffect(() => {
    if (isAuthenticated && isPendingOrder) {
      setOrderTravelStep(EOrderTravelStep.SUMMARY_ORDER);
    }
  }, [isAuthenticated, isPendingOrder, setOrderTravelStep]);

  return (
    <>
      <div className="h-full flex flex-col">
        <Header
          title={titleModal[orderTravelStep]}
          onBack={() =>
            orderTravelStep === EOrderTravelStep.ORDER_PRICE
              ? navigate(-1)
              : setOrderTravelStep((prev) => prev - 1)
          }
        />
        {orderTravelStep === EOrderTravelStep.ORDER_PRICE ? (
          <PriceList
            onNext={() => setOrderTravelStep(EOrderTravelStep.ORDER_GIRL)}
            isOnlyShowTravel
          />
        ) : (
          <FormOrderTravel
            projectInfo={data}
            formState={formState}
            setFormState={setFormState}
            listGirl={listSelectedGirl}
            onChooseGirl={(value) => handleChooseGirl(value)}
            clearListGirl={() => setListSelectedGirl([])}
            onOrder={() => {
              return orderTravelStep === EOrderTravelStep.SUMMARY_ORDER
                ? handleOrderGirl()
                : checkNeedToLogin(routes.SIGNUP)
                ? setIsPendingOrder(true)
                : setOrderTravelStep(EOrderTravelStep.SUMMARY_ORDER);
            }}
            fetchMore={fetchNextPage}
            hasMore={hasNextPage}
            listView={listView}
            searchFemale={searchValue}
            setSearchFemale={setSearchValue}
            loadingFemale={isLoading}
            estimatePrice={totalPriceGirl}
            readonly={orderTravelStep === EOrderTravelStep.SUMMARY_ORDER}
            loading={isPending}
          />
        )}
      </div>

      <ModalSuccess
        open={openModal}
        onConfirm={() => {
          setOpenModal(false);
          navigate(
            routes.CONVERSATION.replace(':id', project?.channelId || '')
          );
          setOpenTutorial(
            !localStorage.getItem(storageKeys.IS_CONFIRM_TUTORIAL) &&
              !detectPWA()
          );
        }}
      >
        <Text className="mt-6" center textColor="text-neutral-900">
          {TEXT_STRING.SUMMARY_ORDER.ORDER_SUCCESS_MESSAGE} <br />
          {TEXT_STRING.SUMMARY_ORDER.ORDER_SUCCESS_HINT}
        </Text>
      </ModalSuccess>
    </>
  );
}

export default Travel;

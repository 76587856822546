import { PAGE_SIZE, TEXT_STRING, queryKeys } from '../../../constants';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import {
  Button,
  DraggableModal,
  Loading,
  Header,
  PaymentCardList,
  PaymentForm,
  Text,
} from 'components';
import { getCardPaymentList, registerCard, updateCardDefault } from 'api';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TCard } from 'types';

function PaymentMethod() {
  const timeoutRef = useRef<NodeJS.Timeout>();

  const [isAddCard, setIsAddCard] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<TCard>();

  const { data, isLoading, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery({
      queryKey: [queryKeys.CARD_PAYMENT],
      queryFn: async ({ pageParam }) =>
        getCardPaymentList(pageParam, PAGE_SIZE),
      initialPageParam: 1,
      getNextPageParam: (lastPage, _, currentPage) => {
        return lastPage?.totalPage > currentPage ? currentPage + 1 : undefined;
      },
    });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (token: string) => {
      return registerCard(token);
    },
  });

  const { mutateAsync: mutateUpdate } = useMutation({
    mutationFn: (id: number) => {
      return updateCardDefault(id);
    },
  });

  const onSaveCard = useCallback(
    (token?: string) => {
      return mutateAsync(token || '').then(() => {
        refetch();
        setIsAddCard(false);
      });
    },
    [mutateAsync, refetch, setIsAddCard]
  );

  const onUpdateDefault = useCallback(
    (id: number) => {
      return mutateUpdate(id).then(() => {
        refetch();
      });
    },
    [mutateUpdate, refetch]
  );

  const onSelectCard = useCallback(
    (card: TCard) => {
      setSelectedCard(card);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        onUpdateDefault(card.id);
      }, 700);
    },
    [onUpdateDefault]
  );

  const cards = useMemo(
    () => data?.pages.map(({ data }) => data).flat() ?? [],
    [data]
  );

  useEffect(() => {
    cards?.forEach((card) => {
      if (card.isDefault) {
        setSelectedCard(card);
      }
    });

    return () => {};
  }, [cards]);

  return (
    <>
      <div className="flex flex-col h-full">
        <Header title={TEXT_STRING.MY_PAGE.PAYMENT_METHOD} />
        <div className="overflow-y-auto py-4" id="scrollFollowing">
          {isLoading ? (
            <div className="w-full flex justify-center items-center p-60">
              <Loading />
            </div>
          ) : (
            <div>
              <Text className="px-4">{TEXT_STRING.PAYMENT.PAYMENT_METHOD}</Text>
              {!cards.length ? (
                <div className="p-8 flex justify-center">
                  {/* {TEXT_STRING.COMMON.NO_DATA} */}
                </div>
              ) : (
                <PaymentCardList
                  isEdit
                  data={cards}
                  selectedCard={selectedCard}
                  hasMore={hasNextPage}
                  onSelectCard={onSelectCard}
                  fetchMore={fetchNextPage}
                  refetchAPI={refetch}
                />
              )}
            </div>
          )}
          <div className="">
            <Button
              onClick={() => {
                setIsAddCard(true);
              }}
              bgColor="bg-transparent"
              textColor="text-primary"
              bold
            >
              {TEXT_STRING.PAYMENT.ADD_METHOD}
            </Button>
            <Text className="px-4">{TEXT_STRING.PAYMENT.NOTE}</Text>
          </div>
        </div>
      </div>
      <DraggableModal
        onClose={() => {
          setIsAddCard(false);
        }}
        open={isAddCard}
        title={`クレジットカード登録`}
      >
        <PaymentForm
          submitAction={{
            action: onSaveCard,
            loading: isPending,
            disabled: isPending,
          }}
        />
      </DraggableModal>
    </>
  );
}

export default PaymentMethod;

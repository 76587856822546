import { Button, Chip, Icon, Modal, Text } from 'components';
import { TEXT_STRING } from '../../../constants';
import { TGift } from 'types';
import { formatNumber } from 'utils';

const GIFT_STR = TEXT_STRING.GIFT;

type Props = {
  open?: boolean;
  loading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  gift?: TGift;
  isLocked?: boolean;
};

function ModalGift({
  open = false,
  loading = false,
  onClose,
  onConfirm,
  gift,
  isLocked = false,
}: Props) {
  return (
    <Modal
      open={open}
      position="center"
      onClose={onClose}
      className="bg-white p-6 flex flex-col justify-between items-center mx-5"
    >
      <div onClick={onClose} className="absolute right-6 cursor-pointer">
        <Icon icon={'close'} />
      </div>
      <img src={gift?.image} alt="" className="px-[20%]" />
      <div className="flex flex-col gap-2 mb-4">
        <Text center bold fontSize={18}>
          {gift?.name}
        </Text>
        <Chip
          className="ring-2 flex justify-center"
          bgColor=""
          ringColor="ring-primary"
          rounded="rounded-3xl"
          filled
        >
          <Text bold fontSize={14} textColor="text-primary">{`${formatNumber(
            gift?.price || 0
          )} ${gift?.currency}`}</Text>
        </Chip>
      </div>
      {isLocked && (
        <Text fontSize={14} center>
          {GIFT_STR.WARNING_MESSAGE}
        </Text>
      )}
      <Button
        onClick={onConfirm}
        className="w-full py-4 my-4"
        loading={loading}
        disabled={loading || isLocked}
        textSize="text-sm"
        bold
      >
        {GIFT_STR.GIVE_GIFT.replace('$gift', gift?.name || '')}
      </Button>
      <Text>{GIFT_STR.NOTE}</Text>
    </Modal>
  );
}

export default ModalGift;

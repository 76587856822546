import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import Icon from 'components/Icon';
import { TEXT_STRING } from '../../constants';
import { TSelectOption } from 'types';
import Label from 'components/Label';
import Rating from 'components/Rating';
import Text from 'components/Text';

type TOption = TSelectOption;
type Props = {
  className?: string;
  name: string;
  values: TOption[];
  label?: string;
  disabled?: boolean;
  onChange: (value: TOption[]) => void;
  onBlur?: () => {};
  onFocus?: () => {};
  options: TOption[];
  rounded?: `rounded-${string}`;
  require?: boolean;
  error?: boolean;
  isSelectRating?: boolean;
};

const MultipleSelect = ({
  className = '',
  name,
  values,
  label,
  disabled = false,
  onChange,
  onBlur,
  onFocus,
  options,
  rounded = 'rounded-[8px]',
  require = false,
  error = false,
  isSelectRating = false,
}: Props) => {
  return (
    <Listbox value={values} onChange={onChange} multiple>
      {label && (
        <Label require={require} error={error}>
          {label}
        </Label>
      )}
      <div className="relative mt-2">
        <Listbox.Button
          name={name}
          className={`${className} ${rounded} ${
            disabled ? 'bg-neutral-50 pointer-events-none' : 'bg-white'
          } truncate py-10px relative w-full cursor-default pl-3 pr-10 text-left ring-1 ring-inset ring-neutral-300 focus:outline-none focus:ring-2 focus:ring-primary sm:leading-6`}
          onBlur={onBlur}
          onFocus={onFocus}
        >
          {values.length ? (
            <div
              className={`${
                isSelectRating ? 'flex-wrap flex gap-2' : 'block truncate'
              }`}
            >
              {values.map(({ value, name }, index) => {
                const showHyphen = index < values.length - 1;
                return (
                  <Fragment key={value}>
                    {isSelectRating ? (
                      <>
                        <Rating value={+value} isShowEmpty={false} />
                        <span className={showHyphen ? 'visible' : 'invisible'}>
                          -
                        </span>
                      </>
                    ) : (
                      `${name}${showHyphen ? ', ' : ''}`
                    )}
                  </Fragment>
                );
              })}
            </div>
          ) : (
            <Text textColor="text-neutral-300">
              {TEXT_STRING.COMMON.SELECT}
            </Text>
          )}
          <span className="pointer-events-none absolute inset-y-0 right-2 ml-3 mb-1 flex items-center pr-2 -rotate-90 scale-75">
            <Icon icon={'chevronLeft'} />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {options.map((option, index) => (
              <Listbox.Option
                key={option.value}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-primary-400 text-white' : 'text-gray-900'
                  }`
                }
                value={option}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {isSelectRating ? (
                        <Rating value={+option.value} isShowEmpty={false} />
                      ) : (
                        option.name
                      )}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                        <Icon icon="check" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default MultipleSelect;

const LoadingPost = ({
  index,
  className = '',
}: {
  index?: number;
  className?: string;
}) => {
  const animation =
    (index || 0) % 2 === 0
      ? `animate-pulse`
      : `animate-pulse [animation-delay:-0.1s]`;
  return (
    <div className={`${className} flex w-full px-4 py-2`}>
      <div
        className={`h-16 w-16 rounded-full bg-gray-300 mr-3 ${animation} self-start align-top`}
      />
      <div className="flex flex-col flex-1">
        <div
          className={`rounded-xl h-6 w-1/2 border border-neutral-200 bg-gray-300 text-gray-300 ${animation}`}
        ></div>
        <div
          className={`mt-2 rounded-xl h-4 w-1/2 border border-neutral-200 bg-gray-300 text-gray-300 ${animation}`}
        ></div>
        <div
          className={`mt-8 rounded-xl h-64 border border-neutral-200 bg-gray-300 text-gray-300 ${animation}`}
        ></div>
      </div>
    </div>
  );
};

export default LoadingPost;

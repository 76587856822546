import { ReactElement } from 'react';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { TEXT_STRING } from '../../constants';

type Props = {
  title?: string;
  content?: string | ReactElement;
  open?: boolean;
  onClose?: () => void;
  onClick?: () => void;
};

function PopupWarning({
  title,
  content,
  open = false,
  onClose,
  onClick,
}: Props) {
  return (
    <Modal open={open} onClose={() => onClose && onClose()}>
      <div className="flex flex-col gap-5 bg-white p-8 pt-12 mx-4 rounded-xl">
        <div className="self-center">
          <Icon icon="warning" color="var(--color-warning-400)" />
        </div>
        <div>
          <Text center bold fontSize={18}>
            {title}
          </Text>
          <Text center className="mt-3 px-4" fontSize={14}>
            {content}
          </Text>
        </div>
        <div className="flex flex-col gap-5 mt-2">
          <Button
            className="w-full py-5"
            onClick={() => onClick && onClick()}
            bold
            textSize="text-sm"
          >
            {TEXT_STRING.COMMON.YES}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default PopupWarning;

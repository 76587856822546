import AnimationView from 'components/AnimationView';
import { queryKeys } from '../../constants';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

type Props = {
  name: string;
  image: string;
  loop?: boolean;
  autoplay?: boolean;
  speed?: number;
  animations?: string[];
  onComplete?: () => void;
  onLoopComplete?: () => void;
};

// TODO: only taxi has animation bounce
const ANIMATE_GIFT = ['タクシー'];
const ANIMATION_TIME = 3000;

function GiftView({
  name,
  image,
  loop = false,
  autoplay = true,
  speed,
  onComplete = () => {},
  onLoopComplete = () => {},
  animations,
}: Props) {
  const timeoutRef = useRef<NodeJS.Timeout>();

  const [animationCompleted, setAnimationCompleted] = useState<number[]>([]);

  // Query
  const { data: gift, error } = useQuery({
    queryKey: [queryKeys.GIFT_DATA, image],
    queryFn: async () => {
      if (animations) {
        const newData = [];
        for (const item of animations) {
          const response = await fetch(item);
          newData.push(await response.json());
        }
        return newData;
      }
    },
    staleTime: Infinity,
    retry: false,
    enabled: !!animations?.length,
  });

  const AnimationsView = useMemo(
    () =>
      !!gift?.length &&
      gift.map((animationData, index) => (
        <div
          key={index}
          className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-full"
        >
          <AnimationView
            options={{ animationData, loop, autoplay }}
            speed={speed}
            isClickToPauseDisabled
            onComplete={() => {
              setAnimationCompleted((prev) => [...prev, index]);
            }}
          />
        </div>
      )),
    [gift, loop, speed, autoplay, setAnimationCompleted]
  );

  useEffect(() => {
    if (animationCompleted.length === animations?.length) {
      onComplete();
    }
  }, [animations, animationCompleted, onComplete]);

  useEffect(() => {
    if ((!animations?.length || !!error) && !timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = undefined;
        onComplete();
      }, ANIMATION_TIME);
    }
  }, [animations, error, onComplete]);

  return (
    <>
      {!animations?.length || !!error ? (
        <img
          src={image}
          alt=""
          className={`${
            ANIMATE_GIFT.includes(name) ? 'animate-bounce' : 'animate-wiggle'
          } w-[90%] m-auto`}
        ></img>
      ) : (
        <>{AnimationsView}</>
      )}
    </>
  );
}

export default memo(GiftView);

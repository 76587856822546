import React, { useEffect } from 'react';
import './App.css';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Layout from 'layout';
import { ProtectedRoute, routers } from 'routers';
import { routes } from './constants';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { detectMobile } from 'utils';
import { WarningModal, TutorialModal } from 'components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';
import OneSignal from 'react-onesignal';
import { useUserStore } from 'store';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    },
  },
});

// Setup api payment
const PAYMENT_API_TOKEN = process.env.REACT_APP_PAYMENT_API_TOKEN;
const TRACKING_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
// Setup OneSignal
const APP_ID = process.env.REACT_APP_ONESIGNAL_APP_ID;

window.TokenPayment.setup(PAYMENT_API_TOKEN, () => {});

function App() {
  const isMobile = detectMobile();
  const navigate = useNavigate();
  const { setSubscriptionId } = useUserStore();

  useEffect(() => {
    if (TRACKING_ID) {
      ReactGA.initialize(TRACKING_ID);
      // Send pageview with a custom path
      ReactGA.send({
        hitType: 'pageview',
        page: '/',
        title: 'TECO APPLICATION',
      });
    }
  }, []);

  useEffect(() => {
    if (APP_ID) {
      OneSignal.init({
        appId: APP_ID,
        notificationClickHandlerMatch: 'origin',
        notificationClickHandlerAction: 'focus',
        autoRegister: true,
        autoResubscribe: true,
      })
        .then(() => {
          OneSignal.Slidedown.promptPush();
          setSubscriptionId(OneSignal.User.PushSubscription.id || null);
        })
        .catch((err) => {
          console.log(`OneSignal initialization failed: ${err} `);
        });
    }
  }, [setSubscriptionId]);

  useEffect(() => {
    const navigateSpecificPage = (event: MessageEvent<any>) => {
      if (event.data?.action === 'navigate' && event.data?.url) {
        navigate(event.data.url);
      }
    };
    navigator?.serviceWorker?.addEventListener('message', navigateSpecificPage);
    return () => {
      navigator?.serviceWorker?.removeEventListener(
        'message',
        navigateSpecificPage
      );
    };
  }, [navigate]);

  return (
    <QueryClientProvider client={queryClient}>
      <div
        id="app"
        className={`${
          !isMobile ? `w-[50vh] relative mx-auto translate-x-0` : ``
        } h-[100dvh] overflow-hidden bg-white`}
      >
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Navigate to={routes.SEARCH} />} />
            {routers.map(
              ({ path, component: PageComponent, isProtected, children }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    isProtected ? (
                      <ProtectedRoute>
                        <PageComponent />
                      </ProtectedRoute>
                    ) : (
                      <PageComponent />
                    )
                  }
                >
                  {children?.map((child) => {
                    const ChildComponent = child.component;
                    return (
                      <Route
                        key={child.path}
                        path={child.path}
                        element={<ChildComponent />}
                        index={child.index}
                      />
                    );
                  })}
                </Route>
              )
            )}
          </Route>
        </Routes>
        <WarningModal />
        <TutorialModal />
        <div id="recaptcha-container" className="invisible"></div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
    </QueryClientProvider>
  );
}

export default App;

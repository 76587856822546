import Lottie, { Options } from 'react-lottie';

type Props = {
  options: Options;
  height?: number | string;
  width?: number | string;
  speed?: number;
  isStopped?: boolean;
  isPaused?: boolean;
  isClickToPauseDisabled?: boolean;
  onComplete?: () => void;
  onLoopComplete?: () => void;
};

function AnimationView({
  options,
  height,
  width,
  speed,
  isStopped = false,
  isPaused = false,
  isClickToPauseDisabled = true,
  onComplete = () => {},
  onLoopComplete = () => {},
}: Props) {
  return (
    <>
      <Lottie
        options={options}
        height={height}
        width={width}
        speed={speed}
        isStopped={isStopped}
        isPaused={isPaused}
        isClickToPauseDisabled={isClickToPauseDisabled}
        eventListeners={[
          {
            eventName: 'complete',
            callback: onComplete,
          },
          {
            eventName: 'loopComplete',
            callback: onLoopComplete,
          },
          // Other events
          // {
          //   eventName: 'enterFrame',
          //   callback: () => console.log('enterFrame'),
          // },
          // {
          //   eventName: 'segmentStart',
          //   callback: () => console.log('segmentStart'),
          // },
          // {
          //   eventName: 'config_ready',
          //   callback: () => console.log('config_ready'),
          // },
          // {
          //   eventName: 'data_ready',
          //   callback: () => console.log('data_ready'),
          // },
          // {
          //   eventName: 'loaded_images',
          //   callback: () => console.log('loaded_images'),
          // },
          // {
          //   eventName: 'DOMLoaded',
          //   callback: () => console.log('DOMLoaded'),
          // },
          // {
          //   eventName: 'destroy',
          //   callback: () => console.log('destroy'),
          // },
        ]}
      />
    </>
  );
}

export default AnimationView;

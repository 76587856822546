import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { getCardPaymentList, registerCard } from 'api';
import Button from 'components/Button';
import PaymentCardList from 'components/PaymentCardList';
import PaymentForm from 'components/PaymentForm';
import Text from 'components/Text';
import { PAGE_SIZE, TEXT_STRING, queryKeys } from '../../constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ECardMethod, EPaymentMethod, TCard, TPaymentPayload } from 'types';
import { getFormattedAmount } from 'utils';
import Loading from 'components/Loading';
import { useToast } from 'hooks';
import CardMethod from 'components/CardMethod';

type Props = {
  title?: string;
  paying: boolean;
  price?: number;
  onPay: (payload: TPaymentPayload) => Promise<void>;
  subTitle?: string;
  onSettled?: () => void;
  isMaintenanceVisa?: boolean;
};

const CardPayment = ({
  title = TEXT_STRING.PAYMENT.PAYMENT_METHOD,
  paying,
  price,
  onPay,
  subTitle = TEXT_STRING.CALL_WOMAN.PAYMENT_WARNING,
  onSettled,
  isMaintenanceVisa = true,
}: Props) => {
  // Hooks
  const { toastError } = useToast();

  // State
  const [selectedCard, setSelectedCard] = useState<TCard>();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [cardMethod, setCardMethod] = useState<ECardMethod>(ECardMethod.OTHERS);
  const [isPaymentCard, setIsPaymentCard] = useState<boolean>(false);

  // Queries, mutation
  const {
    data: cardData,
    isLoading: cardLoading,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: [queryKeys.CARD_PAYMENT],
    queryFn: async ({ pageParam }) => getCardPaymentList(pageParam, PAGE_SIZE),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, currentPage) => {
      return lastPage?.totalPage > currentPage ? currentPage + 1 : undefined;
    },
  });

  const { mutateAsync: mutateSaveCard } = useMutation({
    mutationFn: (token: string) => {
      return registerCard(token);
    },
  });

  // Memo, callbacks
  const cards = useMemo(
    () => cardData?.pages.map(({ data }) => data).flat() ?? [],
    [cardData?.pages]
  );

  const handlePaymentCardAvailable = useCallback(async () => {
    const cardList = cards?.filter((item) => item.id !== selectedCard?.id);
    if (selectedCard?.cardToken) {
      try {
        await onPay({ token: selectedCard.cardToken });
      } catch (err: any) {
        let error = err;
        const retryPayment = err?.response?.data?.retry;
        // ※ 上記でご選択板ファイているカードで決済エラーとなる場合、自動的に他に登録いただいているカードで決済が行われるしようとなっております。
        if (retryPayment !== false) {
          for (const card of cardList) {
            try {
              await onPay({ token: card.cardToken });
              error = undefined;
              break;
            } catch (err) {
              error = err;
            }
          }
        }
        if (error) {
          toastError(error);
        }
      } finally {
        onSettled && onSettled();
      }
    }
  }, [cards, selectedCard, onPay, toastError, onSettled]);

  const handlePaymentCard = useCallback(
    async (payload: TPaymentPayload) => {
      const { token } = payload;
      try {
        await onPay(payload);
        if (!cards?.length && token) {
          mutateSaveCard(token);
        }
      } catch (error) {
        toastError(error as Error);
      } finally {
        onSettled && onSettled();
      }
    },
    [cards?.length, mutateSaveCard, onPay, onSettled, toastError]
  );

  // Effect
  useEffect(() => {
    cards?.forEach((card) => {
      if (card.isDefault) {
        setSelectedCard(card);
        return;
      }
    });

    return () => {};
  }, [cards]);

  return (
    <>
      {title && (
        <Text className="text-3xl py-6" center bold>
          {title}
        </Text>
      )}
      <Text className="text-3xl pb-5" center bold>
        {getFormattedAmount(price)}
        {TEXT_STRING.COMMON.YEN}
      </Text>
      <Text center fontSize={14}>
        {subTitle}
      </Text>
      {!isPaymentCard ? (
        <CardMethod
          onChooseMethod={setCardMethod}
          cardMethod={cardMethod}
          onSubmit={() => setIsPaymentCard(true)}
          isMaintenance={isMaintenanceVisa}
        />
      ) : (
        <>
          {cardMethod === ECardMethod.VISA ? (
            <PaymentForm
              submitAction={{
                action: (token, customerId) =>
                  handlePaymentCard({
                    token,
                    customerId,
                    type: EPaymentMethod.VISA,
                  }),
                loading: paying,
                disabled: paying,
              }}
              isVeilPay
              onCancel={() => setIsPaymentCard(false)}
            />
          ) : (
            <>
              {cardLoading ? (
                <div className="pt-6">
                  <Loading />
                </div>
              ) : !!cards.length && !showForm ? (
                <>
                  <PaymentCardList
                    data={cards}
                    selectedCard={selectedCard}
                    hasMore={hasNextPage}
                    onSelectCard={(card: TCard) => {
                      setSelectedCard(card);
                    }}
                    fetchMore={fetchNextPage}
                    refetchAPI={refetch}
                  />
                  <Button
                    onClick={() => {
                      setShowForm(true);
                    }}
                    bgColor="bg-transparent"
                    textColor="text-primary"
                    bold
                    disabled={paying}
                  >
                    {TEXT_STRING.PAYMENT.ADD_METHOD}
                  </Button>
                  <Button
                    onClick={handlePaymentCardAvailable}
                    block
                    disabled={!selectedCard || paying}
                    loading={paying}
                  >
                    {TEXT_STRING.COMMON.CONTINUE}
                  </Button>
                </>
              ) : (
                <PaymentForm
                  submitAction={{
                    action: (token, customerId) =>
                      handlePaymentCard({ token, customerId }),
                    loading: paying,
                    disabled: paying,
                  }}
                  onCancel={() => {
                    setIsPaymentCard(false);
                    setShowForm(false);
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default CardPayment;

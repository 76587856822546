import { createContext } from 'react';
import { EGiftPaymentStep, EPointPaymentStep, TGiftMessage } from 'types';

type TChatContext = {
  giftView?: TGiftMessage;
  setGiftView: (gift: TGiftMessage) => void;
  historyPaymentId?: number;
  setHistoryPaymentId: (id?: number) => void;
  stepPaymentGift?: EGiftPaymentStep;
  setStepPaymentGift: (step?: EGiftPaymentStep) => void;
  pointHistoryId?: number;
  setPointHistoryId: (id?: number) => void;
  setPaymentStep: (step?: EPointPaymentStep) => void;
};

const ChatContext = createContext<TChatContext>({
  setGiftView: () => {},
  setHistoryPaymentId: () => {},
  setStepPaymentGift: () => {},
  setPointHistoryId: () => {},
  setPaymentStep: () => {}
});

export default ChatContext;

import React from 'react';

type Props = React.PropsWithChildren<{
  className?: string;
  fontWeight?: `font-${string}`;
  textColor?: `text-${string}`;
  fontSize?: number;
  center?: boolean;
  bold?: boolean;
  onClick?: () => void;
  nowrap?: boolean;
  textAlign?: `text-${string}`;
}>;

function Text({
  children,
  className = '',
  textColor = 'text-neutral-600',
  fontSize,
  center,
  bold,
  fontWeight = bold ? 'font-bold' : 'font-normal',
  textAlign = center ? 'text-center' : 'text-start',
  onClick,
  nowrap = false,
}: Props) {
  return (
    <p
      style={{ fontSize: fontSize }}
      className={`${className} 
				${textColor} 
				${textAlign} 
				${fontWeight}
        ${nowrap ? 'whitespace-nowrap' : 'whitespace-pre-line'}
			`}
      onClick={onClick}
    >
      {children}
    </p>
  );
}

export default Text;

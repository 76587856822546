import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AUTO_PRICE,
  PAGE_SIZE,
  TEXT_STRING,
  queryKeys,
} from '../../../../constants';
import { TCreateProject, TFemale } from 'types';
import { useChatStore } from 'store';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  createProjectOrder,
  getProjectInformation,
  getRecommendFemale,
} from 'api';
import { combineDateTime, getFormattedAmount } from 'utils';
import { FIELD_NAME, initialValueCallWoman } from 'helpers';
import { useToast } from 'hooks';
import { CallWoman } from 'components';

const CHAT_STR = TEXT_STRING.CHAT;

type Props = {
  projectId: number;
  areaId: number;
};

function AddGirl({ projectId, areaId }: Props) {
  // State
  const [formStateAddGirl, setFormStateAddGirl] = useState<
    Record<string, string>
  >(initialValueCallWoman);
  const [listSelectedGirl, setListSelectedGirl] = useState<TFemale[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  // Hook
  const { setActionChatStep } = useChatStore();
  const { toastError } = useToast();

  const { data: projectInfo } = useQuery({
    queryKey: [queryKeys.PROJECT_INFO],
    queryFn: getProjectInformation,
    refetchOnMount: false,
  });

  const { mutateAsync: createOrderMutate, isPending } = useMutation({
    mutationFn: (body: TCreateProject) => {
      return createProjectOrder(body);
    },
    onError(error) {
      toastError(error);
    },
  });

  const {
    data,
    isPending: loadingFemale,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [queryKeys.RECOMMEND_ORDER, projectId, searchValue],
    queryFn: async ({ pageParam }) =>
      getRecommendFemale({
        currentPage: pageParam,
        pageSize: PAGE_SIZE,
        projectId: projectId,
        search: searchValue,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, currentPage) => {
      return lastPage?.totalPage > currentPage ? currentPage + 1 : undefined;
    },
    enabled: !!projectId && !!searchValue,
  });

  const handleChooseGirl = useCallback((value: TFemale) => {
    setListSelectedGirl((prev) => {
      return prev.some((girl) => girl.userId === value.userId)
        ? prev.filter((item) => item.id !== value.id)
        : [value, ...prev];
    });
  }, []);

  const totalPriceGirl = useMemo(() => {
    const setCount = +formStateAddGirl[FIELD_NAME.SET];
    const peopleCount = +formStateAddGirl[FIELD_NAME.PEOPLE];
    const girlCount = peopleCount - listSelectedGirl.length;

    const total =
      listSelectedGirl.reduce((result, current) => {
        const price = current.starRank?.groupPrice || AUTO_PRICE;
        const nominatedPrice = current.starRank?.nominatedPrice;
        return result + price * setCount + nominatedPrice;
      }, 0) +
      girlCount * AUTO_PRICE * setCount;

    return getFormattedAmount(total);
  }, [formStateAddGirl, listSelectedGirl]);

  const clearState = useCallback(() => {
    setActionChatStep();
    setFormStateAddGirl(initialValueCallWoman);
    setListSelectedGirl([]);
  }, [setActionChatStep]);

  const handleOrderGirl = useCallback(async () => {
    const dateTime = combineDateTime(
      formStateAddGirl[FIELD_NAME.DATE],
      formStateAddGirl[FIELD_NAME.TIME]
    ).toISOString();
    const data = {
      setCount: +formStateAddGirl[FIELD_NAME.SET],
      peopleCount: +formStateAddGirl[FIELD_NAME.PEOPLE],
      startDate: dateTime,
      projectId: projectId,
      ProjectCandidate: listSelectedGirl.map((girl) => girl.userId),
      estimatePrice: totalPriceGirl,
    };
    const areaId = +formStateAddGirl[FIELD_NAME.AREA_ID];
    try {
      await createOrderMutate(areaId ? { ...data, areaId } : data);
      clearState();
    } catch (error) {
      console.log(error);
    }
  }, [
    clearState,
    createOrderMutate,
    formStateAddGirl,
    listSelectedGirl,
    projectId,
    totalPriceGirl,
  ]);

  useEffect(() => {
    setFormStateAddGirl((prev) =>
      !!areaId ? { ...prev, areaId: `${areaId}` } : prev
    );
  }, [areaId]);

  return (
    <CallWoman
      projectInfo={projectInfo}
      formState={formStateAddGirl}
      setFormState={setFormStateAddGirl}
      listGirl={listSelectedGirl}
      onChooseGirl={(value) => handleChooseGirl(value)}
      clearListGirl={() => setListSelectedGirl([])}
      estimatePrice={totalPriceGirl}
      setListGirl={setListSelectedGirl}
      onOrder={handleOrderGirl}
      textFooterTitle={CHAT_STR.ADDITIONAL_ESTIMATED_AMOUNT}
      textFooterButton={CHAT_STR.BUTTON_ADD_GIRL}
      loadingButton={isPending}
      loadingFemale={loadingFemale}
      fetchMore={fetchNextPage}
      hasMore={hasNextPage}
      listView={data?.pages.map(({ data }) => data).flat()}
      isCallNewGirl
      searchFemale={searchValue}
      setSearchFemale={setSearchValue}
      disabledArea
    />
  );
}

export default AddGirl;

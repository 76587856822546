import { TPostDetail } from 'types';
import { create } from 'zustand';

type State = {
  bannerHeight?: number;
  postRecommendScrollY?: number;
  postFollowedScrollY?: number;
  listPostPaid: TPostDetail[];
};

type Actions = {
  setBannerHeight: (height: number) => void;
  setPostRecommendScrollY: (value?: number) => void;
  setPostFollowedScrollY: (value?: number) => void;
  setListPostPaid: (value: TPostDetail) => void;
};

const usePostStore = create<State & Actions>((set) => ({
  listPostPaid: [],
  setBannerHeight: (height: number) =>
    set(() => ({
      bannerHeight: height,
    })),
  setPostRecommendScrollY: (value?: number) => {
    set(() => ({ postRecommendScrollY: value }));
  },
  setPostFollowedScrollY: (value?: number) => {
    set(() => ({ postFollowedScrollY: value }));
  },
  setListPostPaid: (value: TPostDetail) => {
    set((state) => ({ listPostPaid: [...state.listPostPaid, value] }));
  },
}));

export default usePostStore;

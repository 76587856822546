import { firebaseConfig } from '../constants';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
  MessagePayload,
  Messaging,
  // getMessaging,
  getToken,
  onMessage,
} from 'firebase/messaging';

const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);

let messaging: Messaging;

// const getMessage = async () => {
//   try {
//     messaging = getMessaging(firebaseApp);
//   } catch (error) {}
// };
// getMessage();

const getMessagingToken = async () => {
  if (!messaging) return;
  let currentToken = '';
  try {
    currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });
    return currentToken;
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
  }
};

const onMessageListener = (callbackFn: (payload: MessagePayload) => void) => {
  return new Promise((resolve) => {
    if (messaging) {
      resolve(onMessage(messaging, callbackFn));
    }
  });
};

export { firebaseApp, firebaseAuth, getMessagingToken, onMessageListener };

import { memo, useContext, useMemo } from 'react';
import { TMediaDetail } from 'types';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { PostContext } from 'contexts';
import MediaView from '../MediaView';

type Props = {
  id: string;
  media: TMediaDetail[];
  onClickMedia: (id: string) => void;
  onLoad?: () => void;
  isLockedPost?: boolean;
  isPremiumPost?: boolean;
};

const stylesMap: { [x: number]: { [x: number]: string } } = {
  2: {
    1: 'rounded-tl-2xl rounded-bl-2xl',
    2: 'rounded-tr-2xl rounded-br-2xl',
  },
  3: {
    0: 'rounded-tl-2xl rounded-bl-2xl',
    1: 'rounded-tr-2xl',
    2: 'rounded-br-2xl',
  },
  4: {
    1: 'rounded-tl-2xl',
    2: 'rounded-tr-2xl',
    3: 'rounded-bl-2xl',
    4: 'rounded-br-2xl',
  },
  5: {
    1: 'rounded-tl-2xl',
    2: 'rounded-tr-2xl',
    3: 'rounded-bl-2xl',
    4: '',
    5: 'rounded-br-2xl',
  },
};

function GalleryGrid({
  media,
  onLoad,
  onClickMedia,
  isLockedPost,
  isPremiumPost = false,
}: Props) {
  const { galleryWidth } = useContext(PostContext);
  const data = useMemo(
    () =>
      media?.map((m) => ({ ...m, type: isLockedPost ? 'image/webp' : m.type })),
    [isLockedPost, media]
  );
  const galleryLength = useMemo(() => data.length, [data.length]);
  const options = { controls: isPremiumPost, isAutoPlayVideo: !isPremiumPost };

  switch (galleryLength) {
    case 1:
      return (
        <MediaView
          {...data[0]}
          className="rounded-2xl h-full"
          style={{
            minHeight: galleryWidth && galleryWidth / 5,
          }}
          onClick={() => {
            onClickMedia(data[0].id);
          }}
          onLoad={data[0].originalHeight ? undefined : onLoad}
          videoOptions={options}
        />
      );
    case 2:
      return (
        <div className="flex h-full">
          {data.map((item, index) => (
            <div key={item.id} className="basis-1/2">
              <MediaView
                {...item}
                className={`${stylesMap[galleryLength][index + 1]}`}
                onClick={() => {
                  onClickMedia(item.id);
                }}
                onLoad={item.originalHeight ? undefined : onLoad}
                videoOptions={options}
              />
            </div>
          ))}
        </div>
      );
    case 3:
      return (
        <div className="flex h-full">
          <div className="basis-1/2 flex items-center">
            <MediaView
              {...data[0]}
              className={`${stylesMap[galleryLength][0]}`}
              onClick={() => {
                onClickMedia(data[0].id);
              }}
              onLoad={data[0].originalHeight ? undefined : onLoad}
              videoOptions={options}
            />
          </div>
          <div className="basis-1/2 flex flex-col">
            {data.slice(1, 3).map((item, index) => (
              <div key={item.id} className="basis-1/2">
                <MediaView
                  {...item}
                  className={`${stylesMap[galleryLength][index + 1]}`}
                  onClick={() => {
                    onClickMedia(item.id);
                  }}
                  onLoad={item.originalHeight ? undefined : onLoad}
                  videoOptions={options}
                />
              </div>
            ))}
          </div>
        </div>
      );
    case 4:
      return (
        <div className="flex flex-wrap h-full">
          {data.map((item, index) => (
            <div key={item.id} className={'w-1/2 h-1/2'}>
              <MediaView
                {...item}
                className={`${stylesMap[galleryLength][index + 1]}`}
                onClick={() => {
                  onClickMedia(item.id);
                }}
                onLoad={item.originalHeight ? undefined : onLoad}
                videoOptions={options}
              />
            </div>
          ))}
        </div>
      );

    default:
      return (
        <div className="flex flex-wrap h-full">
          {data.slice(0, 5).map((item, index) => (
            <div
              key={item.id}
              className={`${index > 1 ? 'basis-1/3' : 'basis-1/2'} relative`}
            >
              {index === 4 && galleryLength > 5 && (
                <div className="absolute bg-black/20 w-full h-full pointer-events-none">
                  <h1 className="text-3xl text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    +{galleryLength - 5}
                  </h1>
                </div>
              )}
              <MediaView
                {...item}
                className={`${stylesMap[5][index + 1]}`}
                onLoad={item.originalHeight ? undefined : onLoad}
                videoOptions={options}
              />
            </div>
          ))}
        </div>
      );
  }
}

export default memo(GalleryGrid);

type Props = {
  content: string;
};

function Notice({ content }: Props) {
  return (
    <div className="flex">
      <div className="text-xl leading-[7px] text-neutral-700 rounded-[50%] mx-[7px]">
        .
      </div>
      <p className="text-sm text-neutral-700">{content}</p>
    </div>
  );
}

export default Notice;

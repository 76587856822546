import Text from 'components/Text';
import React, { ReactElement } from 'react';

type Props = {
  className?: string;
  onClose?: () => void;
  show?: boolean;
  content?: string | ReactElement;
  withOverplay?: boolean;
  zIndex?: string;
  triangleClass?: string;
};

function Tooltip({
  className = '',
  onClose,
  show = false,
  content,
  withOverplay = false,
  zIndex = 'z-50',
  triangleClass = '',
}: Props) {
  return show ? (
    <>
      {withOverplay && (
        <div className={`fixed h-full w-full top-0 left-0 ${zIndex}`}>
          <div className="bg-black/60 h-full w-full" onClick={onClose}></div>
        </div>
      )}
      <div
        className={`${className} p-5 bg-white absolute rounded-xl ${zIndex}`}
      >
        {typeof content === 'string' ? <Text bold>{content}</Text> : content}
        <div
          className={`${triangleClass} w-0 h-0 absolute -bottom-[10px] border-l-8 border-l-transparent border-t-[10px] border-t-white border-r-8 border-r-transparent`}
        ></div>
      </div>
    </>
  ) : null;
}

export default Tooltip;

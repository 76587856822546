import { Header, Text } from 'components';
import { TEXT_STRING } from '../../../constants';
import { EAppInfo } from 'types';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import TEXT_APP_INFO from 'constants/appInfo';

const STR = TEXT_STRING.MY_PAGE;

const appInfo = {
  [EAppInfo.ABOUT_APP]: {
    title: STR.ABOUT_APP,
    content: TEXT_APP_INFO.ABOUT_APP_CONTENT,
  },
  [EAppInfo.COMMUNICATION_GUIDE]: {
    title: STR.COMMUNICATION_GUIDE,
    content: TEXT_APP_INFO.COMMUNICATION_GUIDE_CONTENT,
  },
  [EAppInfo.TERMS_OF_SERVICE]: {
    title: STR.TERMS_OF_SERVICE,
    content: TEXT_APP_INFO.TERMS_OF_SERVICE_CONTENT,
  },
  [EAppInfo.PRIVACY_POLICY]: {
    title: STR.PRIVACY_POLICY,
    content: TEXT_APP_INFO.PRIVACY_POLICY_CONTENT,
  },
  [EAppInfo.LEGAL_COMPLIANCE_POLICY]: {
    title: STR.LEGAL_COMPLIANCE_POLICY,
    content: TEXT_APP_INFO.LEGAL_COMPLIANCE_POLICY_CONTENT,
  },
};

function AppInfo() {
  const { id } = useParams();
  const textInfo = useMemo(() => {
    if (!id) return;
    const info = appInfo[id as EAppInfo];
    return info;
  }, [id]);

  return (
    <div className="h-full overflow-y-auto">
      <Header title={textInfo?.title} />
      <div className="p-5 pb-10">
        <Text center bold fontSize={14}>
          {STR.TERMS_OF_SERVICE}
        </Text>
        {textInfo?.content.map((item, index) => {
          return (
            <div key={index}>
              <Text bold className="mt-4 leading-7">
                {item.text}
              </Text>
              <Text className="pl-6">{item.content}</Text>
              <ul className="list-decimal pl-6">
                {item.children?.map((c, ind) => {
                  return (
                    <li key={ind}>
                      <Text className="leading-7">{c.text}</Text>
                      {c.children && (
                        <ul>
                          {c.children.map((text, i) => {
                            return (
                              <div key={i} className="flex gap-2">
                                <span>({i + 1})</span>
                                <li>
                                  <Text className="leading-7">{text}</Text>
                                </li>
                              </div>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AppInfo;

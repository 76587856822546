import Text from 'components/Text';
import { formatAmountNumber } from 'utils';
import { TEXT_STRING } from '../../../constants';
import { useAppStore } from 'store';
import { ReactElement } from 'react';
import Rating from 'components/Rating';
import { TStarRank } from 'types';

const MENU_STR = TEXT_STRING.PRICE_LIST;

type Props = {
  onSelectStar: (star?: TStarRank) => void;
  selectedStars?: TStarRank[];
  className?: string;
};

function Item({ content }: { content: string | ReactElement }) {
  return (
    <div>
      {typeof content === 'string' ? (
        <Text center bold fontSize={14}>
          {content}
        </Text>
      ) : (
        content
      )}
    </div>
  );
}

const SpecifyStars = ({
  onSelectStar,
  selectedStars = [],
  className = 'p-5',
}: Props): JSX.Element => {
  const { filterOptions } = useAppStore();
  const { starRanks } = filterOptions || {};

  return (
    <div className={`${className} overflow-y-auto flex flex-col gap-3`}>
      <div className="grid grid-cols-2 bg-neutral-300 rounded-t-xl py-5">
        <Item content={TEXT_STRING.CAST_PAGE.POINT} />
        <Item content={MENU_STR.ONE_SET} />
      </div>
      <div
        className={`${
          !selectedStars.length && 'bg-primary-50'
        } grid grid-cols-2 rounded-xl border border-solid border-neutral-100 items-center h-20 cursor-pointer`}
        onClick={() => onSelectStar()}
      >
        <Item content={MENU_STR.LEAVE_IT} />
        <Item content={MENU_STR.DISPATCHED_EVALUATION_FEE} />
      </div>
      {starRanks?.map((item, index) => {
        return (
          <div
            key={item.id}
            className={`${
              selectedStars.some((star) => star.id === item.id) &&
              'bg-primary-50'
            } grid grid-cols-2 rounded-xl border border-solid border-neutral-100 items-center h-20 cursor-pointer`}
            onClick={() => onSelectStar(item)}
          >
            <Item
              content={
                <Rating
                  value={index + 1}
                  isShowEmpty={false}
                  className="justify-center px-4"
                />
              }
            />
            <Item content={formatAmountNumber(item.price)} />
          </div>
        );
      })}
    </div>
  );
};

export default SpecifyStars;

import Avatar from 'components/Avatar';
import Text from 'components/Text';
import { IMAGE_ACCEPTED, TEXT_STRING } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import {
  EGiftPaymentStep,
  EPointPaymentStep,
  TMember,
  TMessageDetail,
} from 'types';
import moment from 'moment';
import { useContext, useMemo } from 'react';
import Markdown from 'markdown-to-jsx';
import { ChatContext } from '../context';
import ChatMediaView from 'components/Chat/ChatMediaView';

type Props = {
  message: TMessageDetail;
  isMyMessage?: boolean;
  isAdminMessage?: boolean;
  showAvatar?: boolean;
  user?: TMember;
  onClickAvatar?: () => void;
  onLoad?: () => void;
};

const SYSTEM_CHAT_UUID = process.env.REACT_APP_SYSTEM_CHAT_UUID;
const paymentStr = TEXT_STRING.PAYMENT;
const commonStr = TEXT_STRING.COMMON;

const MessageItem = ({
  message,
  isMyMessage,
  isAdminMessage,
  showAvatar,
  user,
  onClickAvatar,
  onLoad,
}: Props) => {
  const navigate = useNavigate();

  // Context
  const {
    setGiftView,
    setHistoryPaymentId,
    setStepPaymentGift,
    setPointHistoryId,
    setPaymentStep,
  } = useContext(ChatContext);

  const [gift, messageData, text, media] = useMemo(() => {
    const messageChat = message.message?.message || message.message;

    return [
      messageChat?.gift,
      messageChat?.data,
      messageChat?.text || '',
      messageChat?.media,
    ];
  }, [message]);

  const isAutoMessage = useMemo(
    () => SYSTEM_CHAT_UUID === message.uuid,
    [message.uuid]
  );

  const messageTime = useMemo(
    () => moment(+message.timetoken / 10000).format('HH:mm'),
    [message.timetoken]
  );

  const paymentId = useMemo(
    () => messageData?.paymentId || messageData?.prepaidId,
    [messageData?.paymentId, messageData?.prepaidId]
  );

  const historyPaymentId = useMemo(
    () => messageData?.historyPaymentId,
    [messageData?.historyPaymentId]
  );

  const isRegister = useMemo(
    () => messageData?.isRegister,
    [messageData?.isRegister]
  );

  const msgLinks = useMemo(
    () => [
      {
        show: paymentId,
        text: messageData?.prepaidId
          ? paymentStr.TO_PAYMENT
          : paymentStr.CHOOSE_METHOD,
        action: () => navigate(`/payment/${paymentId}`),
      },
      {
        show: messageData?.pointHistoryId,
        text: paymentStr.GO_TO_PAYMENT_PAGE,
        action: () => {
          setPaymentStep(EPointPaymentStep.PROCESSING_DETAIL);
          setPointHistoryId(messageData?.pointHistoryId);
        },
      },
      {
        show: historyPaymentId,
        text: commonStr.PAYMENT_HERE,
        action: () => {
          setHistoryPaymentId(historyPaymentId);
          setStepPaymentGift(EGiftPaymentStep.GIFTS_PREDICT);
        },
      },
      {
        show: isRegister,
        text: commonStr.NAVIGATE_TO_REGISTER_SCREEN,
        action: () => navigate(`/signup`),
      },
    ],
    [
      historyPaymentId,
      isRegister,
      messageData?.pointHistoryId,
      messageData?.prepaidId,
      navigate,
      paymentId,
      setHistoryPaymentId,
      setPaymentStep,
      setPointHistoryId,
      setStepPaymentGift,
    ]
  );

  return (
    <div
      className={`flex ${
        isMyMessage ? 'items-end justify-end' : 'items-start'
      } break-all`}
    >
      {!isMyMessage ? (
        <div
          className={`w-12 ${user?.inactive && user?.id ? 'opacity-50' : ''}`}
        >
          {showAvatar && (
            <Avatar
              circle
              url={
                isAutoMessage || isAdminMessage
                  ? '/images/logo192.png'
                  : user?.avatar
              }
              onClick={
                isAutoMessage || isAdminMessage || (user && !user?.inactive)
                  ? onClickAvatar
                  : undefined
              }
            />
          )}
        </div>
      ) : (
        <Text fontSize={10} className="mr-3 mt-auto">
          {messageTime}
        </Text>
      )}

      {/* Message content */}
      <div
        className={`${
          isMyMessage
            ? 'bg-primary-200'
            : isAutoMessage
            ? 'bg-neutral-50 ml-3'
            : 'bg-transparent ml-3'
        } rounded-xl border border-neutral-200 max-w-[70%]`}
      >
        {text && (
          <div className="px-3 py-2 text-neutral-600 whitespace-pre-line">
            <Markdown
              options={{
                wrapper: 'p',
                forceInline: true,
                overrides: {
                  a: {
                    component: (props) => (
                      <a
                        href={props.href}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {props.children}
                      </a>
                    ),
                  },
                },
              }}
            >
              {text}
            </Markdown>
            {msgLinks.map(
              (msg, index) =>
                msg.show && (
                  <Text
                    key={index}
                    onClick={msg.action}
                    className="cursor-pointer text-sky-400 underline text-[20px] my-2"
                  >
                    {msg.text}
                  </Text>
                )
            )}
          </div>
        )}

        {gift && (
          <>
            <img
              src={gift.image}
              onClick={() => {
                setGiftView(gift);
              }}
              alt=""
              className="px-2"
            />
            <Text center className="mb-6">
              {TEXT_STRING.MESSAGE.GIVE_A_GIFT.replace(
                '$name',
                gift.name || ''
              )}
            </Text>
          </>
        )}

        {media && (
          <ChatMediaView
            listMedia={media}
            roundedTop={text ? '' : 'rounded-t-xl'}
            onLoad={onLoad}
          />
        )}
        {message.url && (
          <ChatMediaView
            listMedia={[
              {
                url: message?.url,
                type: IMAGE_ACCEPTED[0],
              },
            ]}
            roundedTop={text ? '' : 'rounded-t-xl'}
            onLoad={onLoad}
          />
        )}
      </div>

      {!isMyMessage && (
        <Text fontSize={10} className="ml-3 mt-auto">
          {messageTime}
        </Text>
      )}
    </div>
  );
};

export default MessageItem;

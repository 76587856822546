import axios from 'axios';
import {
  TActivityArea,
  TBodyOptions,
  TFilterOptions,
  TProjectInfo,
  TRank,
  TStarRank,
  TVideoGame,
} from 'types';

const getFemaleRank = async (): Promise<TRank[]> => {
  const res = await axios.get('public/female-ranks').then(function (response) {
    return response.data;
  });

  return res;
};

const getSearchFilter = async (): Promise<TBodyOptions> => {
  const res = await axios.get('public/female-tags').then(function (response) {
    return response.data;
  });

  return res;
};

const getActivityAreas = async (params: {
  subArea: boolean;
}): Promise<TActivityArea[]> => {
  return await axios
    .get(`public/activity-area`, { params })
    .then(function (response) {
      return response.data;
    });
};

const getFilterOptions = async (): Promise<TFilterOptions> => {
  return await axios.get(`public/filter-options`).then(function (response) {
    return response.data;
  });
};

const getProjectInformation = async (): Promise<TProjectInfo> => {
  const res = await axios
    .get('public/project-information')
    .then(function (response) {
      return response.data;
    });

  return res;
};

const getGameList = async (): Promise<TVideoGame[]> => {
  const res = await axios.get('public/games').then(function (response) {
    return response.data;
  });

  return res;
};

const getStarRank = async (): Promise<TStarRank[]> => {
  const res = await axios
    .get('public/female-star-ranks')
    .then(function (response) {
      return response.data;
    });

  return res;
};

const findUserByUuid = async (uuid: string): Promise<{ nickname: string }> => {
  const res = await axios
    .get('public/male', { params: { uuid } })
    .then(function (response) {
      return response.data;
    });

  return res;
};

const getChatAdminUuidList = async (): Promise<{ data: string[] }> => {
  const res = await axios.get('public/admin-list').then(function (response) {
    return response.data;
  });

  return res;
};

export {
  getFemaleRank,
  getSearchFilter,
  getActivityAreas,
  getProjectInformation,
  getFilterOptions,
  getGameList,
  getStarRank,
  findUserByUuid,
  getChatAdminUuidList,
};

const getFormattedAmount = (
  amount = 0,
  unit = '',
  currency = 'JPY',
  style: 'decimal' | 'currency' | 'percent' | 'unit' = 'decimal'
) => {
  return `${new Intl.NumberFormat('ja-JP', {
    style,
    currency,
  }).format(amount)}${unit}`;
};

const convertFormatAmount = (value?: string | number) => {
  if (!value) return 0;
  return +`${value}`.replace(/[,.]/g, '');
};

export { getFormattedAmount, convertFormatAmount };

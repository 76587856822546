import { useCallback, useState } from 'react';
import { queryKeys } from '../../../../constants';
import {
  TChannelProjectDetail,
  TProjectFemale,
  TReleaseFemaleProject,
} from 'types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getProjectPrice, projectRelease } from 'api';
import FreeGirl from './FreeGirl';
import Confirm from './Confirm';
import IconButton from 'components/IconButton';
import { useChatStore } from 'store';
import { toast } from 'react-toastify';

enum EReleaseStep {
  FREE_GIRL = 1,
  CONFIRM = 2,
  PAYMENT = 3,
}

type Props = {
  females?: TProjectFemale[];
  projectData: TChannelProjectDetail;
  isFetching: boolean;
  refetchFemales: () => void;
  refetchData: () => void;
};

function ReleaseGirl({
  females = [],
  isFetching,
  refetchData,
  projectData,
}: Props) {
  // State
  const [step, setStep] = useState<EReleaseStep>(EReleaseStep.FREE_GIRL);
  const [releaseGirls, setReleaseGirls] = useState<TProjectFemale[]>([]);

  // Hook
  const { setActionChatStep } = useChatStore();

  const { data: estimatePrice, isFetching: estimating } = useQuery({
    queryKey: [
      queryKeys.PROJECT_PRICE,
      projectData.projectId,
      ...releaseGirls.map(({ user }) => user.id),
    ],
    queryFn: () =>
      getProjectPrice({
        projectId: projectData.projectId,
        femaleIds: releaseGirls.map(({ user }) => user.id),
      }),
    enabled: !!releaseGirls?.length,
  });

  const { mutateAsync: release, isPending: isReleasing } = useMutation({
    mutationFn: (body: TReleaseFemaleProject) => {
      return projectRelease(body);
    },
  });

  const onSuccess = useCallback(() => {
    setActionChatStep();
    refetchData();
  }, [refetchData, setActionChatStep]);

  const handleRelease = useCallback(async () => {
    try {
      await release({
        femaleIds: releaseGirls.map(({ user }) => user.id),
        projectId: projectData.projectId,
      });
      onSuccess();
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  }, [onSuccess, projectData.projectId, release, releaseGirls]);

  return (
    <div>
      {step !== EReleaseStep.FREE_GIRL && (
        <IconButton
          icon="chevronLeft"
          transparent
          onClick={() => {
            setStep((prev) => prev - 1);
          }}
          className="absolute top-5"
        />
      )}
      {step === EReleaseStep.FREE_GIRL && (
        <FreeGirl
          loading={isFetching}
          defaultValues={releaseGirls}
          females={females?.filter((female: any) => female.isMeeting)}
          onSubmit={(girls) => {
            if (isFetching) return;
            setReleaseGirls(girls);
            setStep(EReleaseStep.CONFIRM);
          }}
          isSingleMeeting={!!projectData.isSingleMeeting}
        />
      )}
      {step === EReleaseStep.CONFIRM && (
        <Confirm
          loading={estimating}
          loadingRelease={isReleasing}
          data={estimatePrice}
          onSubmit={handleRelease}
          projectData={projectData}
        />
      )}
    </div>
  );
}

export default ReleaseGirl;

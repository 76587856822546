const parseJSON = (str?: string | null) => {
  if (!str) return;
  try {
    const json = JSON.parse(str);
    return json;
  } catch (e) {
    return;
  }
};

export default parseJSON;

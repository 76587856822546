import axios from 'axios';
import {
  TListFemale,
  TFemaleDetail,
  TFilterFemale,
  TPostResponse,
} from 'types';

const getFemaleList = async (payload: {
  filter: TFilterFemale;
  currentPage: number;
  pageSize: number;
}): Promise<TListFemale> => {
  const { currentPage, pageSize, filter } = payload;
  const { keywords, schedules, ranks, discount, ...res } = filter;
  return await axios
    .post('female/filter', {
      currentPage,
      pageSize,
      ...res,
      rank: ranks,
      startTime: schedules.startTime,
      endTime: schedules.endTime,
      singleCoupon: discount.singleCoupon,
      groupCoupon: discount.groupCoupon,
      searchString: keywords,
    })
    .then(function (response) {
      return response.data;
    });
};

const getFemaleListDiscount = async (payload: {
  filter: TFilterFemale;
  currentPage: number;
  pageSize: number;
}): Promise<TListFemale> => {
  const { currentPage, pageSize, filter } = payload;
  const { keywords, schedules, ranks, discount, ...res } = filter;

  return await axios
    .post('female/list-discount', {
      currentPage,
      pageSize,
      ...res,
      rank: ranks,
      searchString: keywords,
    })
    .then(function (response) {
      return response.data;
    });
};

const getFemaleDetail = async (id: number): Promise<TFemaleDetail> => {
  const res = await axios.get(`female/profile/${id}`).then(function (response) {
    return response.data;
  });

  return res;
};

const getFemalePosts = async (params: {
  userId: number;
  currentPage: number;
  pageSize: number;
  isPremium?: boolean;
}): Promise<TPostResponse> => {
  const res = await axios.get('post', { params }).then(function (response) {
    return response.data;
  });

  return res;
};

export {
  getFemaleList,
  getFemaleDetail,
  getFemalePosts,
  getFemaleListDiscount,
};

import axios from 'axios';
import { TBodyPushNotification } from 'types';

const pushNotificationMessage = async (body: TBodyPushNotification) => {
  return await axios
    .post('notification/push/message', body)
    .then(function (response) {
      return response.data;
    });
};

export { pushNotificationMessage };

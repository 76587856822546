import { queryKeys, TEXT_STRING } from '../../../constants';
import { useEffect, useRef, useState } from 'react';
import { getPremiumPurchasedList } from 'api';
import { PostContext } from 'contexts';
import PostView from 'pages/Home/PostView';
import { Header } from 'components';
import { CellMeasurerCache } from 'react-virtualized';

const cachePost = new CellMeasurerCache({
  defaultHeight: 500,
  fixedWidth: true,
});

function PremiumPurchased() {
  // Hooks
  const ref = useRef<HTMLDivElement>(null);

  // State
  const [galleryWidth, setGalleryWidth] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    if (ref.current?.offsetWidth && galleryWidth !== ref.current?.offsetWidth) {
      setGalleryWidth(ref.current.offsetWidth);
    }
  }, [galleryWidth, setGalleryWidth]);

  return (
    <PostContext.Provider value={{ galleryWidth, setGalleryWidth }}>
      <div className="h-full overflow-y-auto flex flex-col w-full">
        <Header title={TEXT_STRING.MY_PAGE.PURCHASED_PAID_TWEET} />
        <div className={`flex gap-3 bg-primary h-0 px-4`}>
          <div className={`flex-[1]`}></div>
          <div className={`flex-[7]`} ref={ref}></div>
        </div>
        <PostView
          setPostScrollY={() => {}}
          Banner={() => <></>}
          getPostList={getPremiumPurchasedList}
          queryKey={[queryKeys.POST_PREMIUM]}
          enabled
          cachePost={cachePost}
        />
      </div>
    </PostContext.Provider>
  );
}

export default PremiumPurchased;

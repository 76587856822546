import Icon from 'components/Icon';
import Text from 'components/Text';
import { navBars, routes } from '../../constants';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { detectMobile } from 'utils';
import useOrderStore from 'store/useOrderStore';
import { EOrderStep } from 'types';
import { useMessageStore, useUserStore } from 'store';
import Badge from 'components/Badge';
import { useMessage } from 'hooks';
import { usePubNub } from 'pubnub-react';
import { MessageEvent } from 'pubnub';

function Navbar() {
  const location = useLocation();
  const isMobile = detectMobile();
  const navigate = useNavigate();
  const { setOrderStep, setSelectedGirlId } = useOrderStore();
  const { user, clientId } = useUserStore();
  const { updateUnreadCount } = useMessage();
  const { unreadCount } = useMessageStore();
  const pubnub = usePubNub();

  const handleNavigation = useCallback(
    (path: string) => {
      if (path === routes.CALL) {
        setOrderStep(EOrderStep.ORDER_METHOD);
        setSelectedGirlId();
        navigate(path);
      } else navigate(path);
    },
    [navigate, setOrderStep, setSelectedGirlId]
  );

  const numbers = useMemo(() => {
    const obj: { [x: string]: number } = {};
    navBars.forEach((item) => {
      switch (item.path) {
        case routes.MESSAGE:
          obj[item.label] = Object.values(unreadCount).reduce(
            (a, b) => a + b,
            0
          );
          break;

        default:
          obj[item.label] = 0;
          break;
      }
    });
    return obj;
  }, [unreadCount]);

  useEffect(() => {
    const channelGroupId = user?.uuid || clientId;
    const handleMessage = (event: MessageEvent) => {
      if (event?.message?.channel) {
        updateUnreadCount({ channelId: event.message.channel, value: 1 });
      }
    };
    const listenerParams = {
      message: handleMessage,
    };

    pubnub.addListener(listenerParams);
    if (channelGroupId) {
      pubnub.subscribe({
        channels: [channelGroupId],
      });
    }

    return () => {
      if (channelGroupId) {
        pubnub.unsubscribe({
          channels: [channelGroupId],
        });
      }
      pubnub.removeListener(listenerParams);
    };
  }, [pubnub, user?.uuid, clientId, updateUnreadCount]);

  return (
    <div
      className={`fixed ${
        isMobile ? 'w-full' : 'w-[50vh]'
      } mx-auto bottom-0 flex items-center p-4px bg-black gap-4px h-[68px] z-[9]`}
    >
      {navBars.map((item) => {
        const isActive = location.pathname.includes(item.path);
        return (
          <div
            className={`flex flex-col justify-end items-center w-1/5 h-full gap-2px p-4px cursor-pointer`}
            key={item.path}
            onClick={() => handleNavigation(item.path)}
          >
            {item?.icon && (
              <Badge count={numbers[item.label]}>
                <Icon icon={item.icon} color={isActive ? 'var(--color-primary)' : 'white'} />
              </Badge>
            )}
            {item?.img && (
              <img className="w-32px h-32px" src={item.img} alt="" />
            )}
            <Text
              className="font-bold"
              textColor={isActive ? 'text-primary' : 'text-neutral-500'}
              nowrap
            >
              {item.label}
            </Text>
          </div>
        );
      })}
    </div>
  );
}

export default Navbar;

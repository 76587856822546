import { useEffect, useMemo } from 'react';
import BankTransfer from '../BankTransfer';
import { TEXT_STRING } from '../../../../constants';
import { EPointRequestStatus, ETransferStep, TBankDetail } from 'types';
import Step from 'components/Step';
import Loading from 'components/Loading';

type props = {
  currentStep: ETransferStep;
  setCurrentStep: (value: ETransferStep) => void;
  className?: string;
  bankTransferDetail?: TBankDetail;
  isLoading?: boolean;
};

const str = TEXT_STRING.POINTS;
const mapStep = {
  [EPointRequestStatus.PENDING]: ETransferStep.TRANSFER,
  [EPointRequestStatus.SUCCESS]: ETransferStep.TECO_FEE,
};

function BankTransferInformation({
  className,
  currentStep,
  setCurrentStep,
  bankTransferDetail,
  isLoading = true,
}: props) {
  const listSteps = useMemo(
    () => [
      {
        label: str.TRANSFER_APPLICATION,
        value: ETransferStep.APPLY,
      },
      {
        label: str.BANK_TRANSFER,
        value: ETransferStep.TRANSFER,
      },
      {
        label: str.TECO_ASSET_CHARGE,
        value: ETransferStep.TECO_FEE,
      },
    ],
    []
  );

  useEffect(() => {
    if (bankTransferDetail && !isLoading) {
      setCurrentStep(mapStep[bankTransferDetail.status]);
    }
  }, [bankTransferDetail, setCurrentStep, isLoading]);

  return (
    <>
      {isLoading ? (
        <div className="w-full h-full pb-20 flex justify-center items-center">
          <Loading />
        </div>
      ) : (
        <div
          className={`${className} flex flex-col gap-7`}
        >
          <div className="flex items-center w-[75%] mt-[30px] mx-auto">
            {listSteps.map((step, index) => {
              const isLastItem = index === listSteps.length - 1;
              return (
                <div key={index} className={`${isLastItem ? '' : 'flex-1'}`}>
                  <Step
                    label={step.label}
                    isActive={!isLastItem && step.value === currentStep}
                    isFinished={
                      listSteps.length === currentStep ||
                      step.value < currentStep
                    }
                    isShowLine={!isLastItem}
                  />
                </div>
              );
            })}
          </div>
          <BankTransfer
            bankData={bankTransferDetail}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </div>
      )}
    </>
  );
}

export default BankTransferInformation;

import IconButton from 'components/IconButton';
import MediaSlide from 'components/MediaSlide';
import Modal from 'components/Modal';
import MediaView from 'components/PostItem/MediaView';
import React, { useCallback, useState } from 'react';

type Props = {
  className?: string;
  onLoad?: () => void;
  listMedia: { type: string; url: string }[];
  roundedTop?: string;
  roundedBottom?: string;
};

function ChatMediaView({
  className,
  onLoad,
  listMedia,
  roundedTop,
  roundedBottom = 'rounded-b-xl',
}: Props) {
  // State
  const [selectedId, setSelectedId] = useState<number>();

  // Callbacks
  const handleClose = useCallback(() => {
    setSelectedId(undefined);
  }, []);

  return (
    <>
      {listMedia?.map((media, index) => (
        <MediaView
          key={index}
          className={`${className} ${index === 0 && roundedTop} ${
            index === listMedia?.length - 1 && roundedBottom
          }`}
          {...media}
          onClick={() => setSelectedId(index + 1)}
          onLoad={onLoad}
          videoOptions={{ controls: true, isAutoPlayVideo: false }}
        />
      ))}
      <Modal open={!!selectedId} onClose={handleClose}>
        {selectedId && (
          <div
            className="h-screen w-full flex items-center overflow-hidden"
            onClick={handleClose}
          >
            <IconButton
              icon="close"
              transparent
              className="fixed top-0 left-0 z-50 m-4"
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
              }}
              iconColor="white"
            />
            <MediaSlide
              data={listMedia}
              selectedItem={listMedia?.findIndex(
                (_, index) => index + 1 === selectedId
              )}
            />
          </div>
        )}
      </Modal>
    </>
  );
}

export default ChatMediaView;

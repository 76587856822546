import { Button, Chip, Loading, Text } from 'components';
import { TEXT_STRING } from '../../constants';
import { TPendingPayment } from 'types';
import { formatAmountNumber } from 'utils';

const GIFT_STR = TEXT_STRING.GIFT;

const chipData = {
  success: {
    ringColor: 'ring-success-600',
    textColor: 'text-success-700',
    text: GIFT_STR.PAYMENT_SUCCESS,
  },
  fail: {
    ringColor: 'ring-error-600',
    textColor: 'text-error-700',
    text: GIFT_STR.PAYMENT_FAIL,
  },
};

type Props = {
  processing?: TPendingPayment[];
  onNextStep: () => void;
  disabled?: boolean;
  isRefetching?: boolean;
  isLoading?: boolean;
  totalAmount?: number;
};

function ProcessingPriceList({
  processing,
  onNextStep,
  disabled = false,
  isRefetching = false,
  isLoading = false,
  totalAmount,
}: Props) {
  return (
    <div className="flex flex-col items-center justify-center min-h-[100px]">
      {isLoading ? (
        <Loading />
      ) : !processing?.length ? (
        <Text bold>{TEXT_STRING.COMMON.NO_DATA}</Text>
      ) : (
        <>
          {totalAmount && (
            <Text bold fontSize={20}>
              {formatAmountNumber(totalAmount)}
            </Text>
          )}
          <Text className="mt-5">{GIFT_STR.PAYMENT_PROCESSING_MESSAGE}</Text>
          <div className="w-full py-8 flex flex-col gap-1">
            {processing?.map((item, index) => {
              const chipValue = item?.isSuccess
                ? chipData.success
                : chipData.fail;
              return (
                <div
                  className="w-full bg-neutral-25 flex py-6 items-center justify-center"
                  key={index}
                >
                  <div className="border border-solid border-black rounded-full w-8 h-8 flex justify-center items-center mx-8">
                    <Text bold>{index + 1}</Text>
                  </div>
                  <div className="flex-1 flex justify-between items-center">
                    <Text bold>{formatAmountNumber(item.amount || 0)}</Text>
                    {item.isSuccess !== undefined && (
                      <Chip
                        className={`ring-2 flex justify-center mr-4 ${
                          isRefetching && 'opacity-50'
                        }`}
                        bgColor=""
                        ringColor={chipValue.ringColor}
                        rounded="rounded-3xl"
                        filled
                        padding="p-2"
                      >
                        <Text
                          bold
                          textColor={chipValue.textColor as `text-${string}`}
                        >
                          {chipValue.text}
                        </Text>
                      </Chip>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <Button
            onClick={onNextStep}
            bold
            textSize="text-sm"
            block
            padding="py-6"
            disabled={disabled || isRefetching}
            loading={isRefetching}
          >
            {TEXT_STRING.GIFT.CONTINUE_PAY}
          </Button>
        </>
      )}
    </div>
  );
}

export default ProcessingPriceList;
